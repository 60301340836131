export default class Roles {

    static ROLES_COMUM = [
        {label: "Perfis",                       value: ["____PERF_M", "____PERF_C", "____PERF_E", "____PERF_D"]},		
		{label: "Produto",                      value: ["____PROD_M", null        , "____PROD_E", "____PROD_D"]},
		{label: "Equipamentos",                 value: ["PCP_EQPT_M", "PCP_EQPT_C", "PCP_EQPT_E", "PCP_EQPT_D"]},
		{label: "Gestores",                     value: ["____GEST_M", "____GEST_C", "____GEST_E", "____GEST_D"]},
		{label: "Regionais",                    value: ["____REGN_M", "____REGN_C", "____REGN_E", "____REGN_D"]},
		{label: "Vendedores",                   value: ["____VEND_M", "____VEND_C", "____VEND_E", "____VEND_D"]},
		{label: "Usuários",                     value: ["____USER_M", "____USER_C", "____USER_E", "____USER_D"]},
        {label: "Budgets",                      value: ["____BUDG_M", "____BUDG_C", "____BUDG_E", "____BUDG_D"]},
        {label: "Negociações",                  value: ["GER_NEGO_M", "GER_NEGO_C", "GER_NEGO_E", "GER_NEGO_D"]},
		{label: "Clientes",                     value: ["____CLIE_M", "____CLIE_C", "____CLIE_E", "____CLIE_D"]},
        {label: "Lista de Preço",               value: ["____LSTP_M", "____LSTP_C", "____LSTP_E", "____LSTP_D"]},
        {label: "Alçada",                       value: ["____ALCD_M", "____ALCD_C", "____ALCD_E", "____ALCD_D"]},
        {label: "Contas de E-mail",             value: ["____MAIL_M", "____MAIL_C", "____MAIL_E", "____MAIL_D"]},
        {label: "Família",                      value: ["____SEGM_M", "____SEGM_C", "____SEGM_E", "____SEGM_D"]}        
    ];

    static  ROLES_RRE = [
        {label: "Metas",                        value: ["____METARRE_M", "____METARRE_C", "____METARRE_E", "____METARRE_D"]},        
        {label: "Painel RR",                    value: ["CRC_PRRE_M"]},
        {label: "Pedidos",                      value: ["CRC_PEDD_M", "CRC_PEDD_C", "CRC_PEDD_E", null ]},                       
        {label: "Painel Gerencial",             value: ["GER_RRE_PGER_M"]},
        {label: "Inadimplência",                value: ["GER_RRE_INAD_M"]},
        {label: "Simulador",                    value: ["GER_RRE_SIMU_M"]},
        {label: "Simulador Representante",      value: ["CRC_RRE_SIMU_M"]},
        {label: "Parâmetros Simulador",         value: ["GER_RRE_PSIMU_M","GER_RRE_PSIMU_C", "GER_RRE_PSIMU_E","GER_RRE_PSIMU_D"]},
        {label: "Descontos",                    value: ["GER_RRE_DESC_M","GER_RRE_DESC_C", "GER_RRE_DESC_E","GER_RRE_DESC_D"]},
        {label: "Fila de Produção",             value: ["PCP_FPRD_M"]},
        {label: "Ficha Técnica",                value: ["____FCTC_M", "____FCTC_C", "____FCTC_E", "____FCTC_D"]},     
        {label: "Gestão de O.S",                value: ["PCP_GDOS_M", "PCP_GDOS_C", "PCP_GDOS_E", "PCP_GDOS_D"]},
    ];
    
}
