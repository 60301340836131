import React from "react";
import { TabPanel } from "primereact/components/tabview/TabView";

import "./Panel.css";

export default class TZMTabPanel extends React.Component {

	render() {
		return(
			<TabPanel {...this.props} />
		);
	}

}
