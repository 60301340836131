import React from "react";
import CurrencyInput from 'react-currency-input';

export class TZMCurrency extends React.Component {

	onChangeEvent = (event, maskedValue, floatValue) => {
		this.props.onChange({name: this.props.name, value: floatValue, index: this.props.index});
	}

	render() {
		return (
			<div className="ui-textfield-currency">
				<label className="ui-input-label">{this.props.label}</label><br />
				<CurrencyInput className="ui-textfield-currency  ui-textfield" decimalSeparator="," thousandSeparator="." precision="2" prefix="R$ " {...this.props} onChangeEvent={this.onChangeEvent} />
			</div>
		);
	}
}
