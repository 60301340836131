import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { VendedorService } from "../../servicos/VendedorService";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMInputMask from "../../../components/common/TZMInputMask";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { VwVendedorService } from "../../servicos/VwVendedorService";
import Templates from "../../../utilities/Templates";
import TZMPopup from "../../../components/dialog/TZMPopup";
import TZMUserData from "../../../utilities/TZMUserData";


export class EditarVendedor extends React.Component {

	constructor(props) {
		super(props);
		this.state = {			 
			visible: true,
			vendedor: this.completarNulos(this.props.vendedor)			
		};
		this.vendedorService = new VendedorService();        
		this.vwVendedorService = new VwVendedorService();
	}
	
	completarNulos(usuario) {		
		if (usuario.vendedor == null) {
			usuario.vendedor = {pk: {}, nome: ""};
		}
		if (usuario.supervisor == null) {
			usuario.supervisor = {pk: {}, nome: ""};
		}	
		if (usuario.gerente == null) {
			usuario.gerente = {pk: {}, nome: ""};
		}
		return usuario;
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {			
		let vendedor = this.state.vendedor;
		vendedor[event.name] = event.target.value;
		this.setState({vendedor});
	}

	handleChangeMask = (event) => {			
		let vendedor = this.state.vendedor;
		vendedor[event.name] = event.value;
		this.setState({vendedor});
	}

	validar = () => {
		let messages = [];
		let vendedor = this.state.vendedor;
		if (!vendedor.comissao) {
			messages.push("Comissão não informada.");
		}		
		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
			return false;
		}
		return true;
	}

	salvarVendedor = () => {
		if (this.validar()){
			let vendedor = Object.assign({}, this.state.vendedor);
			if (vendedor.vendedor) {
				vendedor.descricaoAmigavel = vendedor.vendedor.pk.vendedorId + ' - ' + vendedor.vendedor.nome;
			}
			this.vendedorService.salvar(vendedor).then((vendedor) => {
				if (this.props.onModalClose) this.props.onModalClose(vendedor);
				this.onHide();
			});
		}
	}

	excluirVendedor = (usuario) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
		let vendedor = this.state.vendedor;
		vendedor.vendedores =  vendedor.vendedores.filter(vendedor => vendedor !== usuario);
		this.setState({vendedor});
			
		}} question="Tem certeza de que deseja remover este vendedor?" />);
	}

	listVendedores = async (event) => {
		await this.vwVendedorService.autoCompletar(event.query).then((list) => { this.setState({ vendedoresOmega: list }); })
	}

	clearVendedor = () => {
		let vendedor = this.state.vendedor;
		vendedor.vendedor = {pk: {}, nome: ""};
		vendedor.gerente = {pk: {}, nome: ""};
		vendedor.supervisor = {pk: {}, nome: ""};
		this.setState({vendedor});
	}

	completarVendedor = (event) => {
		let vendedor = this.state.vendedor;
		vendedor.vendedor = event.value;
		let pk = Object.assign({}, vendedor.vendedor.pk);
		pk.vendedorId = vendedor.vendedor.pk.supervisorId;
		this.vwVendedorService.buscar(pk).then((supervisor) => {
			vendedor.supervisor = supervisor;
			pk.vendedorId = supervisor.pk.gerenteId;
			pk.supervisorId = supervisor.pk.gerenteId;
			this.vwVendedorService.buscar(pk).then((gerente) => {
				vendedor.gerente = gerente;
				this.setState({vendedor});
			});
		});
	}

	handleClear = (event) => {
		let vendedor = this.state.vendedor;
		vendedor[event.name] = null;
		this.setState({vendedor});
	}

	handleSelect = (event) => {		
		let vendedor = this.state.vendedor;
		vendedor[event.name] = event.value;
		this.setState({vendedor});
	}

	handleUnselect = (event) => {
		let vendedor = this.state.vendedor;
		if (!vendedor[event.name]) {
			vendedor[event.name] = [];
		}
		vendedor[event.name] = vendedor[event.name].filter((e) => e.id !== event.value.id);
		this.setState({vendedor});
	}

	selectVendedores = (event) => {		
		this.setState({vendedor: {...this.state.vendedor, vendedor: event.value}});
	}

	listarAlcadas = async (event) => {		
		await this.vendedorService.listarAlcadas({descricao: event.query, sort:'descricao'}).then((alcadas) => this.setState({alcadas}));
	}
	
	render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Vendedor" onHide={this.onHide} {...this.props}>
				<TZMTabView activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<TZMPanel>
							<div className="ui-g">
								<div className="ui-g-3">
									<TZMTextField label="Nome" name="nome" value={this.state.vendedor.nome} onChange={this.handleChange} />
								</div>
								<div className="ui-g-3">
									<TZMTextField label="E-Mail" name="email" value={this.state.vendedor.email} onChange={this.handleChange} />
								</div>
								<div className="ui-g-2">
									<TZMInputMask label="Celular" mask="(99) 9 9999-9999" type="cel" name="celular" value={this.state.vendedor.celular} onChange={this.handleChangeMask} />
								</div>
								<div className="ui-g-2">
									<TZMInputMask label="Telefone" mask="(99) 9999-9999" type="cel" name="telefone" value={this.state.vendedor.telefone} onChange={this.handleChangeMask} />
								</div>
								<div className="ui-g-2">
									<TZMTextField  type="number"  name="comissao" onChange={this.handleChange} label="% Comissão" value={this.state.vendedor.comissao} />
								</div>																
								<div className="ui-g-12 ui-fluid">									
									<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleSelect} onChange={this.handleSelect}
										suggestions={this.state.alcadas} dropdown completeMethod={this.listarAlcadas} name="alcada" field="descricao" 
										label="Alçada" placeholder="Descrição" onUnselect={this.handleUnselect} value={this.state.vendedor.alcada} />
								</div>
							</div>    
						</TZMPanel>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Vendedor OMEGA</span>}>
						<div className="ui-g">
							<div className="ui-g-2">
								<TZMTextField disabled forac label="Vendedor" value={this.state.vendedor.vendedor.pk != null ? this.state.vendedor.vendedor.pk.vendedorId : undefined} />
							</div>

							<div className="ui-g-6">
								<TZMAutoComplete onClear={this.clearVendedor} onSelect={this.completarVendedor} itemTemplate={Templates.vendedorTemplateOmegaPK} 
												onChange={this.selectVendedores} disabled suggestions={this.state.vendedoresOmega} completeMethod={this.listVendedores} 
												field="nome" label="Nome" value={this.state.vendedor.vendedor} />
							</div>
							<div className="ui-g-4"><TZMTextField disabled forac label="Nome Conhecido" value={this.state.vendedor.vendedor.apelido} /></div>
							<div className="ui-g-3"><TZMTextField disabled forac label="CPF" value={this.state.vendedor.vendedor.cpfcnpj} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac label="Celular" value={this.state.vendedor.vendedor.celular} /></div>
							<div className="ui-g-2"><TZMTextField disabled forac label="Telefone" value={this.state.vendedor.vendedor.telefone} /></div>
							<div className="ui-g-5"><TZMTextField disabled forac label="E-mail" value={this.state.vendedor.vendedor.email} /></div>							
							<div className="ui-g-1"><TZMTextField disabled forac label="Supervisor" value={this.state.vendedor.supervisor.pk.vendedorId} /></div>
							<div className="ui-g-5"><TZMTextField disabled forac label="Nome" value={this.state.vendedor.supervisor.nome} /></div>
							<div className="ui-g-1"><TZMTextField disabled forac label="Gerente" value={this.state.vendedor.gerente.pk.vendedorId} /></div>
							<div className="ui-g-5"><TZMTextField disabled forac label="Nome" value={this.state.vendedor.gerente.nome} /></div>							
							
						</div>
					</TZMTabPanel>

				</TZMTabView>
			
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarVendedor} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarVendedor} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
