import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { httpPost } from "../../../utilities/TZMRequest";
import TZMShortcut from "../../../utilities/TZMShortcut";



export default class AlterarSenha extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true,
			alterado: false
		};
	}

	fecharPainel = (modalResponse) => {
		this.setState({visible: false});
		if (this.props[modalResponse]) {
			this.props[modalResponse](this.state.pedido);
		}
	}

	fecharUsuario = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={() => this.fecharPainel("onClose")} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel("onClose");
		}
    }

    confirmarSenha = () => {
        if (this.state.senha && this.state.confirmacao) {
            if (this.state.senha.length < 4) {
                ModalRealm.showDialog(<TZMPopup messages={["A senha deve ter ao menos 4 caracteres."]} />);
            } else if (this.state.senha !== this.state.confirmacao) {
                ModalRealm.showDialog(<TZMPopup messages={["As senhas não coincidem."]} />);
            } else {
                httpPost("/usuarios/alterar-senha", {senha: this.state.senha, confirmacao: this.state.confirmacao}).then((response) => {
                    ModalRealm.showDialog(
                        <TZMPopup header="Informação" style={{width: "250px"}} onClose={() => {
                            this.fecharPainel("onClose");
                        }}>
                            <div style={{textAlign: "center"}}>
                                <span className="fa fa-info ui-tzm-info-icon" style={{marginRight: "4px"}} />Senha alterada com sucesso!
                            </div>
                        </TZMPopup>
                    );
                });
            }
        } else {
            ModalRealm.showDialog(<TZMPopup messages={["Por favor, preencha a senha e a confirmação."]} />);
        }
    }

    handleUpdate = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

	render() {
		return (
			<TZMDialog style={{width: "300px"}} visible={this.state.visible} modal header="Alterar Senha" {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-12">
                            <TZMTextField name="senha" onChange={this.handleUpdate} value={this.state.senha} type="password" label="Senha" />
                        </div>
                        <div className="ui-g-12">
                            <TZMTextField name="confirmacao" onChange={this.handleUpdate} value={this.state.confirmacao} type="password" label="Confirmação de senha" />
                        </div>
					</div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton primary label="Alterar Senha" icon="fas fa-key" onClick={this.confirmarSenha} />
					<TZMButton secondary label="Cancelar" icon="fas fa-times" onClick={this.fecharUsuario} />
				</TZMPanelFooter>
				<TZMShortcut
                    active={this.state.visible}
                    onCtrlS={this.confirmarSenha}
					onEscape={this.fecharUsuario}
				/>
			</TZMDialog>
		);
	}

}
