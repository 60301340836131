import React from "react";
import ReactTooltip from "react-tooltip";

export class ClienteUtils { 

	static TIPOS = {
		"MATRIZ": {value: "building", color: "#F44336", title: "Matriz", filial: false},
		"FILIAL": {value: "store", color: "#303f9f", title: "Filial",  filial: true},
		"UNICO" : {value: "store-alt", color: "#607D8B", title: "Único", filial: false}
	};	

	static clienteTipoPedido(tipo) {		
		if (tipo) {
			let {value, color, title} = ClienteUtils.TIPOS[tipo];
			return <span key="clienteTipo" className={`fas fa-${value}`} title={title} style={{width:"30px", textAlign: "center", fontSize: "20px", color}}  />;
		}			
		return null;
	}

	static clienteTipo(tipo) {		
		if (tipo) {
			let {value, color, title} = ClienteUtils.TIPOS[tipo];
			return <span key="clienteTipo" className={`fas fa-${value}`} title={title} style={{ display:"inline-block",width:"30px", textAlign: "center" ,padding: "5px", fontSize: "14px",  color}}  />;
		}	
		return null;		
	}

	static clienteTipoNome(cliente) {
		let {value, color, title,filial} = ClienteUtils.TIPOS[cliente.tipo];	
		let dataTip =  title + "<br/>";
		if (filial) {
			dataTip += "Dados da Matriz <br/>";
			dataTip += "Razão Social: " + cliente.grupoRazaoSocial + "<br/>";
			dataTip += "Nome Fantasia: " + cliente.grupoNomeFantasia + "<br/>";
			dataTip += "CNPJ: " + cliente.grupoCpfCnpjFormatado;
		}
		return <div>
			<span className={`fas fa-${value}`} style={{ display:"inline-block",width:"30px", textAlign: "center" ,padding: "5px", fontSize: "14px", color: color}} 
				data-tip={dataTip} data-for="cliente" data-multiline={true} />          
			<ReactTooltip id="cliente" place="top" type="info" effect="float" />	
			{cliente.razaoSocial}
		</div>;
	}

	static clienteTemplateOmega(cliente) {
		return <div>
			<i className={`fa fa-${cliente.posicao === "ATIVO" ? "check-circle" : "minus-circle"}`} /> 
			{ClienteUtils.clienteTipo(cliente.tipo)}
			{cliente.pk.clienteId} - {cliente.razaoSocial}</div>;
	}

	static templateTipo(cliente) {
		return <div>			
			{ClienteUtils.clienteTipo(cliente.tipo)}
			{cliente.pk ? cliente.pk.clienteId : cliente.clienteId}	- {cliente.razaoSocial}		
		</div>;
	}
	

}


