import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMPopup from "../../../components/dialog/TZMPopup";

export class PrecoPadrao extends React.Component {

	constructor(props) {
        super(props);
		this.state = {    
            validar: this.props.validar,                               
            params: {
                preco: '0'
            },
            visible: true
        };
    }

    onHide = () => this.setState({ visible: false });
    
    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
    
    confirmarPrecoPadrao = () => {                   
        if (this.validarValorPadrao()){                
            let params = this.state.params;
            if (this.props.onSave) {            
                this.props.onSave(params);
            }
            this.onHide();
        }   
    }

    validarValorPadrao = () => {
        if (this.state.validar) {
            let messages = [];
            let preco = this.state.params.preco;	
            let fci = this.state.params.fci;	
            if (!preco || preco === '0') {
                messages.push(`O preço não pode ser vazio ou igual a 0 (zero).`);
            }
            if (!fci) {
                messages.push(`O FCI não pode ser vazio.`);
            }
            if (messages.length > 0) {
                ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={messages} />);
                return false;
            }
        }
		return true;
	}   
    
	render() {
        return (
        <TZMDialog style={{ width: "300px" }} visible={this.state.visible} modal header="Preço Padrão" onHide={this.onHide} {...this.props}>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <div className="ui-g-6">
                            <TZMTextField min={0} type="number" label="% FCI" value={this.state.params.fci} onChange={(event) => this.handleUpdate("fci", event)} />
                        </div>
                        <div className="ui-g-6">
                            <TZMTextField min={0} type="number" label="Preço" value={this.state.params.preco} onChange={(event) => this.handleUpdate("preco", event)} />                            
                        </div>
                    </div>
                </TZMForm>    
            </TZMPanel>
            <TZMPanelFooter>
                <TZMButton className="ui-button-primary" label="Confirmar" icon="fas fa-check" onClick={this.confirmarPrecoPadrao} />
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />                
			</TZMPanelFooter>            
        </TZMDialog>
        );
    }
}