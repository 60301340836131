import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";

import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { FichaTecnicaService } from "../../rre/servicos/FichaTecnicaService";
import { Validator } from "../../../utilities/TZMValidator";
import Constantes from "../../../utilities/Constantes";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import { PrecoPadrao } from "./PrecoPadrao";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMConfirm from "../../../components/dialog/TZMConfirm";

export class AlterarPreco extends React.Component {

	constructor(props) {
        super(props);
		this.state = {
            listaPrecoVersao: props.listaPrecoVersao,
            itensFiltrados: props.listaPrecoVersao.itens,
            params: {
                produto: null,
                comprimentoRolo: null,
                material: null,
                diametroTubete: null,
                sentidoRebobinagem: null
            },
            visible: true
        };        
        this.fichaTecnicaService = new FichaTecnicaService();         
    }

    onHide = () => this.setState({ visible: false });

    componentDidMount() {
        this.fichaTecnicaService.materiais().then((materiais) => {this.setState({materiais });})
        this.fichaTecnicaService.diametrosTubete().then((diametrosTubete) =>{this.setState({  diametrosTubete });})
        this.fichaTecnicaService.sentidosRebobinagem().then((sentidosRebobinagem) =>{this.setState({  sentidosRebobinagem });})
    }
    
    handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
    }

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
    
    checkNullArray(value) {
		return value ? value : [];
    }
    
    filtrarItem(item,params) {
        if (params.largura && item.produto.largura === Number(params.largura)) {            
        } else if (params.largura && item.produto.largura !== Number(params.largura)){
            return false;
        }

        if (params.altura  && item.produto.altura  === Number(params.altura)) {
        } else if (params.altura  && item.produto.altura  !== Number(params.altura)) {
            return false;
        }

        if (params.comprimentoRolo && item.produto.comprimentoRolo === Number(params.comprimentoRolo)) {
        } else  if (params.comprimentoRolo && item.produto.comprimentoRolo !== Number(params.comprimentoRolo)) {
            return false;
        } 
        if (params.produto && ((item.produtoDescricao.toLowerCase().includes(params.produto.toLowerCase())) || 
                               (item.produtoId.toLowerCase().includes(params.produto.toLowerCase())))) {            
        } else if (params.produto && ((!item.produtoDescricao.toLowerCase().includes(params.produto.toLowerCase())) || 
                                      (!item.produtoId.toLowerCase().includes(params.produto.toLowerCase())))) {   
            return false;
        }

        if (Validator.isSelecionado(params.material) && item.produto.material === params.material) {
        } else if (Validator.isSelecionado(params.material) && item.produto.material !== params.material) {
            return false;
        }
        
        if (Validator.isSelecionado(params.diametroTubete) && item.produto.diametroTubete === params.diametroTubete) {
        } else if (Validator.isSelecionado(params.diametroTubete) && item.produto.diametroTubete !== params.diametroTubete){
            return false;
        }
        
        if (Validator.isSelecionado(params.sentidoRebobinagem) && item.produto.sentidoRebobinagem === params.sentidoRebobinagem) {
        } else if (Validator.isSelecionado(params.sentidoRebobinagem) && item.produto.sentidoRebobinagem !== params.sentidoRebobinagem) {
            return false;
        }

        return true;
    }

    handleList = async () => {        
        let listaPrecoVersao = this.state.listaPrecoVersao;         
        let params = this.state.params;
        let itens = listaPrecoVersao.itens.filter((item) => this.filtrarItem(item,params));        
        itens = itens.filter((v, i, a) => a.findIndex(w => w.id === v.id) === i);
        this.setState({itensFiltrados: itens});       
    }

    alterarPreco = () => {
        ModalRealm.showDialog(<PrecoPadrao key={TZMUserData.getIdGenerator()} validar={false} onSave={(params) => {     
            let itens = this.state.itensFiltrados;
            itens.forEach( (item) => {
                if (params.preco) {
                    item.preco = params.preco;
                }
                if (params.fci) {
                    item.fci = params.fci;
                }
            } );
            this.setState({itensFiltrados: itens});
        }} />);
    }
    
    confirmarAlteracao = () => {
        ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={this.onSave} question={'Deseja realmente alterar os valores?'} />);	
    }

    onSave = () => {                             
        let listaPrecoVersao = this.state.listaPrecoVersao;
        if (this.props.onSave) {            
            this.props.onSave(listaPrecoVersao);
            this.onHide();
        }
    }
    
	render() {
        return (
        <TZMDialog style={{ width: "1200px" }} visible={this.state.visible} modal header="Alterar Valor" onHide={this.onHide} {...this.props}>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <div className="ui-g-3">                            
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("largura", event)} label="Largura" value={this.state.params.largura} />
                        </div>
                        <div className="ui-g-3">
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("altura", event)} label="Altura" value={this.state.params.altura} />
                        </div>
                        <div className="ui-g-3">
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("comprimentoRolo", event)} label="Comprimento Rolo" value={this.state.params.comprimentoRolo} />
                        </div>                            
                        <div className="ui-g-3">
                            <TZMTextField name="produto"  onChange={(event) => this.handleUpdate("produto", event)} label="Produto" placeholder="Descrição, SKU" value={this.state.params.produto} />
                        </div>
                        <div className="ui-g-4">
                            <TZMCombobox name="material" label="Material" onChange={this.handleCombobox} value={this.state.params.material}>
                                {[<option key={"semMaterial"} value={Constantes.semSelecao}>{Constantes.nenhumRegistroSelecionado}</option>, ...this.checkNullArray(this.state.materiais).map((material) =>
                                    <option key={material.produtoId} value={material.produtoId}>{material.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-4">
                            <TZMCombobox name="diametroTubete" label="Diametro Tubete" onChange={this.handleCombobox} value={this.state.params.diametroTubete}>
                                {[<option key={"semDiametro"} value={Constantes.semSelecao}>{Constantes.nenhumRegistroSelecionado}</option>, ...this.checkNullArray(this.state.diametrosTubete).map((diametroTubete) =>
                                    <option key={diametroTubete.produtoId} value={diametroTubete.produtoId}>{diametroTubete.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-4">
                            <TZMCombobox name="sentidoRebobinagem" label="Sentido Rebobinagem" onChange={this.handleCombobox} value={this.state.params.sentidoRebobinagem}>
                                {[<option key={"semRebo"} value={Constantes.semSelecao}>{Constantes.nenhumRegistroSelecionado}</option>, 
                                ...this.checkNullArray(this.state.sentidosRebobinagem).map((sentidoRebobinagem) =>
                                    <option key={sentidoRebobinagem.conteudoAlfa} value={sentidoRebobinagem.conteudoAlfa}>{sentidoRebobinagem.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                    </div>                        
                    <TZMPanelFooter>
                        <TZMButton className="ui-button-warning" style={{ float: "left" }} label="Alterar Valor" icon="fas fa-plus" onClick={() => this.alterarPreco()} />
                        <TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
                    </TZMPanelFooter>
                </TZMForm>
            </TZMPanel>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <DataTable  ref={(el) => this.dt = el} emptyMessage="Nenhum registro encontrado" value={this.state.itensFiltrados} paginator rows={15} 
                                    rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}>                                            
                            <Column key="produtoId" field="produtoId"  style={{ textAlign: "center", width: "18em" }} header="SKU" />
                            <Column key="produtoDescricao" field="produtoDescricao"  style={{ width: "*" }} header="Produto" />
                            <Column key="codigo" style={{ textAlign: "center", width: "10em" }} header="Posição" body={(item) => Validator.isEntidade(item.produto) ? item.produto.posicao : ""} />									
                            <Column key="fci"  style={{ center: "right", width: "15em" }}  header="% FCI" field="fci" />                            
                            <Column key="preco"  style={{ center: "right", width: "15em" }}  header="Preço" field="preco" />
                        </DataTable>
                    </div> 
                </TZMForm>
            </TZMPanel>
            <TZMPanelFooter>
                <TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.confirmarAlteracao} />                
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />                
			</TZMPanelFooter>            
        </TZMDialog>
        );
    }
}
