const CONFIRMADO = "CONFIRMADO";
const ERRO = "ERRO";
const RASCUNHO = "RASCUNHO";
const DESCONTO_FINALIZADO = "DESCONTO_FINALIZADO";
const DESCONTO_REPROVADO = "DESCONTO_REPROVADO";
const ALCADA_DESCONTO = "ALCADA_DESCONTO";
const FATOR_DIVISAO = "D";
const FATOR_MULTIPLICAO = "M";
const FATOR_NULL = "N";


export class PedidoUtils { 

	static statusConfirmado() {return CONFIRMADO;}

	static statusErro() {return ERRO;}

	static statusRascunho() {return RASCUNHO;}

	static statusDescontoFinalizado() {return DESCONTO_FINALIZADO;}

	static statusDescontoReprovado() {return DESCONTO_REPROVADO;}

	static isCancelar(status) {
		switch (status) {
		case ERRO:
		case RASCUNHO:
		case DESCONTO_REPROVADO:
		case ALCADA_DESCONTO:				
			return true;
		default:
			return false;
		}
	}

	static isDividir(fator) { return fator === FATOR_DIVISAO;}

	static isMultiplicar(fator) { return fator === FATOR_MULTIPLICAO; }
	
	static isUnidadeAlternativa(clienteProduto) {
		return clienteProduto && clienteProduto.tipoFator !== FATOR_NULL;
	}
}