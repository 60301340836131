import moment from 'moment';
import 'moment/min/locales';
import React from "react";
import TZMButton from '../../../components/common/TZMButton';
import { toDecimal } from '../../../components/common/TZMFormatter';
import TZMMainContent from '../../../components/main/TZMMainContent';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from '../../../components/panel/TZMPanel';
import TZMPanelFooter from '../../../components/panel/TZMPanelFooter';
import TZMColumn from '../../../components/table/TZMColumn';
import TZMTable from '../../../components/table/TZMTable';
import { Fetch } from '../../../utilities/Fetch';
import TZMShortcut from '../../../utilities/TZMShortcut';
import TZMUserData from '../../../utilities/TZMUserData';
import { MetaService } from '../../servicos/MetaService';
import EditarMeta from './EditarMeta';


const title = "Metas";
const empresa = process.env.REACT_APP_EMPRESA_NOME;

export default class Meta extends React.Component {

	constructor() {
		super();
		this.state = {
			list: [], 
			data: {},
			params: {
				mes: this.rangeDiario(),
				meta: undefined,
				page: 0,
				size: 10000,
				sort: "mes"
			}
		};			
		this.metaService = new MetaService();
		moment.locale('pt-BR');
	}

	rangeDiario() {

		var thisYear = (new Date()).getFullYear();    
		var start = new Date("1/1/" + thisYear);
		var end = new Date("12/31/" + thisYear)
		var defaultStart = moment(start.valueOf());
		var defaultEnd = moment(end.valueOf());		
		//let firstDay = moment().format("YYYY-MM-DD");
		return [defaultStart, defaultEnd];
	}

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {			
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");
		}
	}	

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}
	
	mesFormat(rowData, column) {
		let mes = moment(rowData[column.field]).format("MMMM  [-] YYYY");
		mes = mes.charAt(0).toUpperCase() + mes.slice(1);
		return mes;
	} 

	handleList = () => {
		this.metaService.listar(this.state.params).then((data) => this.setState({list: data}));
	}	

	selectMeta = (event) => {
		this.setState({selectedMeta: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.metaService.completar(event.data).then((meta) => {
					ModalRealm.showDialog(<EditarMeta meta={meta} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
				});
			} else {
				ModalRealm.showDialog(<EditarMeta meta={event.data} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
			}
		}
	}	

	newMeta = () => {
		this.setState({selectedMeta: null});
		this.selectMeta({data: {mes: moment()}});
	}

	toDecimal(rowData, column) {
		return toDecimal(rowData[column.field]);		
	}

	columns = [
		<TZMColumn sortable field="mes" key="mes" body={this.mesFormat} header="Mês" />,		
		<TZMColumn  field="metaLocal" body={this.toDecimal}  key="MetaLocal" header="Meta Local" />,
		<TZMColumn  field="metaInter" body={this.toDecimal} key="MetaExportacao" header="Meta Exportação" />,	
		<TZMColumn key="acoes" header="Ações" style={{width: "5em", textAlign: "center"}} body={(meta) => {
			return (
				<span key={0} title="Editar Meta" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectMeta({data: meta})} />
			);
		}} />
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							{/* <TZMForm>
								<div className="ui-g">
									<div className="ui-g-2">
										<TZMCalendar label="Período" placeholder="Inicial" onChange={(event) => this.handleUpdateParams(event, "mes", 0)} value={this.state.params.mes[0]} />
									</div>
									<div className="ui-g-2">
										<TZMCalendar label="&nbsp;" placeholder="Final" onChange={(event) => this.handleUpdateParams(event, "mes", 1)} value={this.state.params.mes[1]} />
									</div>
								</div>
							</TZMForm> */}
							<TZMPanelFooter>
								<TZMButton success={true} icon="fas fa-plus" label="Nova Meta" onClick={this.newMeta} />
								<TZMButton primary={true} icon="fas fa-search" label="Procurar" onClick={this.handleList} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.list} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				{this.state.dialogs}
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.handleList}
					onCtrlH={this.toggleHidden}
					onCtrlN={this.newMeta}
				/>
				<span className="ui-tzm-dashboard-title">{empresa} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
