import { Column } from "primereact/components/column/Column";
import { DataView } from "primereact/components/dataview/DataView";
import React from "react";
import { VersaoService } from "../../pages/servicos/VersaoService";
import TZMUserData from "../../utilities/TZMUserData";
import TZMButton from "../common/TZMButton";
import TZMDialog from "../dialog/TZMDialog";
import TZMPanelFooter from "../panel/TZMPanelFooter";
import TZMTable from "../table/TZMTable";

export class PainelVersao extends React.Component {

	state = {
		visible: false,
		versoes: []
	};

	versaoService = new VersaoService();

	onClose = () => {
		if (this.state.versoes.length) {
			TZMUserData.setSettings("ultimaVersao", this.state.versoes.map((v) => v.id).reduce((a, b) => Math.max(a, b), 0));      
		}            
		this.setState({visible: false});    
	} 

	componentDidMount() {
		if (TZMUserData.getUsuario().mostrarChangelog) {
			const last = TZMUserData.getSettings("ultimaVersao", 0);
			this.versaoService.listar(last).then((versoes) => {
				if (versoes && versoes.length) {
					this.setState({ versoes, visible: true });
				}
			});
		}
	}

	detalhesVersao = [
		<Column key={"tipo"} header="Tipo" style={{width: "4em", textAlign: "center"}} body={(detalhe) => {
			switch (detalhe.tipo) {
				case "CORRECAO":
					return <i className="fa fa-debug" />;
				case "ADICAO":
					return <i className="fa fa-plus" />;
				default:
					return null;
			}
		}} />,
		<Column key={"nota"} header="Nota de Alteração" style={{width: "*"}} field="nota" />
	];

	buildVersao = (versao) => {
		return (
			<div key={versao.id}>
				<TZMTable header={`Versão ${versao.id}`} children={this.detalhesVersao} value={versao.detalhes} />
			</div>
		);
	}

	render() {
		return (
			<TZMDialog style={{width: "950px"}} onClose={this.onClose} visible={this.state.visible} modal 
						closable={false} header="Notas da Versão" {...this.props}>
				<div className="ui-g">
					<div className="ui-g-12">
						<b>Bem-vindo ao RREWeb!</b><br />
						Desde sua última visita o sistema foi atualizado e as seguintes alterações foram feitas:
					</div>
					<div className="ui-g-2"><div className="ui-update-sidepanel" /></div>
					<DataView rows={1} paginator value={this.state.versoes} className="ui-g-10" itemTemplate={this.buildVersao} />
				</div>
				<TZMPanelFooter>
					<TZMButton className="ui-button-primary"  label="OK" onClick={this.onClose}  />
				</TZMPanelFooter>
			</TZMDialog> 
		);
	}

}
