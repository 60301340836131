import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import {EquipamentoService} from "../servicos/EquipamentoService";
import TZMCombobox from "../../../components/common/TZMCombobox";

export class EditarEquipamento extends React.Component {

	state = {
		visible: true,
		equipamento: Object.assign({
			tipo: "NENHUM",
			descricao: ""
		}, this.props.equipamento)
	};

	static TIPOS = [
		<option key={0} value="NENHUM">Nenhum</option>,
		<option key={1} value="ERREPI">ERREPI</option>,
		<option key={2} value="GGS">GGS</option>,
		<option key={3} value="MODULAR">Modular</option>
	];

	equipamentoService = new EquipamentoService();

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let equipamento = this.state.equipamento;
		equipamento[event.name] = event.target.value;
		this.setState({equipamento});
	};

	salvarEquipamento = () => {
		let equipamento = Object.assign({}, this.state.equipamento);
		this.equipamentoService.salvar(equipamento).then((equipamento) => {
			if (this.props.onModalClose) {
				this.props.onModalClose(equipamento);
			}
			this.onHide();
		});
	};

	render() {
		return (
			<TZMDialog style={{width: "700px"}} visible={this.state.visible} modal header="Equipamento" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<TZMTextField col={8} label="Descrição" name="descricao" value={this.state.equipamento.descricao} onChange={this.handleChange} />
						<TZMCombobox col={4} label="Tipo" name="tipo" children={EditarEquipamento.TIPOS} value={this.state.equipamento.tipo} onChange={this.handleChange} />
					</div>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarEquipamento} />
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarEquipamento} onEscape={this.onHide} />
			</TZMDialog>
		);
	}

}