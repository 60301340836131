import React from "react";
import "./LazyImage.css";

export default class LazyImage extends React.Component {

	constructor() {
		super();
		this.state = {
			loaded: false,
			error: false,
			current: null
		};
	}

	componentDidMount() {
		let img = new Image();
		img.onload = () => {
			this.setState({
				loaded: true
			});
		};
		img.onerror = () => {
			this.setState({
				error: true
			});
		}; 
		img.src = this.props.src;
	}

	render() {
		if (this.state.error) {
			return <div className="loading-loading"><i className="fa fa-times" /></div>;
		} else if (!this.state.loaded) {
			return <div className="loading-loading"><i className="fa fa-spinner fa-spin" /></div>;
		}
		return <img className={this.props.className} style={this.props.style} src={this.props.src} alt={this.props.alt} />;
	}

}
