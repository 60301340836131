import React from "react";
import { SimuladorService } from "../servicos/SimuladorService";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import { TZMSimuladorRepresentante } from "../../../components/common/TZMSimuladorRepresentante";

const title = "Simulador do Representante";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class CalculadoraRepresentante extends React.Component {

	constructor() {
		super();
		this.state = {
			simulador:{},
			simulacao:{
				espacamentoLateral:"0",
				ggf:"0",
				perdas:"0",
				embalagem:"0",
				comissao:"0",
				frete:"0",
				largura:"0",
				comprimento:"0",
				custoMedio:"0",
				mc:"0",
				pisCofins:"0",
				icms:"0",
				familia:"",
				pagamentoFrete: 'C',
				premissaProduto: null
			}		
		}
		this.handleList();
	}
	
	simuladorService = new SimuladorService();	
    
    handleList = () => {
		this.simuladorService.carregarSimulador().then((simulador) => {
			let simulacao = this.state.simulacao;
			simulacao.espacamentoLateral = simulador.espacamentoLateral;
			simulacao.embalagem = simulador.embalagem;
			simulacao.pisCofins = simulador.pisCofins;
			this.setState({simulador,simulacao})
		});
	}
	
	simular = () => {
		let simulacao = Object.assign({}, this.state.simulacao);
		let premissasProduto = this.state.simulador.premissasProduto;			
		let premissaProduto =  premissasProduto.filter(premissa => premissa.produto.pk.produtoId === simulacao.premissaProduto)[0];				
		simulacao.mc = premissaProduto.mc;
		simulacao.custoMedio = premissaProduto.custoMedio;
		this.simuladorService.simular(simulacao).then((simulado) => {
			this.setState({simulacao: simulado})
		});
    }
    
    handleUpdate = (key, event) => {
		let simulador = this.state.simulador;
		simulador[key] = event.target.value;
		this.setState({simulador});
	}   

    render() {
		return (
			<TZMMainContent>				
				<TZMSimuladorRepresentante simulador={this.state.simulador} />
				{this.state.dialogs}				
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
