import Constantes from "./Constantes";

export class Validator {

	static isEmpty(value) {
		return value == null || value.toString().trim().length === 0;
	}

	static isEntidade(value) {
		return value != null && Object.keys(value).length > 0 && (value.id != null || value.cascadeable || (value.pk != null && Object.keys(value.pk).length > 0));
	}

	static mailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

	static isEmail(value) {
		return value != null && Validator.mailRegex.test(value);
	}

	static isSelecionado(value) {
		if (!value) {
			return false;
		}
		console.log("value",value);
		console.log("Constantes.semSelecao",Constantes.semSelecao);
		if (value === Constantes.semSelecao) {
			
			return false;
		}
		return true;
	}
	

}
