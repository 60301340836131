import { httpDelete, httpGet,  httpPost } from "../../../utilities/TZMRequest";

export class SimuladorService {

	completar(simulador) {
		return httpGet(`/simuladores/${simulador.id}`);
	}

	listar(params) {
		return httpGet("/simuladores", params);
	}

	carregarSimulador() {
		let params;
		return httpGet("/simuladores/carregarSimulador", params);
	}

	salvar(simulador) {
		return httpPost("/simuladores", simulador);
	}

	excluir(simulador) {
		return httpDelete("/simuladores", simulador.id);
	}

	simular(simulacao) {
		return httpGet("/simuladores/simular", {simulacao: simulacao});
	}

	calcular(simulacao) {
		return httpGet("/simuladores/calcular", {simulacao: simulacao});
	}

	enviarEmail(globalMensagens) {
		return httpGet(`/simuladores/enviar-email?${Object.keys(globalMensagens).map(k => globalMensagens[k].map(v => `${k}=${v}`).join("&")).join("&")}`);
	}

}