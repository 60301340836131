import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTable from "../../../components/table/TZMTable";
import { Column } from "primereact/components/column/Column";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import LazyImage from "../../../utilities/LazyImage";
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';

const basePath = process.env.REACT_APP_API_HOST || "";
export class VisualizarFichaTecnicaProduto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            visible: true,
            fichaTecnica: props.fichaTecnica,
            produto: props.produto
            
        };

    }

    onHide = () => this.setState({ visible: false });

    fecharPainel = () => {
		this.setState({ visible: false });
    } 

    atributoProduto = (rowData) => {        
        switch (rowData.atributoId) {
            case 13:                
                return (<div> {rowData.comprimentoRolo}</div>)
            case 16:                
                return (<div> {rowData.material.descricao}</div>)
            case 21:                
                return (<div> {rowData.diametroTubete.descricao}</div>)
            case 26:                
                return (<div> {rowData.sentidoRebobinagem.conteudoAlfa}</div>)
            default:
                return (<div></div>)
        }
    }

    conteudoFicha = (rowData) => {        
        switch (rowData.atributo.tipoConteudo) {
            case 'A':                
                return (<div> {rowData.conteudoTexto}</div>)
            case 'N':                
                return (<div> {rowData.conteudoNumero}</div>)
            default:
                return (<div></div>)
        }
    }

    columnsAtributosProduto = [		
        <Column  style={{ textAlign: "center", width: "5em" }} field="atributoId" key="atributoId"  header="Atributo" />,        
        <Column  style={{ textAlign: "left", width: "20em" }} field="atributo.descricao" key="atributo.descricao"  header="Descrição" />,
        <Column  style={{ textAlign: "center", width: "5em" }} field="atributo.tipoConteudo" key="atributo.tipoConteudo"  header="Tipo Conteúdo" />,        
        <Column  style={{ textAlign: "center", width: "5em" }} field="codigoTabela" key="codigoTabela"  header="Tabela" />,
        <Column  style={{ textAlign: "left", width: "15em" }} key="item"  header="Valor" body={this.atributoProduto}/>
    ]
    
    columnsAtributos = [		
        <Column sortable style={{ textAlign: "center", width: "4em" }} field="atributoId" key="atributoId"  header="Atributo" />,
        <Column  style={{ textAlign: "left", width: "15em" }} field="atributo.descricao" key="atributo.descricao"  header="Descrição" />,
        <Column  style={{ textAlign: "center", width: "5em" }} field="atributo.tipoConteudo" key="atributo.tipoConteudo"  header="Tipo Conteúdo" />,        
        <Column  style={{ textAlign: "left", width: "13em" }} key="conteudoNumero"  header="Conteúdo" body={this.conteudoFicha}/>,
        <Column  style={{ textAlign: "center", width: "4em" }} field="atributo.unidade" key="atributo.unidade"  header="Unidade" />
    ]

	render() {
        return (
            <TZMDialog style={{ width: "1000px" }} visible={this.state.visible} modal header="Ficha Técnica Produto" onHide={this.onHide} {...this.props}>
            <TZMPanel>
                <TZMForm>
    				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
	    				<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados do Produto</span>}>
                            <div className="ui-g ">
                                <div className="ui-g-2">
                                    <TZMTextField disabled label="Ficha Técnica" value={this.state.fichaTecnica.fichaTecnicaId} />                                    
                                </div>
                                <div className="ui-g-1">
                                    <TZMTextField disabled label="Versão" value={Number(this.state.fichaTecnica.versao)} />
                                </div>
                                <div className="ui-g-3">
                                    <TZMTextField disabled label="SKU" value={this.state.produto.pk.produtoId} />
                                </div>
                                <div className="ui-g-5">
                                    <TZMTextField disabled  label="Descrição" value={this.state.produto.descricao} />
                                </div>
                            </div>    
                        </TZMTabPanel>
	    				<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Atributos do Produto</span>}>
                            <TZMTable value={this.state.fichaTecnica.produtoAtributos}>                
                                {this.columnsAtributosProduto}
                            </TZMTable>
                        </TZMTabPanel>
	    				<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Atributos da Ficha Técnica</span>}>
                            <TZMTable value={this.state.fichaTecnica.atributos}  paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}>                
                                {this.columnsAtributos}
                            </TZMTable>
                        </TZMTabPanel>
	    				<TZMTabPanel header={<span><span className="ui-tab-number" children="4" />Imagem Ficha Técnica</span>}>  
                            <ScrollPanel style={{width: '100%', height: '600px'}} className="custom">
                                <LazyImage key={this.state.fichaTecnica.imagem}  src={`${basePath}/public/omega/imagens/${this.state.fichaTecnica.imagem}`} />                            
                            </ScrollPanel>
                            
                        </TZMTabPanel>


                    </TZMTabView>
                </TZMForm>
            </TZMPanel>
            <TZMPanelFooter>
                <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fecharPainel} />
            </TZMPanelFooter>            

        </TZMDialog>   
        );
    }
}