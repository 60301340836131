import moment from 'moment';
import 'moment/min/locales';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from '../../../components/dialog/TZMConfirm';
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from '../../../utilities/Fetch';
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { ContaEmailService } from "../../servicos/ContaEmailService";
import EditarContaEmail from "./EditarContaEmail";

const title = "Contas de E-mail";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class ContaEmail extends React.Component {

	constructor() {
		super();
		this.state = {
			list: [], 
			data: {},
			params: {
				contaEmail: undefined,
				page: 0,
				size: 10000,
				sort: "usuario"
			}
		};			
		this.contaEmailService = new ContaEmailService();
		moment.locale('pt-BR');
	}

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");		
		}
	}

	handleList = () => {
		this.contaEmailService.listar(this.state.params).then((data) => this.setState({list: data}));
	}	

	selectContaEmail = (event) => {
		this.setState({selectedContaEmail: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.contaEmailService.completar(event.data).then((contaEmail) => {
					ModalRealm.showDialog(<EditarContaEmail contaEmail={contaEmail} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
				});
			} else {
				ModalRealm.showDialog(<EditarContaEmail contaEmail={event.data} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
			}
		}
	}

	newContaEmail = () => {
		if (TZMUserData.hasRole("____MAIL_C")) {
			this.setState({selectedContaEmail: null});
			this.selectContaEmail({data: {
				status: "ATIVO",
				finalidade: "GERAL"
			}});
		}
	}

	columns = [
		<TZMColumn key={0} sortable field="nome" header="Nome" />,
		<TZMColumn key={0} sortable field="usuario" header="Usuário" />,
		<TZMColumn key={0} sortable field="host" header="Host" />,
		<TZMColumn key={0} sortable field="porta" header="Porta" />,
		<TZMColumn key={8} header="Ações" style={{width: "5em", textAlign: "center"}} body={(contaEmail) => {
			return (
				<div>
					{TZMUserData.hasRole("____MAIL_E") ? <span key={0} title="Editar Conta de E-mail" className="fa fa-edit ui-tzm-icon" onClick={() => this.selectContaEmail({data: contaEmail})} /> : null}
					{TZMUserData.hasRole("____MAIL_D") ? <span key={1} title="Remover Conta de E-mail" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.deleteContaEmail({data: contaEmail})} /> : null}
				</div>
			);
		}} />
	];

	deleteContaEmail = (contaEmail) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.contaEmailService.excluir(contaEmail).then(this.handleList);
		}} question="Tem certeza de que deseja excluir esta contaEmail?" />);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-12">
										<TZMTextField autoFocus placeholder="Código" onChange={(event) => this.setState({params: {...this.state.params, contaEmail: event.target.value}})} value={this.state.params.contaEmail} label="ContaEmail" />
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								{TZMUserData.hasRole("____MAIL_C") ? <TZMButton success={true} icon="fas fa-plus" label="Nova Conta de E-mail" onClick={this.newContaEmail} /> : null}
								<TZMButton primary={true} icon="fas fa-search" label="Procurar" onClick={this.handleList} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.list} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				{this.state.dialogs}
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.handleList}
					onCtrlH={this.toggleHidden}
					onCtrlN={this.newContaEmail}
				/>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
