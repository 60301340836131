import { FichaTecnicaService } from "../servicos/FichaTecnicaService";
import TZMCombobox from "../../../components/common/TZMCombobox";
import TZMForm from "../../../components/common/TZMForm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMDialog from "../../../components/dialog/TZMDialog";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTextField from "../../../components/common/TZMTextField";
import { Column } from "primereact/components/column/Column";
import { toDecimal } from "../../../components/common/TZMFormatter";
import TZMTable from "../../../components/table/TZMTable";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import { VisualizarFichaTecnicaProduto } from "./VisualizarFichaTecnicaProduto";
import TZMUserData from "../../../utilities/TZMUserData";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
export class FichaTecnicaProdutoAtributo extends React.Component {
    

	constructor(props) {
        super();
		this.state = {            
            itemPedido: props.itemPedido,
            fichaTecnicaId: props.fichaTecnica,
            versao: props.versao,                
            params: {
                produtoAtivo: true,
                comprimentoRolo: '0',
                material: null,
                diametroTubete: null,
                sentidoRebobinagem: null,
            },
            visible: true,
            produtos:[]
        };        
        this.fichaTecnicaService = new FichaTecnicaService();        
    }

    onHide = () => this.setState({ visible: false });

    componentDidMount(){
        this.fichaTecnicaService.materiais().then((materiais) =>{this.setState({  materiais });})
        this.fichaTecnicaService.diametrosTubete().then((diametrosTubete) =>{this.setState({  diametrosTubete });})
        this.fichaTecnicaService.sentidosRebobinagem().then((sentidosRebobinagem) =>{this.setState({  sentidosRebobinagem });})
    }
    
    handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
    }

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
    
    checkNullArray(value) {
		return value ? value : [];
	}

    handleList = async () => {		
        let params = this.state.params;
        params.fichaTecnicaId = this.state.fichaTecnicaId;
        params.versao = this.state.versao;
        await this.fichaTecnicaService.listarFichaTecnicaProdutos(params).then((produtos) => this.setState({produtos}));
    }
    
    confirmarSelecao = (produto) => {
        ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => this.onSave(produto)} question={`Deseja selecionar o produto ${produto.descricao} ?`} />);	
	}

    onSave = (produto) => {        
        let itemPedido = this.state.itemPedido;
        itemPedido.unidade = produto.unidade;
        itemPedido.produto = produto;   
        if (this.props.onSalvar) {
            this.props.onSalvar(itemPedido);
            this.onHide();
        }
    }
    
    visualizarFichaTecnicaProduto = (produto) => {
        let params = [];        
        params.fichaTecnicaId = this.state.fichaTecnicaId;
        params.versao = this.state.versao;
        params.produtoId = produto.pk.produtoId;        
        this.fichaTecnicaService.completar(params).then((response) => {
            ModalRealm.showDialog(<VisualizarFichaTecnicaProduto key={TZMUserData.getIdGenerator()}  fichaTecnica={response} produto={produto} />);
        });
	}

    columns = [		
        <Column  field="fichaTecnicaId" key="fichaTecnicaId" style={{ textAlign: "center", width: "4.5em" }} header="Ficha Técnica" body={() => {
            return <div style={{ textAlign: "right" }}>{this.state.fichaTecnicaId}</div>;
        }} />,
        <Column  field="versao" key="versao" style={{ textAlign: "center", width: "4.5em" }} header="Versão" body={() => {
            return <div style={{ textAlign: "right" }}>{this.state.versao}</div>;
        }} />,

        <Column  field="pk.produtoId" key="produtoId" style={{ textAlign: "center", width: "10em" }} header="Produto" />,
        <Column  field="descricao" key="descricao" style={{width:"*", textAlign: "right" }} header="Descrição" />,
        <Column key="saldoDisponivel"  field="saldoDisponivel"  style={{ width: "9.5em" }}header="Saldo Disponível"  body={(rowData) => {
            return <div style={{ textAlign: "right" }}>{toDecimal(rowData["saldoDisponivel"])}</div>;
        }} />,        
        <Column field="unidade" key="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />,        
        <Column field="nomeConhecido" key="nomeConhecido" style={{ textAlign: "center", width: "13em" }} header="Tradução" />,
        <Column field="posicao" key="posicao" style={{ textAlign: "center", width: "8em" }} header="Posição" />,        
        <Column style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
            let botoes = [];            
            botoes.push(<span key={0} title="Visualizar Ficha Técnica Produto"  className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarFichaTecnicaProduto(rowData)} />);
            if (rowData.posicao === 'ATIVO')  {
                botoes.push(<span key={1} title="Selecionar Produto"  className="fa fa-shopping-bag ui-tzm-icon" onClick={() => this.confirmarSelecao(rowData)} />);
            }
            return <div style={{ textAlign: "center" }}>{botoes}</div>;            
        }}/>
    ]   
    
	render() {
        return (
        <TZMDialog style={{ width: "1200px" }} visible={this.state.visible} modal header="Ficha Técnica Atributo" onHide={this.onHide} {...this.props}>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <div className="ui-g-3">
                            <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("comprimentoRolo", event)} label="Comprimento Rolo" value={this.state.params.comprimentoRolo} />
                        </div>                    
                        <div className="ui-g-3">
                            <TZMCombobox name="material" label="Material" onChange={this.handleCombobox} value={this.state.params.material}>
                                {[<option key={"semMaterial"} value={null}>Nenhum registro selecionado...</option>, ...this.checkNullArray(this.state.materiais).map((material) =>
                                    <option key={material.produtoId} value={material.descricao}>{material.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-3">
                            <TZMCombobox name="diametroTubete" label="Diametro Tubete" onChange={this.handleCombobox} value={this.state.params.diametroTubete}>
                                {[<option key={"semDiametro"} value={null}>Nenhum registro selecionado...</option>, ...this.checkNullArray(this.state.diametrosTubete).map((diametroTubete) =>
                                    <option key={diametroTubete.produtoId} value={diametroTubete.descricao}>{diametroTubete.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-3">
                            <TZMCombobox name="sentidoRebobinagem" label="Sentido Rebobinagem" onChange={this.handleCombobox} value={this.state.params.sentidoRebobinagem}>
                                {[<option key={"semRebo"} value={null}>Nenhum registro selecionado...</option>, ...this.checkNullArray(this.state.sentidosRebobinagem).map((sentidoRebobinagem) =>
                                    <option key={sentidoRebobinagem.conteudoAlfa} value={sentidoRebobinagem.descricao}>{sentidoRebobinagem.descricao}</option>)
                                ]}
                            </TZMCombobox>
                        </div>
                        <div className="ui-g-12 ui-g-nopad" >                            
                            <Checkbox inputId="produtoAtivo" onChange={(event) => this.handleUpdate("produtoAtivo", {...event, target: {value: event.checked}})} checked={this.state.params.produtoAtivo} />
                            <label htmlFor="produtoAtivo" className="ui-checkbox-label">{`Somente Produtos Ativos`} </label>                            
                        </div>

                    </div>                        
                    <TZMPanelFooter>
                        <TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
                    </TZMPanelFooter>
                </TZMForm>
            </TZMPanel>
            <TZMPanel>
                <TZMForm>
                    <div className="ui-g">
                        <TZMTable value={this.state.produtos} paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15} >                
                            {this.columns}
                        </TZMTable>
                    </div> 
                </TZMForm>
            </TZMPanel>
            <TZMPanelFooter>
				<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />
			</TZMPanelFooter>            
        </TZMDialog>
        );
    }
}