import React from "react";
import TZMDialog from "./TZMDialog";
import TZMShortcut from "../../utilities/TZMShortcut";
import TZMPanelFooter from "../../components/panel/TZMPanelFooter";
import { Button } from "primereact/components/button/Button";
export default class TZMConfirm extends React.Component {

	constructor() {
		super();
		this.state = {
			visible: true
		};
	}

	fecharPainel = () => {
		this.setState({visible: false});
	}

	onYes = () => {
		if (this.props.onYes) {
			this.props.onYes();
		}
		this.fecharPainel();
	}

	onNo = () => {
		if (this.props.onNo) {
			this.props.onNo();
		}
		this.fecharPainel();
	}

	render() {
		return (
			<TZMDialog header={this.props.header != null ? this.props.header : "Confirmação"} visible={this.state.visible} modal {...this.props}>				 
				<div  className="ui-question-message">{this.props.question}</div>						
				<TZMPanelFooter>
					<Button className="ui-button-success ui-tzm-button"  icon="fas fa-check" label="Sim" onClick={this.onYes} />
					<Button className="ui-button-danger ui-tzm-button"  icon="fas fa-close" label="Não" onClick={this.onNo} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onEscape={this.fecharPainel} onS={this.onYes} onN={this.onNo} />
			</TZMDialog>
		);
	}

}
