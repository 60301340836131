import React from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMTable from "../../../components/table/TZMTable";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMCombobox from "../../../components/common/TZMCombobox";
import { FichaTecnicaService } from "../servicos/FichaTecnicaService";
import TZMTextField from "../../../components/common/TZMTextField";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { toDecimal } from "../../../components/common/TZMFormatter";
import { Column } from "primereact/components/column/Column";
import VisualizarFichaTecnica from "./VisualizarFichaTecnica";
import TZMUserData from "../../../utilities/TZMUserData";


const title = "Ficha Técnica";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class FichaTecnica extends React.Component {

	constructor() {
		super();		
		this.state = {	
			negociacoes:[],			
			params: {
				produto: null,
                comprimentoRolo: null,
                material: null,
                diametroTubete: null,
                sentidoRebobinagem: null,
                fichaTecnicaId: null,
                versao: null,
				todasVersoes: true
			}
		};

		this.fichaTecnicaService = new FichaTecnicaService(); 

	}

	visualizarFichaTecnica = (fichaTecnica) => {
        let params = [];
        params.fichaTecnicaId = fichaTecnica.fichaTecnicaId;
        params.versao = fichaTecnica.versao;        
        this.fichaTecnicaService.completarFichaTecnicaProdutos(params).then((response) => {
            ModalRealm.showDialog(<VisualizarFichaTecnica key={TZMUserData.getIdGenerator()} fichaTecnica={response}  />);
        });
	}

	componentDidMount() {
		this.fichaTecnicaService.materiais().then((materiais) => {this.setState({materiais });});
		this.fichaTecnicaService.diametrosTubete().then((diametrosTubete) =>{this.setState({  diametrosTubete });});
		this.fichaTecnicaService.sentidosRebobinagem().then((sentidosRebobinagem) =>{this.setState({  sentidosRebobinagem });});
	}

	handleCombobox = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({ params });
	}

	handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
	
	checkNullArray(value) {
		return value ? value : [];
	}

	handleList = () =>{			
        this.fichaTecnicaService.listar(this.state.params).then((fichasTecnicas) => this.setState({fichasTecnicas}));
	}
	
    columns = [		
        <Column sortable field="fichaTecnicaId" key="fichaTecnicaId" style={{ textAlign: "center", width: "4.5em" }} header="Ficha Técnica" />,
        <Column sortable field="versao" key="versao" style={{ width: "3em", textAlign: "right" }} header="Versão" />,
        <Column field="descricao" key="descricao" style={{ width: "6em", textAlign: "right" }} header="Descrição" />,        
        <Column key="largura" style={{ width: "4em" }} field="largura"  header="Largura"  body={(rowData) => {
            return <div style={{ textAlign: "right" }}>{toDecimal(rowData["largura"])}</div>;
        }} />,
        <Column key="altura" style={{ width: "4em" }} field="altura"  header="Altura"  body={(rowData) => {
            return <div style={{ textAlign: "right" }}>{toDecimal(rowData["altura"])}</div>;
        }} />,        
        <Column field="imagem" key="imagem" style={{ width: "5em", textAlign: "right" }} header="Imagem da Ficha" />,
        <Column style={{ width: "3em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
            let botoes = [];                        
            botoes.push(<span key={0} title="Visualizar Ficha Técnica"  className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarFichaTecnica(rowData)} />);            
            return <div style={{ textAlign: "center" }}>{botoes}</div>;
        }} />
    ]

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}> 
							<TZMForm>
								<div className="ui-g">
									<div className="ui-g-3">
										<TZMTextField name="produto"  onChange={(event) => this.handleUpdate("produto", event)} label="Produto" placeholder="Descrição, SKU" value={this.state.params.produto} />	                               
									</div>
									<div className="ui-g-3">
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("fichaTecnicaId", event)} label="Ficha Técnica" value={this.state.params.fichaTecnicaId} />
									</div>
									<div className="ui-g-2">
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("versao", event)} label="Versão" value={this.state.params.versao} />
									</div>
									<div className="ui-g-2">
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("largura", event)} label="Largura" value={this.state.params.largura} />										
									</div>
									<div className="ui-g-2">
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("altura", event)} label="Altura" value={this.state.params.altura} />
									</div>
									<div className="ui-g-3">
										<TZMCombobox name="material" label="Material" onChange={this.handleCombobox} value={this.state.params.material}>
											{[<option key={"semMaterial"} value={null}>{null}</option>, ...this.checkNullArray(this.state.materiais).map((material) =>
												<option key={material.produtoId} value={material.descricao}>{material.descricao}</option>)
											]}
										</TZMCombobox>
									</div>
									<div className="ui-g-3">
										<TZMCombobox name="diametroTubete" label="Diametro Tubete" onChange={this.handleCombobox} value={this.state.params.diametroTubete}>
											{[<option key={"semDiametro"} value={null}>{null}</option>, ...this.checkNullArray(this.state.diametrosTubete).map((diametroTubete) =>
												<option key={diametroTubete.produtoId} value={diametroTubete.descricao}>{diametroTubete.descricao}</option>)
											]}
										</TZMCombobox>
									</div>
									<div className="ui-g-3">
										<TZMCombobox name="sentidoRebobinagem" label="Sentido Rebobinagem" onChange={this.handleCombobox} value={this.state.params.sentidoRebobinagem}>
											{[<option key={"semRebo"} value={null}>{null}</option>, ...this.checkNullArray(this.state.sentidosRebobinagem).map((sentidoRebobinagem) =>
												<option key={sentidoRebobinagem.conteudoAlfa} value={sentidoRebobinagem.descricao}>{sentidoRebobinagem.descricao}</option>)
											]}
										</TZMCombobox>
									</div>
									<div className="ui-g-3">
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("comprimentoRolo", event)} label="Comprimento Rolo" value={this.state.params.comprimentoRolo} />
									</div>

								</div>                        
								<TZMPanelFooter>
										<div className="ui-g-4" style={{textAlign: "left"}} >                                
											<Checkbox inputId="versoes" onChange={(event) => this.handleUpdate("todasVersoes", {...event, target: {value: event.checked}})} checked={this.state.params.todasVersoes} />
											<label htmlFor="versoes" className="ui-checkbox-label">Todas as versões </label>
										</div>
									<TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />
								</TZMPanelFooter>
							</TZMForm>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.fichasTecnicas} paginator rows={50} >
								{this.columns}
							</TZMTable>	
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}

