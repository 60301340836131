import { httpGet, httpDelete, httpPost, httpGetAsync } from "../../utilities/TZMRequest";

export class ListaPrecoService {

	listar(params) {
        return httpGet("/listas-preco", params, false);
	}
	
	listarSimples(params) {
        return httpGet("/listas-preco/listar-simples", params, false);
	}	
	
	completar(listaPreco) {
		return httpGet(`/listas-preco/${listaPreco.id}`);
	}	
	
	salvar(listaPreco) {
		return httpPost("/listas-preco", listaPreco);
	}

	excluir(listaPreco) {
		return httpDelete("/listas-preco", listaPreco.id);
	}

	autoCompletar = async (params) => {
        return await httpGetAsync("/listas-preco/listar-simples", params, false);
	}
	
	listarProdutosListaPreco(params) {		
		return httpGet("/listas-preco/listar-produtos-lista-preco", params);
	}

	publicar(listaPreco) {
		return httpPost("/listas-preco", listaPreco);
	}

}
