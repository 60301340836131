import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { FichaTecnicaService } from "../servicos/FichaTecnicaService";
import { Column } from "primereact/components/column/Column";
import TZMTable from "../../../components/table/TZMTable";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import { toDate, toDecimal } from "../../../components/common/TZMFormatter";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { FichaTecnicaProdutoAtributo } from "./FichaTecnicaProdutoAtributo";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMUserData from "../../../utilities/TZMUserData";
import LazyImage from "../../../utilities/LazyImage";
import { VisualizarFichaTecnica } from "./VisualizarFichaTecnica";

const basePath = process.env.REACT_APP_API_HOST || "";

export class FichaTecnica extends React.Component {
    
    constructor(props) {
		super();
		this.state = {            
            params: {
                altura: null,
                largura:null,
                fichaTecnicaId:null,
                versao:null,
                todasVersoes: true
            },
            visible: true,
            fichasTecnicas:[],
			itemPedido: props.itemPedido
        };        
        this.fichaTecnicaService = new FichaTecnicaService();        
    }

    handleList = async () => {			
        await this.fichaTecnicaService.listar(this.state.params).then((fichasTecnicas) => this.setState({fichasTecnicas}));
	}
    
    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
    
    dateFormat(rowData, column) {
		let campo = rowData[column.field];
		if (campo !== null) {
			return toDate(campo);
		}
		return null;
	}

    onHide = () => this.setState({ visible: false });
    
    selecionarFichaTecnicaProdutoAtributo = (itemPedido,fichaTecnica, versao) => {
        ModalRealm.showDialog(<FichaTecnicaProdutoAtributo key={TZMUserData.getIdGenerator()} itemPedido={itemPedido} fichaTecnica={fichaTecnica
        } versao={versao} onSalvar={(itemPedidoSalvar) => {                        
            if (this.props.onSalvar) {
                this.props.onSalvar(itemPedidoSalvar);
            }
            this.setState({itemPedido: itemPedidoSalvar, alterado: true ,visible: false});
        }} />);
    }
        
    visualizarFichaTecnica = (fichaTecnica) => {
        let params = [];
        params.fichaTecnicaId = fichaTecnica.fichaTecnicaId;
        params.versao = fichaTecnica.versao;        
        this.fichaTecnicaService.completar(params).then((response) => {
            ModalRealm.showDialog(<VisualizarFichaTecnica key={TZMUserData.getIdGenerator()}  fichaTecnica={response}  />);
        });
	}

    columns = [		
        <Column sortable field="fichaTecnicaId" key="fichaTecnicaId" style={{ textAlign: "center", width: "4.5em" }} header="Ficha Técnica" />,
        <Column sortable field="versao" key="versao" style={{ width: "3em", textAlign: "right" }} header="Versão" />,
        <Column field="descricao" key="descricao" style={{ width: "6em", textAlign: "right" }} header="Descrição" />,        
        <Column key="largura" style={{ width: "4em" }} field="largura"  header="Largura"  body={(rowData) => {
            return <div style={{ textAlign: "right" }}>{toDecimal(rowData["largura"])}</div>;
        }} />,
        <Column key="altura" style={{ width: "4em" }} field="altura"  header="Altura"  body={(rowData) => {
            return <div style={{ textAlign: "right" }}>{toDecimal(rowData["altura"])}</div>;
        }} />,        
        <Column field="imagem" key="imagem" style={{ width: "5em", textAlign: "right" }} header="Imagem da Ficha" />,
        <Column style={{ width: "3em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
            let botoes = [];                        
            botoes.push(<span key={0} title="Visualizar Ficha Técnica"  className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarFichaTecnica(rowData)} />);
            botoes.push(<span key={1} title="Selecionar Ficha Técnica"  className="fa fa-list-ol ui-tzm-icon" onClick={() =>  this.selecionarFichaTecnicaProdutoAtributo(this.state.itemPedido,rowData.fichaTecnicaId, rowData.versao)} />);
            return <div style={{ textAlign: "center" }}>{botoes}</div>;
        }} />
    ]
    
    fecharPainel = () => {
		this.setState({ visible: false });
    }    
        
	render() {
		return (
			<TZMDialog style={{width: "1400px"}} visible={this.state.visible} modal header="Ficha Técnica" onHide={this.onHide} {...this.props}>
                <TZMPanel>
                    <TZMForm>
                        <div className="ui-g ">
                            <div className="ui-g-2">
                                <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("largura", event)} label="Largura" value={this.state.params.largura} />
                            </div>
                            <div className="ui-g-2">
                                <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("altura", event)} label="Altura" value={this.state.params.altura} />
                            </div>
                            <div className="ui-g-2">
                                <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("fichaTecnicaId", event)} label="Ficha Técnica" value={this.state.params.fichaTecnicaId} />
                            </div>
                            <div className="ui-g-2">
                                <TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("versao", event)} label="Versão" value={this.state.params.versao} />
                            </div>
                            <div className="ui-g-4" >                                
                                <Checkbox inputId="versoes" onChange={(event) => this.handleUpdate("todasVersoes", {...event, target: {value: event.checked}})} checked={this.state.params.todasVersoes} />
                                <label htmlFor="versoes" className="ui-checkbox-label">Todas as versões </label>
                            </div>
                        </div>
                        <TZMPanelFooter>
                            <TZMButton icon="fas fa-search" label="Procurar" onClick={this.handleList} />                            
                        </TZMPanelFooter>
                    </TZMForm>
                </TZMPanel>                 
                <TZMPanel>
                    <div className="ui-g">
                        <div className="ui-g-9">
                            <TZMTable selectionMode="single" selection={this.state.selected} value={this.state.fichasTecnicas} 
                                    onSelectionChange={e => this.setState({selected: e.data})}                                     
                                    header="Selecione uma Ficha Técnica" paginator rows={14} rowsPerPageOptions={[14, 28, 42]} pageLinkSize={15}>                 
                                {this.columns}
                            </TZMTable>
                        </div>
                        <div className="ui-gallery-image" header="Imagem da ficha tecnica" >                            
                            {this.state.fichasTecnicas.length > 0 && this.state.selected ?
                                <LazyImage key={this.state.selected.imagem}  src={`${basePath}/public/omega/imagens/thumb/290x430/${this.state.selected.imagem}`} /> : null }                            
                        </div>
                    </div>
                </TZMPanel>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fecharPainel} />
                </TZMPanelFooter>            
			</TZMDialog>
      
		);
    }
    

}
