import  React  from "react";
import { Column } from "primereact/components/column/Column";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMTable from "../../../components/table/TZMTable";
import { OrdemServicoService } from "../servicos/OrdemServicoService";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMTextField from "../../../components/common/TZMTextField";

export class EditarGestaoOrdemServico extends React.Component {

	constructor(props) {
        super(props);        
		this.state = {
            ordemServico: this.props.ordemServico,
            params: {
                produto: { pk: {}, descricao: "" }
            },
            alterado: false,
			visible: true
		};
        
        this.ordemServicoService = new OrdemServicoService();
	}

    onHide = () => this.setState({visible: false});

    columns = [				
		<Column header="Produto" key="produto" field="produto.pk.produtoId" style={{textAlign: "left",width: "*", }} />,		
		<Column header="Matéria Prima" key="material" field="materiaPrimaId" style={{textAlign: "left",width: "10em", }} />,
        <Column header="Quantidade Pedido" key="quantidadePedido" field="quantidadePedido" style={{textAlign: "left",width: "10em", }} />,
        <Column header="Quantidade Convertida" key="quantidadeConvertida" field="quantidadeConvertida" style={{textAlign: "left",width: "10em", }} />,
        <Column header="Quantidade Roteiro" key="quantidadeRoteiro" field="quantidadeRoteiro" style={{textAlign: "left",width: "10em", }} />,
        <Column header="Quantidade Alocada" key="quantidadeAlocada" field="quantidadeAlocada" style={{textAlign: "left",width: "10em", }} />,
        <Column header="Saldo" key="saldo" field="saldo" style={{textAlign: "left",width: "10em", }} />,
        <Column header="Saldo Convertido" key="saldoReal" field="saldoReal" style={{textAlign: "left",width: "10em", }} />,
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(ordemServico) => { 
			let botoes = [];			
			if (TZMUserData.hasRole("GER_NEGO_E")) {
				// botoes.push(<span key={0} title="Editar Negociação" className="fa fa-edit ui-tzm-icon" onClick={() => this.editar({data: ordemServico})} />);				
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;	
		}} />
	];

    produto = (rowData) =>{
        
    }

    render(){
        return(
            <TZMDialog style={{width: "1200px"}} visible={this.state.visible} modal header="Gestão de Ordem de Serviço" onHide={this.onHide} {...this.props}>
                <div className="ui-g">
					<div className="ui-g-4">
						<TZMTextField  name="ordemProducao" value={this.state.ordemServico.ordemProducao} label="Ordem Serviço" disabled/>
					</div>							
					<div className="ui-g-2">
						
					</div>
				</div>				

                    <div className="ui-g">
                        <div className="ui-g-12">
                            <TZMTable selection={this.state.selectedItemPedido} value={this.state.ordemServico.itens} children={this.columns} />
                        </div>
                    </div>
                
				<TZMPanelFooter>
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />									
				</TZMPanelFooter>				
			</TZMDialog>
        )
    }
}
	