import { httpGet, httpGetAsync, httpPost } from "../../utilities/TZMRequest";

export class ClienteService {
    
    completar(cliente) {
		return httpGet(`/clientes/${cliente.id}`);
	}
	
	listarSimples = async (params) => {
        return  httpGetAsync("/clientes/listar-simples", params, true);
	}
	
	autoComplete = async (params) => {		
        return await httpGetAsync("/clientes/listar-simples", params, false);
	}
	
	listar(params) {
		return httpGet("/clientes", params);
	}

	query(params) {
		return httpGet(`/clientes/query?${Object.keys(params).map(k => `${k}=${params[k]}`).join("&")}`);
    }
    
    salvar(cliente) {
		return httpPost("/clientes", cliente);
	}

	atualizar  = async () => {
        return httpGetAsync("/clientes/atualizar");
    }
 
}
