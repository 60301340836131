import { httpDelete, httpGet, httpGetAsync, httpPost } from "../../utilities/TZMRequest";

export class AlcadaService {

	completar(alcada) {
		return httpGet(`/alcadas/${alcada.id}`);
	}

	listar(params) {
		return httpGet("/alcadas", params);
	}

	salvar(alcada) {
		return httpPost("/alcadas", alcada);
	}

	excluir(alcada) {
		return httpDelete("/alcadas", alcada.id);
	}

	async buscar(alcadaPerfilId) {
		return await httpGetAsync(`/alcadas/vendedor/${alcadaPerfilId}`);
	}

}
