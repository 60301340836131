import { httpGet, httpDelete, httpPost } from "../../utilities/TZMRequest";

export class ListaPrecoVersaoService {

	listar(params) {
        return httpGet("/listas-preco-versao", params, false);
	}
	
	listarSimples(params) {
        return httpGet("/listas-preco-versao/listar-simples", params, false);
	}	
	
	completar(listaPreco) {
		return httpGet(`/listas-preco-versao/${listaPreco.id}`);
	}	
	
	salvar(listaPreco) {
		return httpPost("/listas-preco-versao", listaPreco);
	}

	excluir(listaPreco) {
		return httpDelete("/listas-preco-versao", listaPreco.id);
	}

	publicar(listaPreco) {
		return httpPost("/listas-preco-versao", listaPreco);
	}

	listarProdutosListaPreco(params) {		
		return httpGet("/listas-preco-versao/listar-produtos-lista-preco", params);
	}

}
