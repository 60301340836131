import moment from "moment";
import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMUserData from "../../../utilities/TZMUserData";
import { ProdutoUtils } from "../../comum/produto/ProdutoUtils";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { OrdemServicoService } from "../servicos/OrdemServicoService";
import { EditarGestaoOrdemServico } from "./EditarGestaoOrdemServico";
import { OrdemServicoUtils } from "./OrdemServicoUtils";


const title = "Gestão de Ordem de Serviço";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export default class GestaoOrdemServico extends React.Component {

	constructor() {
		super(); 
		moment.locale('pt-BR');
		this.state = {
			ordensServico: [],
			expandedRows: [],
			status: [OrdemServicoUtils.statusPendente(),OrdemServicoUtils.statusAguardandoInsumos()],
			params: {	
				produto: null,
				materiaPrima: null,				
				ordemServico: null,
				pedido: null
			}
		};
		this.ordemServicoService = new OrdemServicoService();
		this.vwProdutoService = new VwProdutoService();
	}

	listar = async () => {
		let params = Object.assign({}, this.state.params);
		if (params.produto) {
			params.produto = params.produto.pk.produtoId;
		}
		if (params.materiaPrima) {
			params.materiaPrima = params.materiaPrima.pk.produtoId;
		}
		params.status = this.state.status;	
		await this.ordemServicoService.listarSimples(params).then((ordensServico) => this.setState({ordensServico}));
	}

	listarProdutos = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((produtos) => {this.setState({ produtos });});
	}

	listarMateriais = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((materiais) => {this.setState({ materiais });});
	}

	data = (rowData) => { return (<div>{moment(rowData.registro).format("DD/MM/YYYY HH:mm:ss")}</div>);}

	columns = [		
		<Column key="expander" style={{ width: "2em" }} expander />,		
		<Column header="Status" key="status" field="status" body={(rowData) => OrdemServicoUtils.columnStatus(rowData.status)} style={{textAlign: "center",width: "4em", }} />,
		<Column header="Ordem de Serviço" key="ordemServico" field="ordemServico" style={{textAlign: "left",width: "15em", }} />,
		<Column header="SKU" key="produtoId" field="produtoId" style={{textAlign: "left",width: "15em", }} />,
		<Column header="Produto" key="produtoDescricao" field="produtoDescricao" style={{textAlign: "left",width: "*", }} />,
		<Column header="Pedido" key="pedido" field="pedidoId" style={{textAlign: "center",width: "10em", }} />,		
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(ordemServico) => { 
			let botoes = [];
			console.log("ordemServico",ordemServico);
			switch (ordemServico.status) {
				case OrdemServicoUtils.statusPendente():
					botoes.push(<span key={0} title="Programar" className="fa fa-clipboard-list ui-tzm-icon" onClick={() => this.programado(ordemServico)} />);					
					botoes.push(<span key={1} title="Aguardando Insumos" className="fa fa-clipboard-check ui-tzm-icon" onClick={() => this.aguardandoInsumos(ordemServico)} />);			
					break;
				case OrdemServicoUtils.statusAguardandoInsumos():
					botoes.push(<span key={0} title="Pendente" className="fa fa-play-circle ui-tzm-icon" onClick={() => this.pendente(ordemServico)} />);					
					botoes.push(<span key={1} title="Programar" className="fa fa-clipboard-list ui-tzm-icon" onClick={() => this.programado(ordemServico)} />);
					break;
				case OrdemServicoUtils.statusProgramado():
					botoes.push(<span key={0} title="Pendente" className="fa fa-play-circle ui-tzm-icon" onClick={() => this.programado(ordemServico)} />);									
					botoes.push(<span key={1} title="Aguardando Insumos" className="fa fa-clipboard-check ui-tzm-icon" onClick={() => this.aguardandoInsumos(ordemServico)} />);					
					break;
				case OrdemServicoUtils.statusFinalizado:					
					break;			
				default:
					break;
			}
			
			
			return <div style={{ textAlign: "center" }}>{botoes}</div>;	
		}} />
	];

	visualizar = (event) => {
		this.setState({selectedOrdemServico: event.data});
		if (event.data != null) {			
			this.ordemServicoService.completarOrdemServicoItem(event.data.ordemProducao).then((itens) => {
				let ordemServico = Object.assign({}, event.data);
				ordemServico.itens = itens;
				ModalRealm.showDialog(<EditarGestaoOrdemServico key={TZMUserData.getIdGenerator()} ordemServico={ordemServico}  onModalClose={this.listar}  />);
			});			
		}
	}

	rowExpand = (event) => {
		if (this.state.expandedRows != null && this.state.expandedRows.length > event.data.length) {
			this.setState({ expandedRows: event.data });
		} else {
			if (event.data.length > 0) {								
				let item = event.data.pop();				
				this.ordemServicoService.completarOrdemServicoItem({produtoId: item.produtoId, ordemServico: item.ordemServico}).then((response) => {
					item.itens = response;
					this.setState({ expandedRows: [...event.data, item] });
				});
			} else {
				this.setState({ expandedRows: event.data });
			}
		}
	}

	rowExpansionTemplate = (data) => {
		return (
			<DataTable value={data.itens}>
				{/* <Column header="Data" key="data" field="data" name="data" style={{width: "15em", textAlign: "center"}} body={this.data} /> */}
				<Column header="Código Matéria Prima" key="material" field="materiaPrimaId" style={{textAlign: "center",width: "10em", }} />
				<Column header="Descrição Matéria Prima" key="material" field="materiaPrima.descricao" style={{textAlign: "left",width: "20em", }} />
        		<Column header="Qtd Pedido" key="quantidadePedido" field="quantidadePedido" style={{textAlign: "right",width: "5em", }} />
        		<Column header="Qtd Convertida" key="quantidadeConvertida" field="quantidadeConvertida" style={{textAlign: "right",width: "5em", }} />
        		<Column header="Qtd Roteiro" key="quantidadeRoteiro" field="quantidadeRoteiro" style={{textAlign: "right",width: "5em", }} />
        		<Column header="Qtd Alocada" key="quantidadeAlocada" field="quantidadeAlocada" style={{textAlign: "right",width: "5em", }} />
        		<Column header="Saldo" key="saldo" field="saldo" style={{textAlign: "right",width: "5em", }} />
        		<Column header="Saldo Convertido" key="saldoReal" field="saldoReal" style={{textAlign: "right",width: "5em", }} />
			</DataTable>
		);
	}

	handleUpdate = (event) => {
		console.log("event",event);
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}	

	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	aguardandoInsumos = (ordemServico) => {
		ModalRealm.showDialog(<TZMConfirm question={"Deseja alterar o status para Aguardando Insumos?"} onYes={() => {
			ordemServico.status = "AGUARDANDO_INSUMOS";		
			this.ordemServicoService.salvar(ordemServico);
		}} />);		
	}

	programado = (ordemServico) => {
		ModalRealm.showDialog(<TZMConfirm question={"Deseja alterar o status para Programado?"} onYes={() => {
			ordemServico.status = "PROGRAMADO";	
			this.ordemServicoService.salvar(ordemServico);
		}} />);		
	}

	pendente = (ordemServico) => {
		ModalRealm.showDialog(<TZMConfirm question={"Deseja alterar o status para Pendente?"} onYes={() => {
			ordemServico.status = "PENDENTE";
			this.ordemServicoService.salvar(ordemServico);			
		}} />);
	}

	render() {

		const optionsStatus = [            
            {label: 'Pendente', value: OrdemServicoUtils.statusPendente()},
            {label: 'Aguardando Insumos', value: OrdemServicoUtils.statusAguardandoInsumos()},
            {label: 'Programado', value: OrdemServicoUtils.statusProgramado()}
        ];
        return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}> 
							<TZMForm>
								<div className="ui-g">										
									<div className="ui-g-3">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ProdutoUtils.produtoTemplate} onSelect={this.handleUpdate} 
															onChange={this.handleUpdate} suggestions={this.state.produtos}  completeMethod={this.listarProdutos} 
															name="produto" field="descricao" label="Produto" placeholder="Descrição ou SKU"  value={this.state.params.produto} />
									</div>
									<div className="ui-g-3">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ProdutoUtils.produtoTemplate} onSelect={this.handleUpdate} 
															onChange={this.handleUpdate} suggestions={this.state.materiais}  completeMethod={this.listarMateriais} 
															name="materiaPrima" field="descricao" label="Matéria Prima" placeholder="Descrição ou SKU"  value={this.state.params.materiaPrima} />
									</div>
									<div className="ui-g-2">
										<TZMTextField type="number" placeholder="Número" name="ordemServico" label="Ordem Serviço" onChange={this.handleUpdate}  value={this.state.params.ordemServico} />
									</div>
									<div className="ui-g-2">
										<TZMTextField type="number" name="pedido" autoFocus placeholder="Número" label="Pedido" onChange={this.handleUpdate} value={this.state.params.pedido} />
									</div>
									<div className="ui-g-2 ui-textfield-group">
                                        <label className="ui-input-label">Status</label><br />
                                        <MultiSelect value={this.state.status} defaultLabel="Selecione" options={optionsStatus} 
													 name="status"  onChange={(e) => this.setState({status: e.value})}  style={{minWidth: "20em"}} />                                    
                                    </div>
								</div>
							</TZMForm>	
							<TZMPanelFooter >
								<TZMButton className="ui-button-primary ui-tzm-button"  icon="fas fa-search" label="Procurar" onClick={this.listar} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<DataTable ref={(el) => this.dt = el} emptyMessage="Nenhum registro encontrado" onRowToggle={this.rowExpand} rowExpansionTemplate={this.rowExpansionTemplate} 
										expandedRows={this.state.expandedRows} value={this.state.ordensServico} paginator rows={30} 
										rowsPerPageOptions={[30, 50, 100]}  children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
}
