import { AutoComplete } from "primereact/components/autocomplete/AutoComplete";
import React from "react";
import "./Common.css";

export default class TZMAutoComplete extends React.Component {
	
	state = {
		modified: false,
		empty: false
	};

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange({...event, name: this.props.name, field: this.props.field});
			// if (this.changeTimeout) {
			// 	clearTimeout(this.changeTimeout);
			// }
			// this.changeTimeout = setTimeout(() => {
			// 	this.setState({empty: this.props.value && this.props.value.length > 1 && this.props.suggestions != null && this.props.suggestions.length === 0});
			// }, 700);
		}
	}

	onClear = (event) => {
		if (this.props.onClear) {
			this.props.onClear({...event, name: this.props.name, field: this.props.field});
		}
	}

	onSelect = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect({...event, name: this.props.name, field: this.props.field});
		}
	}

	onUnselect = (event) => {
		if (this.props.onUnselect) {
			this.props.onUnselect({...event, name: this.props.name, field: this.props.field});
		}
	}
	

	render() {
		return (
			<div className="ui-textfield-group" style={{position: "relative"}}>
				<label className="ui-input-label">{this.props.label}</label><br />
				<AutoComplete appendTo={document.getElementById("root")} {...this.props} minLength={this.props.minLength || 3} onClear={this.onClear} onChange={this.onChange} onUnselect={this.onUnselect} 
				onSelect={this.onSelect} className="ui-autocomplete" />
				{/* {this.state.empty ? <div className="ui-suggestion-empty-message">Nenhum registro encontrado</div> : null} */}
			</div>
		);
	}

}
