import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMShortcut from "../../../utilities/TZMShortcut";
import { ProdutoService } from "../../servicos/ProdutoService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { FamiliaService } from "../../servicos/FamiliaService";

export class EditarProduto extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
            produto: props.produto,
            params: {
                produto: { pk: {}, descricao: "" }
            }
		};
        this.produtoService = new ProdutoService();		
		this.vwProdutoService = new VwProdutoService();
		this.familiaService = new FamiliaService();
	}

	onHide = () => this.setState({visible: false});

	handleChange = (event) => {
		let produto = this.state.produto;
		produto[event.name] = event.target.value;
		this.setState({produto});
	}

	salvarProduto = () => {
		let produto = Object.assign({}, this.state.produto);
		this.produtoService.salvar(produto).then((produto) => {
			if (this.props.onModalClose) this.props.onModalClose(produto);
			this.onHide();
		});
	}
	
    produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
		);
    }
  

    render() {
		return (
			<TZMDialog style={{width: "1000px"}} visible={this.state.visible} modal header="Produto" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<div className="ui-g">
						<div className="ui-g-6">
                            <TZMTextField label="SKU" disabled name="produtoId" value={this.state.produto.vwProduto.pk.produtoId}  />
                        </div>
						<div className="ui-g-6">
                            <TZMTextField label="Descrição" disabled name="descricao" value={this.state.produto.vwProduto.descricao}  />
                        </div>
						<div className="ui-g-2">
                            <TZMTextField label="Fator Unitário" disabled name="fatorUnitario" value={this.state.produto.vwProduto.fatorUnitario} />
                        </div>	
                        <div className="ui-g-2">
                            <TZMTextField label="Unidade" disabled name="unidade" value={this.state.produto.vwProduto.unidade}  />
                        </div>
						<div className="ui-g-2">
                            <TZMTextField label="Grupo" disabled name="grupoId" value={this.state.produto.vwProduto.grupoId}  />
                        </div>
						<div className="ui-g-3">
                            <TZMTextField label="Família" disabled name="familia" value={this.state.produto.vwProduto.familia.descricao}  />
                        </div>
						<div className="ui-g-3">
                            <TZMTextField label="Saldo Disponível" disabled name="saldoDisponivel" value={this.state.produto.vwProduto.saldoDisponivel}  />
                        </div>
                    </div>
				</TZMPanel>
				<TZMPanelFooter>
					{/* <TZMButton success label="Salvar" icon="fas fa-save" onClick={this.salvarProduto} /> */}
					<TZMButton secondary label="Fechar" icon="fas fa-times" onClick={this.onHide} />
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvarProduto} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
