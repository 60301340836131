
import { httpGetAsync } from "../../../utilities/TZMRequest";

export class PainelGerencialService {

     listar  = async (params) => {
        return httpGetAsync("/painel-gerencial/listar", params);
    }

    atualizar  = async () => {
        return httpGetAsync("/painel-gerencial/atualizar");
    }

    listarDetalhe  = async (params) => {
        return httpGetAsync("/painel-gerencial/listarDetalhe", params);
    }
    
}
