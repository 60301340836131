import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { EquipamentoService } from "../servicos/EquipamentoService";
import { EditarEquipamento } from "./EditarEquipamento";
import TZMCombobox from "../../../components/common/TZMCombobox";

const title = "Equipamentos";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Equipamento extends React.Component {

	state = {
		equipamentos: [],
		params: {
			search: {
				descricao: "",
				tipo: null
			}
		}
	};

	equipamentoService = new EquipamentoService();

	listarEquipamentos = () => {
		const params = Object.assign({}, this.state.params);
		if (params.search) {
			params.search = Object.keys(params.search).filter(k => params.search[k]).map(k => `${k}:${params.search[k]}`).join(";");
		}
		this.equipamentoService.listar(params).then((equipamentos) => this.setState({equipamentos}));
	}

	alternarOcultos = () => {
		this.setState({desativados: !this.state.desativados});
	}

	editarEquipamento = (equipamento) => {
		ModalRealm.showDialog(<EditarEquipamento equipamento={equipamento} onModalClose={this.listarEquipamentos} />);
	}

	criarEquipamento = () => {
		this.editarEquipamento({nome: ""});
	}

	excluirEquipamento = (equipamento) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.equipamentoService.excluir(equipamento).then(this.listarEquipamentos);
		}} question="Tem certeza de que deseja excluir este equipamento?" />);
	}

	columns = [
		<TZMColumn header="Descrição" key={0} field="descricao" style={{width: "*"}} />,
		<TZMColumn header="Ações" key={2} style={{width: "6em", textAlign: "center"}} body={(equipamento) => {
			return (
				<div>
					{TZMUserData.hasRole("____EQPT_E") ? <span title="Editar Equipamento" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarEquipamento(equipamento)} /> : null}
					{TZMUserData.hasRole("____EQPT_D") ? <span title="Remover Equipamento" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirEquipamento(equipamento)} /> : null}
				</div>
			);
		}} />
	];

	handleUpdate1 = (event) => {
		const {params} = this.state;
		params.search[event.name] = event.value;
		this.setState({params});
	}

	handleUpdate2 = (event) => {
		const {params} = this.state;
		params[event.name] = event.value;
		this.setState({params});
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">
									<TZMTextField col={8} name="descricao" autoFocus onChange={this.handleUpdate1} label="Equipamento" placeholder="Descrição" value={this.state.params.search.descricao} />
									<TZMCombobox col={4} label="Tipo" name="tipo" children={EditarEquipamento.TIPOS} value={this.state.params.search.tipo} onChange={this.handleUpdate1} />
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton title={this.state.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.alternarOcultos} secondary icon={this.state.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____EQPT_C") ? <TZMButton success icon="fas fa-plus" label="Novo Equipamento" onClick={this.criarEquipamento} /> : null}
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarEquipamentos} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.equipamentos} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<TZMShortcut active={TZMShortcut.modalCount === 0} onEnter={this.listarEquipamentos} onCtrlH={this.alternarOcultos} onCtrlN={this.criarEquipamento} />
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
