import { httpGet, httpDelete, httpPost, httpGetAsync} from "../../utilities/TZMRequest";

export class NegociacaoService {

	listar(params) {
		return httpGet("/negociacoes", params, false);
	}   
    
    listarSimples = async (params) => {
        return await httpGetAsync("/negociacoes/listar-simples", params, true);
    }
	
	completar(negociacao) {
		return httpGet(`/negociacoes/${negociacao.id}`);
	}	
	
	salvar(negociacao) {
		return httpPost("/negociacoes", negociacao);
	}

	renovar(negociacao){
		return httpPost("/negociacoes/renovar", negociacao)
	}

	excluir(negociacao) {
		return httpDelete("/negociacoes", negociacao.id);
	}

}
