import { AutoComplete } from "primereact/components/autocomplete/AutoComplete";
import React from "react";
import { PerfilService } from "../../pages/servicos/PerfilService";

export class NeoSuggestion extends React.Component {

    static Perfil = class extends React.Component {

        state = {
            perfis: []
        };

        perfilService = new PerfilService();

        render() {
            return <NeoSuggestion {...this.props} completeMethod={this.listarPerfis} field="descricao" suggestions={this.state.perfis} />;
        }

        listarPerfis = (event) => {
            this.perfilService.buscar(`search=descricao~${event.query.replace(/[^0-9a-zA-Z ]/g, " ")};ativado=true&page=0&sort=descricao&size=10`).then((perfis) => this.setState({perfis}));
        }

    }

    state = {
        value: ""
    };

    render() {
        return (
            <div>
                <label>{this.props.label}</label>
                <AutoComplete {...this.props} value={this.state.value.length ? this.state.value : this.props.value} onChange={this.onChange} onSelect={this.onSelect} onClear={this.onClear} />
            </div>
        );
    }

    onChange = (event) => {
        this.setState({value: event.value});
    }

    onSelect = (event) => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value: event.value, index: this.props.index});
            this.setState({value: ""});
        }
    }

    onClear = () => {
        if (this.props.value) {
            this.onSelect({value: null});
        }
    }

}
