import {httpDelete, httpGet, httpPost} from "../../../utilities/TZMRequest";

export class EquipamentoService {

	buscar(equipamento) {
		return httpGet(`/equipamentos/${equipamento.id}`);
	}

	listar(params) {
		return httpGet(`/equipamentos?${Object.keys(params).map(k => `${k}=${params[k]}`).join("&")}`);
	}

	salvar(equipamento) {
		return httpPost("/equipamentos", equipamento);
	}

	excluir(equipamento) {
		return httpDelete("/equipamentos", equipamento.id);
	}

}
