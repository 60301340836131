import { Column } from "primereact/components/column/Column";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMTextArea from "../../../components/common/TZMTextArea";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";

export class JustificativaDesconto extends React.Component {

    state = {
        visible: true,
        justificativa: null,
        itens: this.props.itens.filter(i => i.usuarioNome).sort((a, b) => a.nivel - b.nivel) || [],
        acao:  this.props.acao

    };

    onClose = () => {
        this.setState({visible: false});
    }

    handleChange = (event) => this.setState({[event.name]: event.value});

    onModalClose = () => {
        if (this.props.onModalClose) {
            this.props.onModalClose(this.state.justificativa);
        }
        this.onClose();
    }

    mostrarJustificativas = (itens) => {
        if (itens && itens.length > 0) {
                return (
                <div>
                    <TZMTable value={this.state.itens} header="Justificativas anteriores">
                        <Column key="nivel" name="nivel" style={{textAlign:"center" , width: "6em"}} field="nivel" header="Nível" />
                        <Column key="usuario"  name="usuario" style={{textAlign:"center" , width: "14em"}} field="usuarioNome" header="Usuário" />
                        <Column key="justificativa"  name="justificativa" style={{textAlign:"center" , width: "*"}} field="justificativa" header="Justificativa" />                            
                    </TZMTable> 
                </div>
            );
        }
        return null;
    }

    render() {        
        let msg1 = this.state.acao === "REPROVADO" ? "Informe a justificativa para reprovar a solicitação de desconto." : "O desconto que você está pedindo está acima da sua alçada!"
        let msg2 = this.state.acao === "REPROVADO" ? null : "Vamos encaminhar essa solicitação de desconto para uma alçada superior, mas precisamos que você nos informe o motivo pelo qual deseja concedê-lo.";
        let msg3 = this.state.acao === "REPROVADO" ? null : "Por favor, preencha a justificativa para o pedido de desconto dos itens deste pedido.";        
        return (
            <TZMDialog header="Justificativa de Desconto" style={{width: "550px"}} visible={this.state.visible} modal onHide={this.onClose}>
                <div className="header-desconto">
                    <div><i className="fas fa-exclamation" /></div>
                    <div>{msg1}<br/>{msg2}</div>
                </div>
                <p>{msg3}</p>
                <div className="ui-g">
                    <TZMTextArea col={12} name="justificativa" style={{height: "6em"}} onChange={this.handleChange} value={this.state.justificativa} />
                </div>
                {this.mostrarJustificativas(this.state.itens)}                
                <TZMPanelFooter>
                    <TZMButton className="ui-button-primary" label="Confirmar" icon="fas fa-check" onClick={this.onModalClose} />
                </TZMPanelFooter>
            </TZMDialog>
        );
    }

}
