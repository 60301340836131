import React from "react";
const APROVADO = "APROVADO";
const REPROVADO = "REPROVADO";
const ENCAMINHADO = "ENCAMINHADO";
const SOLICITADO = "SOLICITADO";
const ENFILEIRADO = "ENFILEIRADO";
const EM_ANDAMENTO = "EM_ANDAMENTO";
export class DescontoUtils {

	static LEGENDAS = {
		APROVADO : {value: "check", color: "#4cae4c", title: "Desconto Aprovado"},
		REPROVADO: {value: "exclamation", color: "#d43f3a", title: "Desconto Reprovado"},
		EM_ANDAMENTO : {value: "clock", color: "#2196F3", title: "Desconto em Andamento"}
	};	

	static statusAprovado() {return APROVADO;}

	static statusReprovado() {return REPROVADO;}

	static statusEncaminhado() {return ENCAMINHADO;}

	static statusSolicitado() {return SOLICITADO;}

	static statusEnfileirado() {return ENFILEIRADO;}

	static statusEmAndamento() {return EM_ANDAMENTO;}

	static statusDesconto(status) {				
		if (status) {
			let {value, color, title} = DescontoUtils.LEGENDAS[status];
			return <span key="statusDesconto" className={`fas fa-${value}`} title={title} style={{textAlign: "center",  color}}  />;
		}			
		return null;
	}

}