import {TZMSimulador} from "../../../components/common/TZMSimulador";
import TZMDialog from "../../../components/dialog/TZMDialog";
import React from "react";

export class SimuladorPopup extends React.Component {

	state = {
		visible: true
	};

	onHide = () => this.setState({visible: false});

	render() {
		return (
			<TZMDialog style={{width: "500px"}} visible={this.state.visible} header="Simulação" key={Math.random()} onHide={this.onHide}>
				<TZMSimulador disabled simulador={this.props.simulador} simulacao={this.props.simulacao} />
			</TZMDialog>
		);
	}

}
