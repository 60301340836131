import { UsuarioService } from "../pages/servicos/UsuarioService";
export default class TZMUserData {

	static ADMIN         = "ADMIN";
	static GERENTE       = "GERENTE";
	static SUPERVISOR    = "SUPERVISOR";
	static REPRESENTANTE = "REPRESENTANTE";
	static DIRETOR       = "DIRETOR";

	static _blankUser = {authorities: [], principal: {}, settings: {}};

	static usuarioService = new UsuarioService();

	static setUsuario(usuario) {		
		window.sessionStorage.setItem("X-User-Content", JSON.stringify(usuario));		
	}

	static resetUsuario() {
		window.localStorage.removeItem("X-Authorization-Key");
		window.sessionStorage.removeItem("X-User-Content");
		TZMUserData.setUsuario(TZMUserData._blankUser);
	}

	static getSettings(value, defaults = null) {
		const usuario = TZMUserData.getUsuario();
		return usuario.settings && usuario.settings[value] ? usuario.settings[value] : defaults;
	}

	static getPrincipal() {
		return TZMUserData.getUsuario();
	}

	static getEmpresaAAP() {
		return 'AAP';
	}

	static getEmpresaRFID() {
		return 'RFID';
	}

	static getEmpresaRRE() {
		return 'RRE';
	}

	static OMEGA() {
		return 'OMEGA';
	}

	static TZM(){
		return 'TZM';
	}
	
	static setSettings = async  (key, value)  => {
        try {
            console.log('Procurando usuario...')
            let usuario = await TZMUserData.usuarioService.completarAsync(TZMUserData.getUsuario());
            console.log('User find:', usuario);
            if (!usuario.settings) {
                usuario.settings = {};
            }
            usuario.settings[key] = value;
            console.log('Salvando usuario...')
            await TZMUserData.usuarioService.salvarAsync(usuario);
            console.log('Usuario salvo...')			
            window.location.reload();
            console.log('Reload true...')
        } catch (error) {
            console.log('Error',error);            
            window.location.reload();
        }
    }
	
	static getUsuario() {
		let usuario = window.sessionStorage.getItem("X-User-Content");
		return usuario ? JSON.parse(usuario) : TZMUserData._blankUser;
	}
	
	static isAutenticado() {
		return TZMUserData.getUsuario().authorities.length > 0;
	}

	static hasRole(role) {
		return TZMUserData.getUsuario().authorities.some(item => item.authority === role);
	}

	static isAdministrador() {
		return TZMUserData.getUsuario().nivel === this.ADMIN;
	}

	static isDiretor() {
		return TZMUserData.getUsuario().nivel === this.DIRETOR;
	}

	static isGerente() {
		return TZMUserData.getUsuario().nivel === this.GERENTE;
	}

	static isSupervisor() {
		return TZMUserData.getUsuario().nivel === this.SUPERVISOR;
	}

	static isRepresentante() {
		return TZMUserData.getUsuario().nivel === this.REPRESENTANTE;
	}

	static getIdGenerator() {
		return Math.floor((Math.random() * 10000000) + 1);
	}
	

}
