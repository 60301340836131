import moment from "moment";
import React from "react";
import { Fetch } from "../../utilities/Fetch";
export class IntegracaoService { 

	logsPedido(id) {
		return id ? Fetch.GetJSON(`/integracao/logs/pedido/${id}`) : { then: () => { } };
	}

	logsItemPedido(id, itemId) {
		return id ? Fetch.GetJSON(`/integracao/logs/item-pedido/${id}/${itemId}`) : { then: () => { } };
	}

	logsTextoEspecifico(id) {
		return id ? Fetch.GetJSON(`/integracao/logs/texto-especifico/${id}`) : { then: () => { } };
	}

	logsTextoInterno(id) {
		return id ? Fetch.GetJSON(`/integracao/logs/texto-interno/${id}`) : { then: () => { } };
	}

	statusPedido(id) {
		return id ? Fetch.GetJSON(`/integracao/status/pedido/${id}`) : { then: () => { } };
	}

	statusGeral(id) {
		return id ? Fetch.GetJSON(`/integracao/status/geral/${id}`) : { then: () => { } };
	}

	statusItemPedido(id, itemId) {
		return id ? Fetch.GetJSON(`/integracao/status/item-pedido/${id}/${itemId}`) : { then: () => { } };
	}

	statusTextoEspecifico(id) {
		return id ? Fetch.GetJSON(`/integracao/status/texto-especifico/${id}`) : { then: () => { } };
	}

	statusTextoInterno(id) {
		return id ? Fetch.GetJSON(`/integracao/status/texto-interno/${id}`) : { then: () => { } };
	}
	
	static columnStatus(rowData) {
		let value, color, title;
		if (rowData.descontos && rowData.descontos.some(d => d.itens.some(di => di.status === "APROVADO"))) {
			value = "thumbs-up";
			color = "#0558e7";
			title = "Desconto Aprovado";
		} else {
			switch (rowData.status) {
			case "BLOQUEADO":
				value = "lock";
				color = "#F44336";
				title = "Bloqueado";
				break;
			case "CANCELADO":
				value = "ban";
				color = "#F44336";
				title = "Cancelado";
				break;
			case "CARTEIRA":
				value = "wallet";
				color = "#607D8B";
				title = "Em Carteira";
				break;
			case "FATURADO":
				value = "check";
				color = "#4CAF50";
				title = "Faturado";
				break;
			case "ERRO":
				value = "times";
				color = "#F44336";
				title = "Erro";
				break;
			case "RASCUNHO":
				value = "shopping-cart";
				color = "#FF9800";
				title = "Rascunho";
				break;
			case "ALCADA_DESCONTO":
				value = "tag";
				color = "#4CAF50";
				title = "Desconto Pendente";
				break;
			case "DESCONTO_REPROVADO":
				value = "exclamation";
				color = "#FF9800";
				title = "Desconto Reprovado";
				break;
			case "PROCESSADO":
				value = "thumbs-up";
				color = "#0558e7";
				title = "Processado";
				break;
			default:
				value = "clock";
				color = "#2196F3";
				title = "Confirmado em (" + moment(rowData.confirmadoEm).format("DD-MM-YYYY HH:mm:ss") + ") / Aguardando Processamento ";
				break;
			}
		}
		return <span key="columnStatus" className={`fas fa-${value}`} title={title} style={{color}} />;
	}



}
