
import moment from 'moment';
import 'moment/min/locales';
import React from "react";
import { MetaService } from '../../servicos/MetaService';
import { Validator } from '../../../utilities/TZMValidator';
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPopup from '../../../components/dialog/TZMPopup';
import TZMConfirm from '../../../components/dialog/TZMConfirm';
import TZMDialog from '../../../components/dialog/TZMDialog';
import TZMPanelFooter from '../../../components/panel/TZMPanelFooter';
import TZMCalendar from '../../../components/common/TZMCalendar';
import TZMTextField from '../../../components/common/TZMTextField';
import TZMButton from '../../../components/common/TZMButton';
import TZMShortcut from '../../../utilities/TZMShortcut';

export default class EditarMeta extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			meta: this.props.meta,
			visible: true,
			alterado: false,
			params: {},			
		};
		this.metaService = new MetaService();
		moment.locale('pt-BR');
	}

	mesFormat(rowData, column) {
		return moment(rowData[column.field]).format("MMMM  [-] YYYY");
	} 

	handleUpdate = (key, event) => {
		let meta = this.state.meta;
		meta[key] = event.target.value;
		this.setState({ meta, alterado: true });
	}

	validarMeta = () => {

		let messages = [];
		let meta = this.state.meta;

		// if (Validator.isEmpty(meta.id)) {

		// 	let mes = moment(meta.meta).format("MMMM  [-] YYYY");
		// 	mes = mes.charAt(0).toUpperCase() + mes.slice(1);

		// 	this.metaService.listar({ data: meta.mes }).then((metas => {
		// 		if (!Validator.isEmpty(metas)) {		
		// 			messages.push("Já existe uma meta cadastrada para " + mes + ".");
		// 		}
		// 	}));

		// }

		if (Validator.isEmpty(meta.mes)) {
			messages.push("A data de referência do mês é obrigatória.");
		} 

		if (Validator.isEmpty(meta.metaLocal)) {
			messages.push("Meta Local é obrigatória.");
		}
		
		if (Validator.isEmpty(meta.metaInter)) {
			messages.push("Meta Exportação é obrigatória.");
		}	

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}
		
		return true;
	}

	salvarMeta = () => {
		if (this.validarMeta()) {
			this.metaService.salvar(this.state.meta).then((response) => {
				this.setState({ visible: false });
				if (this.props.onSave) {
					this.props.onSave(response);
				}
			});
		}
	}

	fecharPainel = () => {
		this.setState({ visible: false });
		if (this.props.onClose) {
			this.props.onClose(this.state.meta);
		}
	}


	fecharMeta = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	reativarMeta = () => {
		this.metaService.salvar({ ...this.state.Meta, ativado: true }).then(() => this.fecharPainel("onDelete"));
	}

	desativarMeta = () => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.metaService.excluir(this.state.Meta).then(() => this.fecharPainel("onDelete"));
		}} question="Tem certeza de que deseja desativar este usuário?" />);
	}

	render() {
		return (
			<TZMDialog style={{ width: "400px" }} visible={this.state.visible} modal header="Meta" onHide={this.onHide} {...this.props}>
				<div className="ui-g">
					<div className="ui-g-3"><TZMCalendar label="Mês" onChange={(event) => this.handleUpdate("mes", event)} value={moment(this.state.meta.mes).format("YYYY-MM")} /></div>
					<div className="ui-g-4"><TZMTextField type="number" onChange={(event) => this.handleUpdate("metaLocal", event)} label="Meta Local" value={this.state.meta.metaLocal} /></div>
					<div className="ui-g-4"><TZMTextField type="number" onChange={(event) => this.handleUpdate("metaInter", event)} label="Meta Exportação" value={this.state.meta.metaInter} /></div>
				</div>
				<TZMPanelFooter>
					{this.state.meta.id != null && this.state.meta.ativado ? <TZMButton style={{ float: "left" }} danger title="Desativar Meta" icon="fas fa-trash-alt" onClick={this.desativarMeta} /> : null}
					{/* {this.state.meta.id != null && !this.state.meta.ativado ? <TZMButton style={{ float: "left" }} title="Reativar Meta" icon="fas fa-eye" onClick={this.reativarMeta} /> : null} */}
					<TZMButton success={true} label="Salvar" icon="fas fa-save" onClick={this.salvarMeta} />
					<TZMButton secondary={true} label="Fechar" icon="fas fa-times" onClick={this.fecharMeta} />
				</TZMPanelFooter>
				<TZMShortcut
					active={this.state.visible}
					onCtrlS={this.salvarMeta}
					onEscape={this.fecharMeta}
					onAlt1={() => this.selectTabIndex(0)}
				/>
			</TZMDialog>
		);
	}
}
