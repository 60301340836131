import React from "react";

const STATUS_PENDENTE = "PENDENTE";
const STATUS_PROGRAMADO = "PROGRAMADO";
const STATUS_AGUARDANDO_INSUMOS = "AGUARDANDO_INSUMOS";
const STATUS_FINALIZADO = "FINALIZADO";

export class OrdemServicoUtils { 

    static statusPendente() {return STATUS_PENDENTE;}
	static statusProgramado() {return STATUS_PROGRAMADO;}
	static statusAguardandoInsumos() {return STATUS_AGUARDANDO_INSUMOS;}
	static statusFinalizado() {return STATUS_FINALIZADO;}

    static STATUS = {
		PENDENTE: {value: "play-circle", color: "#2196F3", title: "Pendente"},
		PROGRAMADO: {value: "clipboard-list", color: "#0558e7", title: "Programado"},
		AGUARDANDO_INSUMOS : {value: "clipboard-check", color: "#FF9800", title: "Aguardando Insumos"},
        FINALIZADO : {value: "boxes", color: "#4CAF50", title: "Finalizado"}        
	};	

    static columnStatus(status) {			
		if (status) {
			let {value, color, title} = OrdemServicoUtils.STATUS[status];
			return <span key="columnStatus" className={`fas fa-${value}`} title={title} style={{textAlign: "center", fontSize: "15px", color}}  />;
		}			
		return null;
	}
	
}