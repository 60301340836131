
import React from "react";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { DashboardDesconto } from "./DashboardDesconto";

const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Dashboard extends React.Component {
	
	render() {
		return (
			<TZMMainContent>				
				<DashboardDesconto />				
				<span className="ui-tzm-dashboard-title">{empresaNome}</span>
			</TZMMainContent>
		);
	}

}
