import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMButtonDesativado from "../../../components/common/TZMButtonDesativado";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import { EditarCliente } from "./EditarCliente";
import { ClienteService } from "../../servicos/ClienteService";
import TZMTextField from "../../../components/common/TZMTextField";
import { ListaPrecoService } from "../../servicos/ListaPrecoService";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { Column } from "primereact/components/column/Column";
import {ClienteUtils}  from "./ClienteUtils";

const title = "Clientes";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Cliente extends React.Component {

	state = {
		clientes:[],		
		params: {
			cliente: null,
			listaPreco: null,
			desativados: false,
			page: 0,
			size: 300,
			sort: "razaoSocial"
		}
	};

	listarClientes = () => {
		let params = Object.assign({}, this.state.params);				
		this.clienteService.listarSimples(params).then((clientes) => this.setState({clientes}));
	}

	listarClientesSemListaPreco = () => {
		let params = Object.assign({}, this.state.params);
		params.semListaPreco = true;				
		this.clienteService.listarSimples(params).then((clientes) => this.setState({clientes}));
	}

	clienteService = new ClienteService();
	listaPrecoService = new ListaPrecoService();
	
	editarCliente = (event) => {
		this.setState({selectedCliente: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.clienteService.completar(event.data).then((cliente) => {
					ModalRealm.showDialog(<EditarCliente key={TZMUserData.getIdGenerator()} cliente={cliente}  onModalClose={this.listarClientes}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarCliente key={TZMUserData.getIdGenerator()} cliente={event.data} onModalClose={this.listarClientes}  />);
			}
		}
	}

	handleUpdate = (event) => {		
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	}
	
	handleChange = (event) => {		
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({params});
	}
	
	handleClear = (event) => {
		let params = this.state.produto;
		params[event.name] = null;
		this.setState({params});
	}	

	handleRefresh = () => {
		this.clienteService.atualizar();
	}

	columns = [			
		<Column header="Situação" key="situacao" field="posicao" style={{textAlign: "center",width: "5em", }} />,		
		<Column header="Cliente" key="cliente" field="clienteId" style={{textAlign: "left",width: "10em", }} />,		
		<Column sortable header="Razão Social" key="razaoSocial" field="razaoSocial" style={{width: "*"}} body={(rowData) => ClienteUtils.clienteTipoNome(rowData)}/>,
		<Column sortable header="Nome Fantasia" key="nomeFantasia" field="nomeFantasia" style={{width: "25em"}} />,
		<Column header="CNPJ" key="cpfCnpjFormatado" field="cpfCnpjFormatado" style={{textAlign: "center",width: "12em"}} />,
		<Column header="Cidade" key="cidade" field="cidade" style={{width: "20em"}} />,
		<Column header="Estado" key="estado" field="estado" style={{textAlign: "center", width: "5em"}} />,
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(cliente) => { 
		return (
				<div>
					{TZMUserData.hasRole("____CLIE_E") ? <span title="Editar Cliente" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarCliente({data: cliente})} /> : null}					
				</div>
			);
		}} />
	];


	listarListaPreco = async (event) => {		
		await this.listaPrecoService.autoCompletar({descricao: event.query, sort:'descricao'}).then((listasPreco) => this.setState({listasPreco}));
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}> 
							<TZMForm>
								<div className="ui-g">									
									<div className="ui-g-6">
										<TZMTextField name="cliente" autoFocus onChange={this.handleUpdate} label="Descrição" placeholder="Razão Social,Nome Fantasia, CNPJ ou Código" value={this.state.params.cliente} />
									</div>
									<div className="ui-g-6 ui-g-nopad" >
										<div className="ui-g-11">									
											<TZMAutoComplete  onClear={this.handleClear} onSelect={this.handleChange} onChange={this.handleChange}
												suggestions={this.state.listasPreco}  completeMethod={this.listarListaPreco} name="listaPreco" field="descricao" 
												label="Lista Preço" placeholder="Descrição" value={this.state.params.listaPreco} />
										</div>
										<div className="ui-g-1 center-button">
											<TZMButton className="ui-button-secondary" title="Filtra apenas os clientes sem lista de preço configurada." icon="fas fa-file-invoice-dollar"  onClick={this.listarClientesSemListaPreco} />
										</div>
									</div>
								</div>
							</TZMForm>	
							<TZMPanelFooter >
								<TZMButtonDesativado  name="desativados" desativados={this.state.params.desativados}  onClick={this.handleChange} />
																
								{TZMUserData.isAdministrador() ? 
								<TZMButton icon="fas fa-sync" label="Atualizar" title="Integra os novos clientes." className="ui-button-secondary"  onClick={this.handleRefresh}  />
								: null}								
								<TZMButton icon="fas fa-search" label="Procurar" onClick={this.listarClientes} />
							</TZMPanelFooter>
							
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.clientes} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
	
}
