import preval from 'preval.macro';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/omega/theme.css";
import React from "react";
import { Switch } from "react-router";
import { Link, Route, withRouter } from "react-router-dom";
import "./App.css";
import TZMButton from "./components/common/TZMButton";
import TZMIcon from "./components/common/TZMIcon";
import TZMLogo from "./components/common/TZMLogo";
import TZMAppMenu from "./components/menubar/TZMAppMenu";
import TZMMenubar from "./components/menubar/TZMMenubar";
import { ModalRealm } from "./components/modal/ModalRealm";
import TZMSidebar from "./components/sidebar/TZMSidebar";
import TZMSidebarCard from "./components/sidebar/TZMSidebarCard";
import TZMSidebarCardFooter from "./components/sidebar/TZMSidebarCardFooter";
import { PainelVersao } from "./components/versao/PainelVersao";
import "./modules/font-awesome/css/fontawesome.css";
import "./modules/font-awesome/css/solid.css";
import CheatSheet from "./pages/CheatSheet";
import Usuario from "./pages/comum//usuario/Usuario";
import Alcada from './pages/comum/alcada/Alcada';
import { Budget } from "./pages/comum/budget/Budget";
import { Cliente } from './pages/comum/cliente/Cliente';
import ContaEmail from './pages/comum/conta-email/ContaEmail';
import { Gestor } from "./pages/comum/gestor/Gestor";
import { ListaPreco } from './pages/comum/listapreco/ListaPreco';
import { Perfil } from './pages/comum/perfil/Perfil';
import { Produto } from "./pages/comum/produto/Produto";
import { Regional } from "./pages/comum/regional/Regional";
import  Negociacao  from './pages/comum/negociacao/Negociacao';
import Configuracoes from "./pages/comum/usuario/Configuracoes";
import { Vendedor } from "./pages/comum/vendedor/Vendedor";
import { Dashboard } from './pages/rre/dashboard/Dashboard';
import Inadimplencia from "./pages/rre/inadimplencia/Inadimplencia";
import Meta from "./pages/rre/meta/Meta";
import PainelGerencial from "./pages/rre/painelGerencial/PainelGerencial";
import Painel from "./pages/rre/painel/Painel";
import Pedido from "./pages/rre/pedido/Pedido";
import "./utilities/TZMFormat.js";
import TZMShortcut from "./utilities/TZMShortcut";
import TZMUserData from "./utilities/TZMUserData";
import {Equipamento} from "./pages/pcp/equipamento/Equipamento";
import {FilaProducao} from "./pages/pcp/fila-producao/FilaProducao";
import GestaoOrdemServico from "./pages/pcp/gestao-ordem-servico/GestaoOrdemServico";
import Simulador from './pages/rre/parametros-simulador/Simulador';
import Calculadora from './pages/rre/calculadora/Calculadora';
import CalculadoraRepresentante from './pages/rre/calculadora-representante/CalculadoraRepresentante';
import FichaTecnica from './pages/rre/ficha-tecnica/FichaTecnica';
import { Desconto } from './pages/comum/desconto/Desconto';
import { Familia } from './pages/comum/familia/Familia';
import { VersaoService } from './pages/servicos/VersaoService';

const ambiente = process.env.REACT_APP_AMBIENTE;
const BUILD_VERSION = preval`const moment = require('moment');module.exports = moment().format('YYYYMMDD-HHmm');`;

export const App = withRouter(class extends React.Component {

	state = {
		sidebarVisible: false,
		menus: [],
		allowed: {}
	};
	
	versaoService = new VersaoService();

	toggleSidebar = () => {
		this.setState({sidebarVisible: !this.state.sidebarVisible});
	}

	openSettings = () => {
		this.setState({sidebarVisible: false});
		ModalRealm.showDialog(<Configuracoes key={TZMUserData.getIdGenerator()} />);
	}

	runLogout = () => {
		TZMUserData.resetUsuario();
		window.location.replace("/");
	}

	showCheatSheet() {
		ModalRealm.showDialog(<CheatSheet key={TZMUserData.getIdGenerator()} />);
	}

	moveTo = (path) => {
		this.props.history.push(path);
		this.setState({sidebarVisible: false});
	}

	componentDidMount() {
		//CHECAR VERSAO				
		this.versaoService.ultimaVersao().then((v) => {							
			if (v && Number(process.env.REACT_APP_VERSAO) !== Number(v.id) ) {
				// window.location.reload();
			}
		});		
		
		ModalRealm.showDialog(<PainelVersao key={TZMUserData.getIdGenerator()} />);
		let menus = [];
		let allowed = {			
			"GERENCIAL": [],
			"PCP": [],			
			"COMERCIAL": [],
			"PROJETOS": [],
			"CADASTROS": []
		};		
		if (ambiente === "INTERNO") {
			if (TZMUserData.hasRole("____USER_M")) {
				allowed["CADASTROS"].push("____USER_M");
				menus.push(<Route key={0} path="/usuarios" component={Usuario} />);
			}
			if (TZMUserData.hasRole("____PROD_M")) {
				allowed["CADASTROS"].push("____PROD_M");
				menus.push(<Route key={1} path="/produtos" component={Produto} />);
			}
			if (TZMUserData.hasRole("____GEST_M")) {
				allowed["CADASTROS"].push("____GEST_M");
				menus.push(<Route key={2} path="/gestores" component={Gestor} />);
			}
			if (TZMUserData.hasRole("____REGN_M")) {
				allowed["CADASTROS"].push("____REGN_M");
				menus.push(<Route key={3} path="/regionais" component={Regional} />);
			}
			if (TZMUserData.hasRole("____VEND_M")) {
				allowed["CADASTROS"].push("____VEND_M");
				menus.push(<Route key={4} path="/vendedores" component={Vendedor} />);
			}
			if (TZMUserData.hasRole("____SEGM_M")) {
				allowed["CADASTROS"].push("____SEGM_M");
				menus.push(<Route key={6} path="/familias" component={Familia} />);
			}
			if (TZMUserData.hasRole("____PERF_M")) {
				allowed["CADASTROS"].push("____PERF_M");
				menus.push(<Route key={7} path="/perfis" component={Perfil} />);
			}
			if (TZMUserData.hasRole("____CLIE_M")) {
				allowed["CADASTROS"].push("____CLIE_M");
				menus.push(<Route key={8} path="/clientes" component={Cliente} />);
			}
			if (TZMUserData.hasRole("____LSTP_M")) {
				allowed["CADASTROS"].push("____LSTP_M");
				menus.push(<Route key={9} path="/listaspreco" component={ListaPreco} />);
			}
			if (TZMUserData.hasRole("____BUDG_M")) {
				allowed["CADASTROS"].push("____BUDG_M");
				menus.push(<Route key={10} path="/budgets" component={Budget} />);
			}
			if (TZMUserData.hasRole("____ALCD_M")) {
				allowed["CADASTROS"].push("____ALCD_M");
				menus.push(<Route key={11} path="/alcadas" component={Alcada} />);
			}
			if (TZMUserData.hasRole("____MAIL_M")) {
				allowed["CADASTROS"].push("____MAIL_M");
				menus.push(<Route key={12} path="/contas-email" component={ContaEmail} />);
			}
			if (TZMUserData.hasRole("PCP_EQPT_M")) {
				allowed["PCP"].push("PCP_EQPT_M");
				menus.push(<Route key={13} path="/equipamentos" component={Equipamento} />);
			}
			if (TZMUserData.hasRole("PCP_GDOS_M")) {
				allowed["PCP"].push("PCP_GDOS_M");
				menus.push(<Route key={14} path="/gestao-ordem-servico" component={GestaoOrdemServico} />);
			}
			if (TZMUserData.hasRole("____METARRE_M")) {
				allowed["CADASTROS"].push("____METARRE_M");
				menus.push(<Route key={13} path="/metas" component={Meta} />);
			}
			if (TZMUserData.hasRole("CRC_PRRE_M")) {
				allowed["GERENCIAL"].push("CRC_PRRE_M");
				menus.push(<Route key={14} path="/painel" component={Painel} />);
			}		
			if (TZMUserData.hasRole("GER_NEGO_M")) {
				allowed["GERENCIAL"].push("GER_NEGO_M");
				menus.push(<Route key={16} path="/negociacoes" component={Negociacao} />);						
			}
			if (TZMUserData.hasRole("GER_RRE_PSIMU_M")) {
				allowed["GERENCIAL"].push("GER_RRE_PSIMU_M");						
				menus.push(<Route key={21} path="/parametros-simulador" component={Simulador} />);
			}
			if (TZMUserData.hasRole("GER_RRE_SIMU_M")) {
				allowed["GERENCIAL"].push("GER_RRE_SIMU_M");						
				menus.push(<Route key={22} path="/simulador" component={Calculadora} />);																						
			}
			if (TZMUserData.hasRole("GER_RRE_DESC_M")) {
				allowed["GERENCIAL"].push("GER_RRE_DESC_M");						
				menus.push(<Route key={22} path="/descontos" component={Desconto} />);
			}
			if (TZMUserData.hasRole("PCP_FPRD_M")) {
				allowed["PCP"].push("PCP_FPRD_M");
				menus.push(<Route key={18} path="/fila-producao" component={FilaProducao} />);
			}
		}

		allowed["COMERCIAL"].push("CRC_DASH_M");
		menus.push(<Route key={0} path="/" exact component={Dashboard} />);
		if (TZMUserData.hasRole("CRC_PEDD_M")) {
			allowed["COMERCIAL"].push("CRC_PEDD_M");
			menus.push(<Route exact key={18} path="/pedidos" component={Pedido} />);
			menus.push(<Route key={19} path="/pedidos/:pedido" component={Pedido} />);
		}
		if (TZMUserData.hasRole("GER_RRE_INAD_M")) {
			allowed["COMERCIAL"].push("GER_RRE_INAD_M");
			menus.push(<Route key={20} path="/inadimplencia" component={Inadimplencia} />);					
		}
		if (TZMUserData.hasRole("CRC_RRE_SIMU_M")) {
			allowed["COMERCIAL"].push("CRC_RRE_SIMU_M");						
			menus.push(<Route key={22} path="/simulador-representante" component={CalculadoraRepresentante} />);						
		}		
		if (TZMUserData.hasRole("GER_RRE_PGER_M")) {
			allowed["GERENCIAL"].push("GER_RRE_PGER_M");
			menus.push(<Route key={15} path="/painelGerencial" component={PainelGerencial} />);						
		}
		if (TZMUserData.hasRole("____FCTC_M")) {
			allowed["CADASTROS"].push("____FCTC_M");
			menus.push(<Route key={17} path="/fichas-tecnica" component={FichaTecnica} />);
		}		
		this.setState({menus, allowed});
	}

	links = {
		"CRC_DASH_M": {priority: 1, label: "Dashboard", to: "/", icon: "home"},
		"CRC_PRRE_M": {priority: 1, label: "Painel RR", to: "/painel", icon: "th"},
		"CRC_PEDD_M": {priority: 2, label: "Pedidos", to: "/pedidos", icon: "file-alt"},
		"GER_RRE_PGER_M": {priority: 3, label: "Painel Gerencial", to: "/painelGerencial", icon: "money-check-alt"},
		"GER_RRE_INAD_M": {priority: 4, label: "Inadimplência", to: "/inadimplencia", icon: "hand-holding-usd"},
		"GER_RRE_PSIMU_M":{priority: 5, label: "Parâmetros Simulador", to: "/parametros-simulador", icon: "cog"},
		"GER_RRE_SIMU_M": {priority: 6, label: "Simulador do Gestor", to: "/simulador", icon: "calculator"},
		"GER_NEGO_M": {priority: 7, label: "Negociação", to: "/negociacoes", icon: "file-contract"},		
		"PCP_FPRD_M": {priority: 10, label: "Fila de Produção", to: "/fila-producao", icon: "bars"},
		"____PROD_M": {priority: 401, label: "Produtos", to: "/produtos", icon: "tags"},
		"PCP_EQPT_M": {priority: 401, label: "Equipamentos", to: "/equipamentos", icon: "terminal"},
		"PCP_GDOS_M": {priority: 401, label: "Gestão de O.S", to: "/gestao-ordem-servico", icon: "clipboard-list"},
		"____META_M": {priority: 400, label: "Metas", to: "/metas", icon: "chart-line"},
		"____METARRE_M": {priority: 400, label: "Metas", to: "/metas", icon: "chart-line"},
		"____MAIL_M": {priority: 401, label: "Contas de E-mail", to: "/contas-email", icon: "envelope"},
		"____GEST_M": {priority: 402, label: "Gestores", to: "/gestores", icon: "users"},
		"____REGN_M": {priority: 404, label: "Regionais", to: "/regionais", icon: "globe"},		
		"____VEND_M": {priority: 405, label: "Vendedores", to: "/vendedores", icon: "user-tag"},
		"____PERF_M": {priority: 407, label: "Perfis", to: "/perfis", icon: "th"},
		"____BUDG_M": {priority: 406, label: "Budgets", to: "/budgets", icon: "user-tag"},		
		"____ALCD_M": {priority: 408, label: "Alçadas", to: "/alcadas", icon: "percent"},		
		"____SEGM_M": {priority: 408, label: "Famílias", to: "/familias", icon: "align-justify"},
		"____USER_M": {priority: 409, label: "Usuários", to: "/usuarios", icon: "user-circle"},
		"____CLIE_M": {priority: 410, label: "Clientes", to: "/clientes", icon: "user-tie"},
		"____LSTP_M": {priority: 411, label: "Lista de Preço", to: "/listaspreco", icon: "file-invoice-dollar"},
		"____FCTC_M": {priority: 412, label: "Ficha Técnica", to: "/fichas-tecnica", icon: "list-ol"},
		"CRC_RRE_SIMU_M": {priority: 6, label: "Simulador do Representante", to: "/simulador-representante", icon: "calculator"},
		"GER_RRE_DESC_M": {priority: 7, label: "Descontos", to: "/descontos", icon: "percentage"}
	};

	linkTemplate = (index, linkData) => {
		return {
			index: linkData.priority,
			value: (
				<div key={TZMUserData.getIdGenerator()}> 
					<Link key={linkData.to} onClick={this.toggleSidebar} to={linkData.to}>
						<div key={index} className="ui-sidebar-link">
							<TZMIcon className="sidebar-link" name={linkData.icon} />
							<span>{linkData.label}</span>
						</div>
					</Link>
				</div>
			)
		};
	}
	
	renderGroupLinks = (k) => {
		let i = 0;
		if (this.state.allowed[k].length) {
			return (
				<div key={TZMUserData.getIdGenerator()}>
					<div className="sidebar-link-header">{k}</div>
					{this.state.allowed[k].map(b => this.linkTemplate(i++, this.links[b])).sort((a, b) => a.priority - b.priority).map((i) => i.value)}
				</div>
			);
		} else {
			return null;
		}
	}

	render() {		
		return (
			<div>
				<div className="ui-trademark"><span style={{marginRight: "4px"}}>BeOnTag {`1.0 ` + BUILD_VERSION}</span><TZMIcon name="copyright" /><span style={{marginLeft: "4px"}}>2024 TZM</span></div>
				<TZMMenubar>
					<TZMAppMenu active={this.state.sidebarVisible ? this.state.sidebarVisible  : undefined} onClick={this.toggleSidebar} />
					<div className="ui-tzm-logo" />
					<TZMLogo />
				</TZMMenubar>
				<TZMSidebar visible={this.state.sidebarVisible} onHide={() => this.setState({ sidebarVisible: false })}>
					<TZMSidebarCard>		
						<TZMSidebarCardFooter>
							<div className="ui-sidebar-footer-strip">
							<span>{TZMUserData.getPrincipal().nome}</span>
								<TZMButton className="sidebar-button ui-button-danger"  icon="fas fa-sign-out-alt" title="Sair do Sistema" onClick={this.runLogout} />
								<TZMButton className="sidebar-button ui-button-primary" icon="fas fa-question-circle" title="Tabela de Atalhos" onClick={this.showCheatSheet} />
								<TZMButton className="sidebar-button ui-button-success" icon="fas fa-cogs" title="Configurações" onClick={this.openSettings} />
							</div>
						</TZMSidebarCardFooter>
					</TZMSidebarCard>
					{Object.keys(this.state.allowed).map(this.renderGroupLinks)}
				</TZMSidebar>
				<main style={{margin: "10px"}}><Switch>{this.state.menus}</Switch></main>
				<TZMShortcut active master onCtrlO={this.openSettings} onF1={this.toggleSidebar} onCtrlF1={this.showCheatSheet} onCtrlQ={this.runLogout} />
				<ModalRealm />
				{window.location.pathname !== "/painel" ? <div className="ui-trademark-placeholder" /> : null}
			</div>
		);
	}

});
