import React from "react";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTable from "../../../components/table/TZMTable";
import { Column } from "primereact/components/column/Column";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import LazyImage from "../../../utilities/LazyImage";
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';

const basePath = process.env.REACT_APP_API_HOST || "";
export class VisualizarFichaTecnica extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            visible: true,
            fichaTecnica: props.fichaTecnica
        };

    }

    onHide = () => this.setState({ visible: false });

    fecharPainel = () => {
		this.setState({ visible: false });
    } 

    conteudoFicha = (rowData) => {        
        switch (rowData.atributo.tipoConteudo) {
            case 'A':                
                return (<div> {rowData.conteudoTexto}</div>)
            case 'N':                
                return (<div> {rowData.conteudoNumero}</div>)
            default:
                return (<div></div>)
        }
    }
    
    columnsAtributos = [		
        <Column sortable style={{ textAlign: "center", width: "4em" }} field="atributoId" key="atributoId"  header="Atributo" />,
        <Column  style={{ textAlign: "left", width: "15em" }} field="atributo.descricao" key="atributo.descricao"  header="Descrição" />,
        <Column  style={{ textAlign: "center", width: "5em" }} field="atributo.tipoConteudo" key="atributo.tipoConteudo"  header="Tipo Conteúdo" />,        
        <Column  style={{ textAlign: "left", width: "13em" }} key="conteudoNumero"  header="Conteúdo" body={this.conteudoFicha}/>,
        <Column  style={{ textAlign: "center", width: "4em" }} field="atributo.unidade" key="atributo.unidade"  header="Unidade" />
    ]

	render() {        
        return (
            <TZMDialog style={{ width: "1000px" }} visible={this.state.visible} modal header="Ficha Técnica" onHide={this.onHide} {...this.props}>
            <TZMPanel>
                <TZMForm>
    				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>	    			
	    				<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Atributos da Ficha Técnica</span>}>
                            <TZMTable value={this.state.fichaTecnica.atributos}  paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}>                
                                {this.columnsAtributos}
                            </TZMTable>
                        </TZMTabPanel>
	    				<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Imagem Ficha Técnica</span>}>  
                            <ScrollPanel style={{width: '100%', height: '600px'}} className="custom">
                                <LazyImage key={this.state.fichaTecnica.imagem}  src={`${basePath}/public/omega/imagens/${this.state.fichaTecnica.imagem}`} />                            
                            </ScrollPanel>
                        </TZMTabPanel>
                    </TZMTabView>
                </TZMForm>
            </TZMPanel>
            <TZMPanelFooter>
                <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fecharPainel} />
            </TZMPanelFooter>
        </TZMDialog>   
        );
    }
}