import { httpDelete, httpGet, httpPost, httpGetAsync } from "../../utilities/TZMRequest";

export class ProdutoService {

    completar(produto) {
        return httpGet(`/produtos/${produto.id}`);
    }

    listar(params) {
        return httpGet("/produtos", params);
    }

    listarSimples(params) {
        return httpGet("/produtos/listar-simples", params);
    }

    salvar(produto) {
        return httpPost("/produtos", produto);
    }

    excluir(produto) {
        return httpDelete("/produtos", produto.id);
    }

    autoCompletar = async (params) => {
        return await httpGetAsync("/produtos/auto-completar", params, false);
    }

    atualizar  = async () => {
        return httpGetAsync("/produtos/atualizar");
    }
}
