import React from "react";

export class ProdutoUtils { 

	static produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line">
                <div className="ui-tzm-suggestion-chip">
                    {value.pk ? value.pk.produtoId : value.produtoId}
                </div>
                {value.descricao}
            </div>
		);
	}

}