let currencyFormatterWithoutCents = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 0 });
let currencyFormatter = new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" });
let currencyFormatterUSD = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
let percentFormatter = new Intl.NumberFormat("pt-BR", { style: "percent" });
let decimalFormatter = new Intl.NumberFormat("pt-BR");
let fixexFormatter = new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 1 });
let dateFormatter = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" });
let timestampFormatter = new Intl.DateTimeFormat("pt-BR", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" });

export function toCurrency(value) {
	return currencyFormatter.format(value);
}

export function toCurrencyWithoutCents(value) {
	return currencyFormatterWithoutCents.format(value);
}

export function toCurrencyUSD(value) {
	return currencyFormatterUSD.format(value);
}

export function toDecimal(value) {
	return decimalFormatter.format(value);
}

export function toDecimalFixed(value, dp) {
	return fixexFormatter.format(value.toFixed(dp));
}


export function toDate(value) {
	return dateFormatter.format(Date.parse(value));
}

export function toTimestamp(value) {
	return timestampFormatter.format(value);
}

export function toPercent(value) {
	return percentFormatter.format(value);
}