
import React from "react";
import moment from "moment"
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import TZMTextField from "../../../components/common/TZMTextField";
import { ListaPrecoVersaoService } from "../../servicos/ListaPrecoVersaoService";
import { EditarListaPreco } from "./EditarListaPreco";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import { ClienteService } from "../../servicos/ClienteService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { Column } from "primereact/components/column/Column";
import { ProdutoUtils } from "../produto/ProdutoUtils";
import { ClienteUtils } from "../cliente/ClienteUtils";

const title = "Listas de Preço";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class ListaPreco extends React.Component {

	constructor(props) {
		super(props);
		moment.locale('pt-BR');		
		this.state = {	
			listasPrecoVersao:[],
			desativados: false,
			params: {
				codigo: null,
				versionamento: null,
				produto: null,
				cliente: null,
				descricao: ''
			}
		};
		this.listaPrecoVersaoService = new ListaPrecoVersaoService();	
		this.clienteService = new ClienteService();
		this.vwProdutoService = new VwProdutoService();
	}

	listarListasPreco = () => {
		let params = Object.assign({}, this.state.params);		
		if (params.cliente) {
			params.cliente = params.cliente.clienteId;
		}
		if (params.produto) {
			params.produto = params.produto.pk.produtoId;
		}
		this.listaPrecoVersaoService.listarSimples(params).then((listasPrecoVersao) => this.setState({listasPrecoVersao}));
	}

	listarProdutos = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((produtos) => {
			this.setState({ produtos });
		});
	}

	listarClientes = async (event) => {
        await this.clienteService.autoComplete({cliente: event.query, desativados: false, sort: 'razao'}).then((clientes) => this.setState({clientes}));
    }
	
	editarListaPreco = (event) => {
		this.setState({selectedListaPreco: event.data});
		if (event.data != null) {
			if (event.data.id != null) {
				this.listaPrecoVersaoService.completar(event.data).then((listaPrecoVersao) => {
					ModalRealm.showDialog(<EditarListaPreco key={TZMUserData.getIdGenerator()} listaPrecoVersao={listaPrecoVersao}  onModalClose={this.listarListasPreco}  />);
				});
			} else {
				ModalRealm.showDialog(<EditarListaPreco key={TZMUserData.getIdGenerator()} listaPrecoVersao={event.data} onModalClose={this.listarListasPreco}  />);
			}
		}
    }
    
	criarListaPreco = () => {
		this.setState({selectedListaPreco: null});
		this.editarListaPreco({data: {codigo: null}});
	}

	selectAutoComplete = (event) => {
		let params = this.state.params;
		params[event.name] = event.value;
		this.setState({ params });
	}

    handleUpdate = (key, event) => {
		let params = this.state.params;
		params[key] = event.target.value;
		this.setState({params});
    }
	
	handleClear = (event) => {
		let params = this.state.params;
		params[event.name] = null;
		this.setState({params});
	}

	handleUpdateParams = (event, field, index) => {
		let params = this.state.params;
		params[field][index] = event.target.value;
		this.setState({ params });
	}
	
	clonarListaPreco = (listaPrecoVersao) => {
		this.listaPrecoVersaoService.completar(listaPrecoVersao).then((result) => this.cloneProperties(result,'CLONAR'));
	}

	publicarListaPreco = (listaPrecoVersao) => {
		this.listaPrecoVersaoService.completar(listaPrecoVersao).then((result) => this.cloneProperties(result,'PUBLICAR'));
	}

	cloneProperties = (listaPrecoVersao,tipo) => {									
		listaPrecoVersao.registro = null;	
		listaPrecoVersao.versao = 1;	
		if (tipo === 'CLONAR')  {			
			listaPrecoVersao.listaPreco = null;
		} else if (tipo === 'PUBLICAR') {			
			listaPrecoVersao.versionamento = null;
		}
		listaPrecoVersao.status = 'PENDENTE';
		if (listaPrecoVersao.id != null) listaPrecoVersao.id = null;		
		if (listaPrecoVersao.itens != null) {
			listaPrecoVersao.itens = listaPrecoVersao.itens.filter(item => item.produto.posicao === 'ATIVO');
			listaPrecoVersao.itens.forEach(item => {
				item.versao = 0;
				item.registro = null;								
				item.produtoId = item.produto.pk.produtoId;
				item.produtoDescricao = item.produto.descricao;
				if (item.id != null) item.id = null;
			});
		}
		
		ModalRealm.showDialog(<EditarListaPreco key={TZMUserData.getIdGenerator()} listaPrecoVersao={listaPrecoVersao}  onModalClose={this.listarListasPreco}  />);	
	}

	columns = [		
		<Column header="Descrição" key="descricao" field="listaPreco.descricao" style={{textAlign: "left",width: "10em", }} />,
		<Column header="Código" key="codigo" field="listaPreco.codigo" style={{textAlign: "left",width: "10em", }} />,
		<Column header="Versão" key="versao" field="versionamento" style={{textAlign: "left",width: "10em", }} />,
		<Column header="Status" key="status" field="status" style={{textAlign: "left",width: "10em", }} />,
		<Column header="Ações" key="acoes" style={{width: "6em", textAlign: "center"}} body={(listaPrecoVersao) => { 
			let botoes = [];			
			if (TZMUserData.hasRole("____LSTP_E")) {
				botoes.push(<span key={0} title="Editar" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarListaPreco({data: listaPrecoVersao})} />);
				botoes.push(<span key={1} title="Copiar" style={{ margin: "0 3px 0 2px" }} className="fa fa-copy ui-tzm-icon" onClick={() => this.clonarListaPreco(listaPrecoVersao)} />);
				if (listaPrecoVersao.status !== 'PENDENTE') {
					botoes.push(<span key={2} title="Nova Versão" style={{ margin: "0 3px 0 2px" }} className="fa fa-file-upload ui-tzm-icon" onClick={() => this.publicarListaPreco(listaPrecoVersao)} />);				
				}				
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;	
		}} />
	];

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">	
									<div className="ui-g-1">									
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("codigo", event)} label="Código" value={this.state.params.codigo} />
									</div>									
									<div className="ui-g-1">									
										<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("versionamento", event)} label="Versão" value={this.state.params.versionamento} />
									</div>
									<div className="ui-g-2">
										<TZMTextField  name="descricao" value={this.state.params.descricao} label="Descrição" onChange={(event) => this.handleUpdate("descricao", event)} />		
									</div>
									<div className="ui-g-4">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ClienteUtils.templateTipo} onSelect={this.selectAutoComplete} onChange={this.selectAutoComplete}
											suggestions={this.state.clientes} completeMethod={this.listarClientes} name="cliente" field="descricaoAmigavel" 
												label="Cliente"  placeholder="Razão social, fantasia ou CNPJ"  value={this.state.params.cliente} />
									</div>
									<div className="ui-g-4">
										<TZMAutoComplete  onClear={this.handleClear} itemTemplate={ProdutoUtils.produtoTemplate} onSelect={this.selectAutoComplete} onChange={this.selectAutoComplete}
												suggestions={this.state.produtos}  completeMethod={this.listarProdutos} name="produto" field="descricao" 
												label="Produto" placeholder="Descrição ou SKU"  value={this.state.params.produto} />
									</div>									
								</div>
							</TZMForm>	
							<TZMPanelFooter>
								<TZMButton className="ui-button-secondary" title={this.state.params.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.toggleHidden}  icon={this.state.params.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____LSTP_C") ? <TZMButton className="ui-button-success ui-tzm-button" icon="fas fa-plus" label="Nova Lista Preço" onClick={this.criarListaPreco} /> : null}
								<TZMButton className="ui-button-primary ui-tzm-button"  icon="fas fa-search" label="Procurar" onClick={this.listarListasPreco} />
							</TZMPanelFooter>							
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.listasPrecoVersao} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}
	
}
