import { Fetch } from "../../utilities/Fetch";
import { httpGet, httpGetAsync } from "../../utilities/TZMRequest";
export class VwProdutoService {

	autoCompletar(query) {
		return Fetch.GetJSON(`/vw-produtos?produto=${query}&page=0&size=40&sort=descricao`);
	}

	listarSimples(query) {
		return Fetch.GetJSON(`/vw-produtos/listar-simples?produto=${query}&page=0&size=40&sort=descricao`);
	}

	produtoPreco = async (params) =>{
		return await httpGetAsync("/vw-produtos/produto-preco", params, true);
	}

	listar(params) {
		return httpGet("/vw-produtos", params);
	}

	listarMateriaPrima = async (query) =>{
		return await httpGetAsync("/vw-produtos/listar-materia-prima", {produto: query}, true);
	}

}
 