import { Column } from "primereact/components/column/Column";
import { Card } from "primereact/components/card/Card";
import React, { Component } from "react";
import EditarPedido from "../pedido/EditarPedido";
import {JustificativaDesconto} from "../pedido/JustificativaDesconto";
import {SimuladorPopup} from "./SimuladorPopup";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import TZMUserData from "../../../utilities/TZMUserData";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import { toCurrency } from "../../../components/common/TZMFormatter";
import moment from "moment";
import {DescontoService} from "../../servicos/DescontoService";
import {PedidoService} from "../../servicos/PedidoService";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMForm from "../../../components/common/TZMForm";
import TZMButton from "../../../components/common/TZMButton";
import TZMConfirm from "../../../components/dialog/TZMConfirm";

export class DashboardDesconto extends Component {

    state = {
		descontosSolicitados: [],
		minhasSolicitacoes: [],
        simulador: {},
        loading: true
	};

    componentDidMount(){
        this.listarDescontos();
    }

    descontoService = new DescontoService();
	pedidoService = new PedidoService();

    columnStatus(rowData) {
		let value, color, titulo;
		switch (rowData.pedidoStatus) {
			case "ALCADA_DESCONTO":
				value = "tag";
				color = "#4CAF50";
				titulo = "Desconto Pendente";
				break;
			case "DESCONTO_REPROVADO":
				value = "times";
				color = "#FF9800";
				titulo = "Desconto Reprovado";
				break;
			default:
				break;
		}
		return (<span key={1} className={`fas fa-${value}`} title={titulo} style={{textAlign: "center", fontSize: "18px", color}} />);
	}

	cardFooter = (desconto) => {
		return (
			<div className="ui-g">
				<div className="ui-g-9">
					Desconto solicitado por {desconto.solicitante} em {moment(desconto.registro).format("DD/MM/YYYY HH:mm")}.
				</div>
				<div className="ui-g-3" style={{textAlign: "right"}}>
					<TZMButton label="Visualizar" icon="fa fa-desktop" onClick={() => this.visualizarPedido(desconto.pedidoId)}/>
				</div>
			</div>
		);

	}

	columnsDescontos = [
		<Column key={0} style={{width: "*"}} header="Produto" field="produtoDescricao" />,		
		<Column key={1} style={{width: "15em"}} header="Matéria Prima" field="materiaPrima" />,
		<Column key={2} style={{width: "8em", textAlign: "center"}} header="Família" field="familia" />,
		<Column key={3} style={{width: "5em", textAlign: "center"}} header="Unidade" field="unidade" />,
		<Column key={4} style={{width: "9em", textAlign: "right"}} header="Valor Unitário" body={(item) => toCurrency(item.valorUnitario)}/>,
		<Column key={5} style={{width: "9em", textAlign: "center"}} header="Quantidade" field="quantidade" />,
		<Column key={6} style={{width: "9em", textAlign: "right"}} header="Valor Total" body={(item) => toCurrency(item.valorTotalItem)}/>,
		<Column key={7} style={{width: "15em", textAlign: "right"}} header="Desconto" body={(pid) => `${pid.percentual}% (${(pid.valorDesconto).toCurrency()})`} />,
		<Column key={8} style={{width: "25em"}} header="Justificativa" body={(pid) => pid.itens.filter(i => i.usuarioNome).sort((a, b) => a.nivel - b.nivel).map(i => <div key={TZMUserData.getIdGenerator()}>- {i.justificativa}</div>)} />,
		<Column style={{ width: "6.5em" }} key="acoes" header="Ações" body={(di) => {
			let botoes = [];
			if (di.solicitacao) {
				let item = di.itens.filter(i => i.status === "SOLICITADO");				
				if (item && item[0].limite < di.percentual) {
					botoes.push(<span key="encaminhar" title="Encaminhar" className="fa fa-arrow-right ui-tzm-icon" onClick={() => this.modificarDesconto(di, "E")} />)
				} else {
					botoes.push(<span key="aprovar" title="Aprovar" className="fa fa-check ui-tzm-icon" onClick={() => this.modificarDesconto(di, "A")} />);
				}
				botoes.push(<span key="reprovar" title="Reprovar" className="fa fa-times ui-tzm-icon" onClick={() => this.modificarDesconto(di, "R")} />);
			}
			if (TZMUserData.hasRole("GER_RRE_SIMU_M") && di.logSimulacao){
				botoes.push(<span key="log" title="Dados da Simulação" className="fa fa-calculator ui-tzm-icon" onClick={() => this.openSimulacao(di.logSimulacao)} />)
			}
			return <div style={{ textAlign: "center" }}>{botoes}</div>;
		}} />
	];

	visualizarPedido = (pedidoID) => {
		this.pedidoService.buscar(pedidoID).then((pedido) => {
			ModalRealm.showDialog(<EditarPedido key={TZMUserData.getIdGenerator()} pedido={pedido} />);
		});
	}

	listarDescontos = async () => {
        await this.descontoService.solicitados(TZMUserData.getUsuario()).then((descontosSolicitados) => this.setState({descontosSolicitados}));
        await this.descontoService.minhasSolicitacoes(TZMUserData.getUsuario()).then((minhasSolicitacoes) => this.setState({minhasSolicitacoes}));
        this.setState({loading: false});
	}

	reprovarDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "REPROVADO").then(this.listarDescontos)
	}

	aprovarDesconto = (desconto, usuario) => {
		this.descontoService.desenvolver(desconto, usuario, "APROVADO").then(this.listarDescontos)
	}

	modificarDesconto = (descontoItem, acao) => {
		const usuario = TZMUserData.getUsuario();
		switch (acao) {
			case "R":
				ModalRealm.showDialog(<JustificativaDesconto  acao={"REPROVADO"} itens={descontoItem.itens} key={TZMUserData.getIdGenerator()} onModalClose={(justificativa) => {
					this.reprovarDesconto({...descontoItem, justificativa} , usuario)}} />);	
				break;
			case "E":
				ModalRealm.showDialog(<JustificativaDesconto  keu={TZMUserData.getIdGenerator()} itens={descontoItem.itens} onModalClose={(justificativa) => {
					this.descontoService.desenvolver({...descontoItem, justificativa}, usuario, "APROVADO").then(this.listarDescontos);
				}} />);
				break;
			default:
				ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} question="Tem certeza de que deseja aprovar está solicitação de desconto?" onYes={() => this.aprovarDesconto(descontoItem, usuario)} />);
				break;
		}
	}

	openSimulacao = (simulacao) => {
		ModalRealm.showDialog(<SimuladorPopup key={TZMUserData.getIdGenerator()} simulador={this.state.simulador} simulacao={simulacao} />);
    }

    solitacoesRealizadas = () => {
		if (this.state.descontosSolicitados.length > 0) {
			return (<TZMTabPanel key="descontosSolicitados" header="Solicitações de Desconto">{this.descontosCards(this.state.descontosSolicitados)}</TZMTabPanel>);
		}
		return [];
	}

	minhasSolicitacoes = () => {
		if (this.state.minhasSolicitacoes.length > 0) {
			return (<TZMTabPanel key="meusDescontos" header="Minhas Solicitações de Desconto">{this.descontosCards(this.state.minhasSolicitacoes)}</TZMTabPanel>);
		}
		return [];
	}

    descontosCards = (descontos) => {
        var elements=[];
        for(var i=0;i<descontos.length;i++){
			let desconto = descontos[i];
			let header = <div key={desconto.pedidoId} style={{fontSize: "18px"}}>{this.columnStatus(desconto)} Pedido {desconto.pedidoId}</div>
            let footer = this.cardFooter(desconto);
            elements.push(this.cards(desconto, header, footer));
        }
        return (<div className="ui-g">{elements}</div>);
    }

    cards(desconto, header, footer) {
        return <div key={desconto.pedidoId} className="ui-g-12">
            <Card  title={header} footer={footer}>
                <div className="ui-g " >
                    <div className="ui-g-6">
                        Cliente: {desconto.razaoSocial}
                    </div>
                    <div className="ui-g-6">
                        Representante: {desconto.representanteNome}
                    </div>
                </div>
                <TZMTable children={this.columnsDescontos} value={desconto.descontos} />
            </Card>
        </div>;
	}

	emptyDesconto = () =>{
		if (this.state.descontosSolicitados.length > 0 || this.state.minhasSolicitacoes.length > 0 ) {
			return [];
		} else {
			return <TZMTabPanel>Nenhum registro encontrado.</TZMTabPanel>
		}
	}

	render(){
        if (this.state.loading) {
            return <TZMPanel> <i className="fa fa-spin fa-spinner" /> Carregando Descontos</TZMPanel>;
        } else {
            return (
                <TZMPanel>
                    <TZMForm>
                        <TZMTabView>
							{this.emptyDesconto()}
                            {this.solitacoesRealizadas()}
                            {this.minhasSolicitacoes()}
                        </TZMTabView>
                    </TZMForm>
                </TZMPanel>
            );
        }
    }
}
