import { Fetch } from "../../utilities/Fetch";

export class PainelRREService {

	pedidoOperador() {
		return Fetch.GetJSON("/painel/operador");
	}
	
	pedidoVendedor() {
		return Fetch.GetJSON("/painel/vendedor");
	}

	monitorTotais() {
		return Fetch.GetJSON("/painel/totais");
	}

	descontos() {
		return Fetch.GetJSON("/painel/descontos");
	}

}
