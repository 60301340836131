import classNames from "classnames";
import moment from "moment";
import { Calendar } from "primereact/components/calendar/Calendar";
import React from "react";
import "./Common.css";

export default class TZMCalendar extends React.Component {

	onChange = (event) => {
		if (event.originalEvent.target.value != null) {
			if (this.props.showTime) {
				event.originalEvent.target.value = event.originalEvent.target.value.toDateTime();
			} else {
				event.originalEvent.target.value = event.originalEvent.target.value.toDate();
			}
		}
		if (this.props.onChange) {
			if (event.value) {
				this.props.onChange({name: this.props.name, target: {value: this.props.showTime ? moment(event.value).format("YYYY-MM-DDTHH:mm:ss") : moment(event.value).format("YYYY-MM-DD")}});
			} else {
				this.props.onChange({name: this.props.name, target: {value: null}});
			}
		}
	}

	pt_br = {
		firstDayOfWeek: 0,
    	dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
    	dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    	dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    	monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    	monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
	}

	removeKeys = [8, 46];

	onKeyPress = (event) => {
		if (!this.fullSelected() && !this.removeKeys.includes(event.keyCode) && this.props.value && this.props.value.length === (this.props.showTime ? 19 : 10)) event.preventDefault();
	}

	fullSelected = () => {
		let calendar = this.calendar || {};
		return this.props.value ? calendar.selectionStart === 0 && calendar.selectionEnd === this.props.value.length : true;
	}

	refCalendar = (el) => this.calendar = el ? el.inputfield : null;

	render() {
		let classes = classNames({
			"ui-tzm-calendar": true,
			"ui-input-required": this.props.required && (!this.props.value || this.props.value.trim().length === 0)
		});
		let value = /T[0-9]{2}:[0-9]{2}:[0-9]{2}/g.test(this.props.value) ? this.props.value : this.props.value + "T00:00:00";
		value = typeof this.props.value === "string" ? new Date(Date.parse(value)) : this.props.value == null || isNaN(this.props.value) ? null : new Date(this.props.value);
		if (isNaN(value)) value = null;
		return (
			<div className="ui-textfield-group">
				<label className="ui-input-label">{this.props.label}</label><br />
				<Calendar ref={this.refCalendar} appendTo={document.getElementById("root")} inputClassName={classes} locale={this.pt_br} monthNavigator={true} yearNavigator={true} yearRange="1976:2030" 
				dateFormat="dd/mm/yy" onKeyPress={this.onKeyPress} style={{width: "100%", display: "inline-block"}} {...this.props} value={value} onChange={this.onChange} />
			</div>
		);
	}

}
