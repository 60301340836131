import React from "react";
import { Column } from "primereact/components/column/Column";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMTable from "../../../components/table/TZMTable";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMButton from "../../../components/common/TZMButton";
import TZMShortcut from "../../../utilities/TZMShortcut";

export class OrdemProducao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            ordensProducao: props.ordensProducao
        };        
    }

    fechar = () => {
        this.setState({ visible: false });
    }

    columnStatus(rowData) {         
        return <div key="icone">
        <span style={{ textAlign: "center" ,padding: "5px", fontSize: "16px", color: "#5c5d5e" }} 
            className={`fas fa-${rowData.icone}`}  />
        </div>;
    }

    columns = [        
        <Column key={0} header="Pedido" style={{ width: "10em", textAlign: "center" }} field="pedidoId" />,        
        <Column key={1} header="Item" style={{ width: "3em", textAlign: "center" }} field="itemId" />,        
        <Column key={2} header="SKU" style={{ width: "13em", textAlign: "center" }} field="produtoId" />,
        <Column key={3} header="Número O.P. " style={{ width: "10em", textAlign: "center" }} field="ordemProducao" />,
        <Column key={4} header="Status" style={{ width: "8em", textAlign: "center" }} field="statusDescricao" />,
        <Column key={5} header="Observação" style={{ width: "*", textAlign: "justify" }} field="observacao" />
    ];

    render() {
        return (
            <TZMDialog style={{ width: "1000px" }} visible={this.state.visible} modal header="Ordens de Produção" {...this.props}>
                <div className="ui-g">
                    <TZMTable value={this.state.ordensProducao}>{this.columns}</TZMTable>
                </div>
                <TZMPanelFooter>
                    <TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.fechar} />
                </TZMPanelFooter>
                <TZMShortcut active={this.state.visible} onEscape={this.fechar}
                />
            </TZMDialog>
        );
    }

}