import {httpDelete, httpGet, httpGetAsync, httpPost} from "../../../utilities/TZMRequest";

export class OrdemServicoService {

	buscar(ordemServico) {
		return httpGet(`/ordens-servico/${ordemServico.id}`);
	}

	listar(params) {
		return httpGet(`/ordens-servico?${Object.keys(params).map(k => `${k}=${params[k]}`).join("&")}`);
	}

	salvar(ordemServico) {
		return httpPost("/ordens-servico", ordemServico);
	}

	excluir(ordemServico) {
		return httpDelete("/ordens-servico", ordemServico.id);
	}

	listarSimples = async (params) => {
        return await httpGetAsync("/ordens-servico/listar-simples", params, true);
    }

	completarOrdemServicoItem = async (params) => {
        return await httpGetAsync("/ordens-servico/completar-ordem-servico-item", params, true);
    }

}