import React from "react";
import {Panel} from "primereact/components/panel/Panel";
import {httpDelete, httpGet, httpPost} from "../../../utilities/TZMRequest";
import TZMTable from "../../../components/table/TZMTable";
import {Websocket} from "../../../components/websocket/Websocket";
import {RelatorioService} from "../../servicos/RelatorioService";
import TZMUserData from "../../../utilities/TZMUserData";
import {Column} from "primereact/components/column/Column";
import TZMMainContent from "../../../components/main/TZMMainContent";
import {Growl} from "primereact/components/growl/Growl";
import {OrdemProducao} from "./OrdemProducao";
import {ModalRealm} from "../../../components/modal/ModalRealm";
import {toDate} from "../../../components/common/TZMFormatter";
import {byFilaProducaoEntregaOrId} from "../../../utilities/SortUtils";

const title = "Fila de Produção";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class FilaProducao extends React.Component {

	state = {
		paineis: 15,
		pedidos: [],
		expandedPedidos: [],
		logsProducao: [],		
		logsExpedicao: [],
		logsColeta: [],
		printing: 0
	};

	relatorioService = new RelatorioService();

	static X_FILA_PRODUCAO = "fila-producao";

	componentDidMount() {
		const paineis = Number(window.localStorage.getItem(FilaProducao.X_FILA_PRODUCAO) || "15");
		this.setState({paineis}, () => {
			httpGet("/vwpedidos/web").then(pedidos => {
				httpGet("/logs-producao").then(resultados => {
					const logsProducao = resultados.filter(lp => lp.status === "PRODUZINDO").sort(byFilaProducaoEntregaOrId);
					const logsExpedicao = resultados.filter(lp => lp.status === "CONCLUIDO").sort(byFilaProducaoEntregaOrId);
					const logsColeta = resultados.filter(lp => lp.status === "DESPACHADO").sort(byFilaProducaoEntregaOrId);
					this.setState({pedidos, logsProducao, logsExpedicao, logsColeta});
				});
			});
		});
	}

	dateFormat(rowData, column) {
		let campo = rowData[column.field];		
		if (campo) {
			return toDate(campo);
		}
		return null;
	}

	filterDate = (value, filter) => {
		if (value) {
			const _value = toDate(value)
			return _value.includes(filter)
		}
		return null;
	}

	printAcoesLogs = (lp, i) => (
		<div style={{textAlign: "center"}}>
			{/* {i === 3 ? */}
				{/* <button className="icon-button" disabled={this.state.printing === lp.pedidoId} title="Imprimir guia de produção" onClick={() => this.imprimirEspelho(lp)}> */}
					<i disabled={this.state.printing === lp.pedidoId} title="Imprimir guia de produção" style={{cursor: "pointer", marginRight: "4px"}} 
					onClick={() => this.imprimirEspelho(lp)}
					className={`fa fa-${this.state.printing === lp.pedidoId ? "circle-notch fa-spin" : "file-pdf"}`}/>
				{/* </button>  */}
			{/* : null} */}
			{i !== 0 ? <i style={{cursor: "pointer", margin: "0 2px"}} title="Retornar para lista de pedidos" className="fa fa-receipt" onClick={() => this.excluirLogProducao(lp)}/> : null}
			{i !== 1 ? <i style={{cursor: "pointer", margin: "0 2px"}} title="Enviar para fila de produção" className="fa fa-pallet" onClick={() => (i === 0 ? this.criarLogProducao : this.trocarStatus)(lp, "PRODUZINDO")}/> : null}
			{i !== 2 ? <i style={{cursor: "pointer", margin: "0 2px"}} title="Enviar para lista de expedição" className="fa fa-truck" onClick={() => (i === 0 ? this.criarLogProducao : this.trocarStatus)(lp, "CONCLUIDO")}/> : null}
			{i !== 3 ? <i style={{cursor: "pointer", margin: "0 2px"}} title="Enviar para lista de coleta" className="fa fa-boxes" onClick={() => (i === 0 ? this.criarLogProducao : this.trocarStatus)(lp, "DESPACHADO")}/> : null}
			{i !== 4 ? <i style={{cursor: "pointer", margin: "0 2px"}} title="Marcar log de produção como coletado" className="fa fa-luggage-cart" onClick={() => (i === 0 ? this.criarLogProducao : this.trocarStatus)(lp, "COLETADO")}/> : null}
		</div>
	);

	columnsLogsProducao = [
		<Column key={0} style={{width: "2.5em", textAlign: "center"}} header="&nbsp;" expander/>,
		<Column filterMatchMode="contains" filter key={2} style={{width: "7em"}} field="pedidoId" header="Número" body={this.columnStatus}/>,
		<Column filterMatchMode="contains" filter key={3} header="Cliente" field="filaProducao.cliente" body={(lp) => lp.filaProducao ? lp.filaProducao.cliente : ""}/>,
		<Column header="P/ Entrega"
		        style={{width: "6.5", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={(value, filter) => this.filterDate(value, filter)}
		        filter
		        sortable
		        field="filaProducao.entrega"
		        key={4}				
		        body={(lp) => (lp.filaProducao && lp.filaProducao.entrega) ? toDate(lp.filaProducao.entrega) : ""}
		/>,
		<Column style={{width: "6.5em", textAlign: "center"}} 
				filterMatchMode="custom"
				filterFunction={(value, filter) => this.filterDate(value, filter)}
				filter 
				key={5} 
				field="filaProducao.minimaFaturamento" 
				sortable
				header="Dt Mínima" 
				body={(lp) => (lp.filaProducao  && lp.filaProducao.minimaFaturamento) ? toDate(lp.filaProducao.minimaFaturamento) : ""} />,
		<Column key={6} style={{width: "5em"}} header="Ações" body={lp => this.printAcoesLogs(lp, 1)}/>
	];
	
	columnsLogsExpedicao = [
		<Column key={0} style={{width: "2.5em", textAlign: "center"}} header="&nbsp;" expander/>,
		<Column filterMatchMode="contains" filter key={2} style={{width: "7em"}} field="pedidoId" header="Número" body={this.columnStatus}/>,
		<Column filterMatchMode="contains" filter key={3} header="Cliente" field="filaProducao.cliente" body={(lp) => lp.filaProducao ? lp.filaProducao.cliente : ""}/>,
		<Column style={{width: "6.5em", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={(value, filter) => this.filterDate(value, filter)}
		        field="filaProducao.entrega"
		        filter
		        key={4}
				sortable
		        header="P/ Entrega"
		        body={(lp) => (lp.filaProducao && lp.filaProducao.entrega) ? toDate(lp.filaProducao.entrega) : ""}/>,
		<Column style={{width: "6.5em", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={(value, filter) => this.filterDate(value, filter)}
		        field="filaProducao.minimaFaturamento"
		        filter
		        key={5}
				sortable
		        header="Dt Mínima"
		        body={(lp) => (lp.filaProducao && lp.filaProducao.minimaFaturamento) ? toDate(lp.filaProducao.minimaFaturamento) : ""}/>,
		<Column key={6} style={{width: "5em"}} header="Ações" body={lp => this.printAcoesLogs(lp, 2)}/>
	];

	columnsLogsColeta = [
		<Column key={0} style={{width: "2.5em", textAlign: "center"}} header="&nbsp;" expander/>,
		<Column filterMatchMode="contains" filter key={2} style={{width: "7em"}} field="pedidoId" header="Número" body={this.columnStatus}/>,
		<Column filterMatchMode="contains" filter key={3} header="Cliente" field="filaProducao.cliente" body={(lp) => lp.filaProducao ? lp.filaProducao.cliente : ""}/>,
		<Column style={{width: "6.5em", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={(value, filter) => this.filterDate(value, filter)}
		        field="filaProducao.entrega"
		        filter
		        key={4}
				sortable
		        header="P/ Entrega"
		        body={(lp) => (lp.filaProducao && lp.filaProducao.entrega) ? toDate(lp.filaProducao.entrega) : ""}/>,
		<Column style={{width: "6.5em", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={(value, filter) => this.filterDate(value, filter)}
		        field="filaProducao.minimaFaturamento"
		        filter
				sortable
		        key={5}				
		        header="Dt Mínima"
		        body={(lp) => (lp.filaProducao && lp.filaProducao.minimaFaturamento) ? toDate(lp.filaProducao.minimaFaturamento) : ""}/>,				
		<Column key={6} style={{width: "5em"}} header="Ações" body={lp => this.printAcoesLogs(lp, 3)}/>
	];

	columnsPedidos = [
		<Column key={0} style={{width: "2.5em", textAlign: "center"}} header="&nbsp;" expander/>,
		<Column filterMatchMode="contains" filter sortable field="pedidoId" key={1} style={{width: "7em"}} header="Número" body={this.columnStatus}/>,
		<Column filterMatchMode="contains" filter sortable key={2} header="Cliente" field="cliente"/>,
		<Column header="P/ Entrega"
		        style={{width: "6.5em", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={this.filterDate}
		        filter
		        sortable
		        field="entrega"
		        body={this.dateFormat}
		        key={3}/>,
		<Column header="Dt Mínima"
		        style={{width: "6.5em", textAlign: "center"}}
		        filterMatchMode="custom"
		        filterFunction={this.filterDate}
		        filter
		        sortable
		        field="minimaFaturamento"
		        body={this.dateFormat}
		        key={4}/>,							
		<Column key={5} style={{width: "5em"}} header="Ações" body={fp => this.printAcoesLogs(fp, 0)} />
	];

	excluirLogProducao = logProducao => {
		httpDelete("/logs-producao", logProducao.id).then(() => {
			let {logsProducao, logsExpedicao, logsColeta} = this.state;
			httpGet("/vwpedidos/web").then(pedidos => {
				logsProducao = logsProducao.filter(lp => lp.id !== logProducao.id);
				logsExpedicao = logsExpedicao.filter(lp => lp.id !== logProducao.id);
				logsColeta = logsColeta.filter(lp => lp.id !== logProducao.id);
				this.setState({pedidos, logsExpedicao, logsProducao, logsColeta});
			});
		});
	}

	ordemProducao = rowData => {
		ModalRealm.showDialog(<OrdemProducao key={TZMUserData.getIdGenerator()} ordensProducao={rowData.ordensProducao} />);
	}

	templateItens = filaProducao => {
		return (
			<TZMTable value={filaProducao.itens}>
				<Column key={0} style={{width: "10em", textAlign: "center"}} header="SKU" field="produtoId" />
				<Column key={1} style={{width: "*"}} header="Produto" field="descricao" />	
				<Column key={2} style={{width: "13em"}} header="Quantidades" body={(i) => (
					<div className="pseudo-table right">
						<div>Saldo Pedido:</div><div title="Quantidade a produzir do pedido">{i.quantidade}</div>
						<div>No estoque 1:</div><div title="Saldo disponível do produto em estoque 1">{i.estoque1}</div>
						<div>No estoque 2:</div><div title="Saldo disponível do produto em estoque 2">{i.estoque2}</div>
					</div>
			)} />
			<Column key={3} style={{width: "4em", textAlign: "center"}} header="Status" field="status" body={this.columnStatusItem}/>
			{
				filaProducao.status ? (
					<Column key={4} style={{width: "3em"}} header="O.P." body={(i) => {
						let botoes = [];
						if (i.ordensProducao.length > 0) {
							botoes.push(<span key={0} title="Ordens de Produção" className="fas fa-clipboard-list ui-tzm-icon" onClick={() => this.ordemProducao(i)}/>);
						}
						return <div style={{ textAlign: "center" }}>{botoes}</div>;
					}}/>
				) : <Column key={4} style={{width: "0em"}}/>
			}
			</TZMTable>
		);
	}

	trocarStatus = async (logProducao, status) => await httpPost(`/logs-producao`, {...logProducao, status});

	criarLogProducao = async (filaProducao, status = "PRODUZINDO") => {
		const logProducao = {
			pedidoId: filaProducao.pedidoId,
			empresaFilialId: filaProducao.empresaFilialId,
			status,
			responsavel: TZMUserData.getUsuario().login		
		};
		await httpPost(`/logs-producao`, logProducao);
	}

	imprimirEspelho = filaProducao => {
		this.setState({printing: filaProducao.pedidoId});
		this.relatorioService.espelho(filaProducao.pedidoId).then(() => this.setState({printing: 0}));
	}

	handleLogProducaoWebsocket = logProducao => {
		let {pedidos, logsProducao, logsExpedicao, logsColeta} = this.state;
		let oldLogProducao = {status: "PENDENTE"};
		oldLogProducao = {...oldLogProducao, ...[...pedidos, ...logsProducao, ...logsExpedicao, ...logsColeta].filter(lp => lp.pedidoId === logProducao.pedidoId)[0]};
		pedidos = pedidos.filter(p => p.pedidoId !== logProducao.pedidoId);
		logsProducao = logsProducao.filter(lp => lp.pedidoId !== logProducao.pedidoId);
		logsExpedicao = logsExpedicao.filter(lp => lp.pedidoId !== logProducao.pedidoId);
		logsColeta = logsColeta.filter(lp => lp.pedidoId !== logProducao.pedidoId);
		switch (logProducao.status) {
			case "PENDENTE":
				pedidos.push(logProducao);
				break;
			case "PRODUZINDO":
				logsProducao.push(logProducao);
				break;
			case "CONCLUIDO":
				logsExpedicao.push(logProducao);
				break;
			case "DESPACHADO":
				logsColeta.push(logProducao);
				break;
			case "COLETADO":
				break;
			default:
				break;
		}
		logsProducao.sort(byFilaProducaoEntregaOrId);
		logsExpedicao.sort(byFilaProducaoEntregaOrId);
		logsColeta.sort(byFilaProducaoEntregaOrId);
		this.setState({pedidos, logsProducao, logsExpedicao, logsColeta});
		this.growl.show({
			closable: true, severity: "info", sticky: false, summary: "Pedido", life: 15000,
			detail: (
				<div>
					Pedido {logProducao.pedidoId} passou de:<br/>
					{this.icones[oldLogProducao.status]} {oldLogProducao.status} <i className="fa fa-arrow-right"/> {this.icones[logProducao.status]} {logProducao.status}!
				</div>
			)
		});
	}

	toggleRowPedidos = (event, index = 0) => {
		const expandedPedidos = event.data;
		if (expandedPedidos.length === index) {
			this.setState({expandedPedidos});
		} else {
			if (!expandedPedidos[index].itens) {
				const pedidoId = expandedPedidos[index].pedidoId || expandedPedidos[index].filaProducao?.pedidoId;
				httpGet(`/logs-producao/itens?id=${expandedPedidos[index].pedidoId}&numero=${pedidoId}`).then((itens) => {
					expandedPedidos[index].itens = itens;
					this.toggleRowPedidos(event, ++index);
				});
			} else {
				this.toggleRowPedidos(event, ++index);
			}
		}
	}

	icones = {
		"PENDENTE": <i className="fa fa-receipt"/>,
		"PRODUZINDO": <i className="fa fa-pallet"/>,
		"CONCLUIDO": <i className="fa fa-truck"/>,
		"DESPACHADO": <i className="fa fa-boxes"/>
	};

	togglePainel = index => {
		let {paineis} = this.state;
		if (paineis & (1 << index)) {
			paineis &= ~(1 << index);
		} else {
			paineis |=  (1 << index);
		}
		window.localStorage.setItem(FilaProducao.X_FILA_PRODUCAO, String(paineis));
		this.setState({paineis});
	}

	printMinimized = () => {
		const components = [];
		for (let i = 0; i < 4; ++i) {
			if (!(this.state.paineis & (1 << i))) {
				components.push(<div key={TZMUserData.getIdGenerator()} onClick={() => this.togglePainel(i)} className="fila-producao-minimized">{this.panelLabels[i]}</div>);
			}
		}
		return components;
	}

	panelLabels = [
		"Pedidos",
		"Fila de Produção",
		"Expedição",
		"Picking"
	];

	columnStatusItem(rowData) {		
		let value, color, _title;
		switch (rowData.status) {
			case "BLOQUEADO":
				value = "lock";
				color = "#F44336";
				_title = "Bloqueado";
				break;			
			case "CARTEIRA":
				value = "wallet";
				color = "#607D8B";
				_title = "Em Carteira";
				break;
			case "FATURADO":
				value = "check";
				color = "#4CAF50";
				_title = "Faturado";
				break;		
			default:
				break;
		}
		return (
			<div className='ui-g' >
				<div className='ui-g-12' style={{textAlign: "center"}}>
					<span key={1} className={`fas fa-${value}`} title={_title} style={{color}}/>
				</div>
			</div>
		);
	}

	columnStatus(rowData) {
		let pedido = rowData.filaProducao ? rowData.filaProducao : rowData;
		let value, color, _title;
		switch (pedido.status) {
			case "BLOQUEADO":
				value = "lock";
				color = "#F44336";
				_title = "Bloqueado";
				break;			
			case "CARTEIRA":
				value = "wallet";
				color = "#607D8B";
				_title = "Em Carteira";
				break;
			case "FATURADO":
				value = "check";
				color = "#4CAF50";
				_title = "Faturado";
				break;		
			default:
				break;
		}
		return (
			<div className='ui-g' >
				<div className='ui-g-12' style={{textAlign: "center"}}>
					<span key={1} className={`fas fa-${value}`} title={_title} style={{color}}/>&nbsp;
					{pedido.pedidoId}
				</div>
			</div>
		);
	}

	render() {		
		return (
			<TZMMainContent>
				<div className="fila-producao-container">
					<div className={`fila-producao ${this.state.paineis & (1 << 0) ? "" : "disabled"}`}>
						<Panel header={<div><i className="fa fa-receipt" /> {this.panelLabels[0]} <i className="fa fa-minus fila-producao-toggler" onClick={() => this.togglePainel(0)} /></div>}>
							<TZMTable value={this.state.pedidos}
							          children={this.columnsPedidos}
							          paginator
							          rows={30}
							          pageLinkSize={15}
							          onRowToggle={this.toggleRowPedidos}
							          rowExpansionTemplate={this.templateItens}
							          expandedRows={this.state.expandedPedidos}/>
						</Panel>
					</div>
					<div className={`fila-producao ${this.state.paineis & (1 << 1) ? "" : "disabled"}`}>
						<Panel header={<div><i className="fa fa-pallet" /> {this.panelLabels[1]} <i className="fa fa-minus fila-producao-toggler" onClick={() => this.togglePainel(1)} /></div>}>
							<TZMTable value={this.state.logsProducao}
							          children={this.columnsLogsProducao}
							          paginator
							          rows={30}
							          pageLinkSize={15}
							          onRowToggle={this.toggleRowPedidos}
							          rowExpansionTemplate={this.templateItens}
							          expandedRows={this.state.expandedPedidos}/>
						</Panel>
					</div>
					<div className={`fila-producao ${this.state.paineis & (1 << 2) ? "" : "disabled"}`}>
						<Panel header={<div><i className="fa fa-truck" /> {this.panelLabels[2]} <i className="fa fa-minus fila-producao-toggler" onClick={() => this.togglePainel(2)} /></div>}>
							<TZMTable value={this.state.logsExpedicao}
							          children={this.columnsLogsExpedicao}
							          paginator
							          rows={30}
							          pageLinkSize={15}
							          onRowToggle={this.toggleRowPedidos}
							          rowExpansionTemplate={this.templateItens}
							          expandedRows={this.state.expandedPedidos}/>
						</Panel>
					</div>
					<div className={`fila-producao ${this.state.paineis & (1 << 3) ? "" : "disabled"}`}>
						<Panel header={<div><i className="fa fa-boxes" /> {this.panelLabels[3]} <i className="fa fa-minus fila-producao-toggler" onClick={() => this.togglePainel(3)} /></div>}>
							<TZMTable value={this.state.logsColeta}
							          children={this.columnsLogsColeta}
							          paginator
							          rows={30}
							          pageLinkSize={15}
						          		onRowToggle={this.toggleRowPedidos}
							          rowExpansionTemplate={this.templateItens}
							          expandedRows={this.state.expandedPedidos}/>

						</Panel>
					</div>
					<Websocket path="/logs-producao" onMessage={this.handleLogProducaoWebsocket}/>
					<div className="fila-producao-minimized-container">
						{this.printMinimized()}
					</div>
				</div>
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<div className="content-section implementation p-fluid">
                    <Growl ref={(el) => this.growl = el} baseZIndex={10001002}/>
                </div>
			</TZMMainContent>
		);
	}

}
