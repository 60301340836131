import { Column } from "primereact/components/column/Column";
import { ScrollPanel } from "primereact/components/scrollpanel/ScrollPanel";
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import { toDecimal } from "../../../components/common/TZMFormatter";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import LazyImage from "../../../utilities/LazyImage";
import TZMUserData from "../../../utilities/TZMUserData";
import { FichaTecnicaService } from "../servicos/FichaTecnicaService";
import { VisualizarFichaTecnicaProduto } from "./VisualizarFichaTecnicaProduto";


const basePath = process.env.REACT_APP_API_HOST || "";
export default class VisualizarFichaTecnica extends React.Component {

	constructor(props) {
		super();
		this.state = {
            visible: true,
            fichaTecnica: props.fichaTecnica
		};
		this.fichaTecnicaService = new FichaTecnicaService(); 
	}

    onHide = () => this.setState({ visible: false });

    conteudoFicha = (rowData) => {        
        switch (rowData.atributo.tipoConteudo) {
            case 'A':                
                return (<div> {rowData.conteudoTexto}</div>)
            case 'N':                
                return (<div> {rowData.conteudoNumero}</div>)
            default:
                return (<div></div>)
        }
    }
    
    columnsAtributos = [		
        <Column  sortable style={{ textAlign: "center", width: "4em" }} field="atributoId" key="atributoId"  header="Atributo" />,
        <Column  style={{ textAlign: "left", width: "15em" }} field="atributo.descricao" key="atributo.descricao"  header="Descrição" />,
        <Column  style={{ textAlign: "center", width: "5em" }} field="atributo.tipoConteudo" key="atributo.tipoConteudo"  header="Tipo Conteúdo" />,        
        <Column  style={{ textAlign: "left", width: "13em" }} key="conteudoNumero"  header="Conteúdo" body={this.conteudoFicha}/>,
        <Column  style={{ textAlign: "center", width: "4em" }} field="atributo.unidade" key="atributo.unidade"  header="Unidade" />
	]
	
	columnsProdutos = [		
        <Column  field="fichaTecnicaId" key="fichaTecnicaId" style={{ textAlign: "center", width: "4.5em" }} header="Ficha Técnica" body={() => {
            return <div style={{ textAlign: "right" }}>{this.state.fichaTecnica.fichaTecnicaId}</div>;
        }} />,
        <Column  field="versao" key="versao" style={{ textAlign: "center", width: "4.5em" }} header="Versão" body={() => {
            return <div style={{ textAlign: "right" }}>{this.state.fichaTecnica.versao}</div>;
        }} />,

        <Column  field="pk.produtoId" key="produtoId" style={{ textAlign: "center", width: "10em" }} header="Produto" />,
        <Column  field="descricao" key="descricao" style={{width:"*", textAlign: "right" }} header="Descrição" />,
        <Column key="saldoDisponivel"  field="saldoDisponivel"  style={{ width: "9.5em" }}header="Saldo Disponível"  body={(rowData) => {
            return <div style={{ textAlign: "right" }}>{toDecimal(rowData["saldoDisponivel"])}</div>;
        }} />,        
        <Column field="unidade" key="unidade" style={{ textAlign: "center", width: "4.5em" }} header="Unidade" />,        
        <Column field="nomeConhecido" key="nomeConhecido" style={{ textAlign: "center", width: "13em" }} header="Tradução" />,
        <Column field="posicao" key="posicao" style={{ textAlign: "center", width: "8em" }} header="Posição" />,        
        <Column style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
            let botoes = [];            
            botoes.push(<span key={0} title="Visualizar Ficha Técnica Produto"  className="fa fa-desktop ui-tzm-icon" onClick={() => this.visualizarFichaTecnicaProduto(rowData)} />);
            return <div style={{ textAlign: "center" }}>{botoes}</div>;            
        }}/>
	]
	
	visualizarFichaTecnicaProduto = (produto) => {
        let params = [];        
        params.fichaTecnicaId = this.state.fichaTecnica.fichaTecnicaId;
        params.versao = this.state.fichaTecnica.versao;
        params.produtoId = produto.pk.produtoId;        
        this.fichaTecnicaService.completarFichaTecnicaProdutoAtributo(params).then((response) => {
            let fichaTecnica = this.state.fichaTecnica;
            fichaTecnica.produtoAtributos = response;
            ModalRealm.showDialog(<VisualizarFichaTecnicaProduto key={TZMUserData.getIdGenerator()} fichaTecnica={fichaTecnica} produto={produto} />);
        });
	}

	render() {        
        return (
            <TZMDialog style={{ width: "1200px" }} visible={this.state.visible} modal header="Ficha Técnica" onHide={this.onHide} {...this.props}>
				<TZMPanel>
					<TZMForm>
						<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>	    			
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Produtos da Ficha Técnica</span>}>
								<TZMTable value={this.state.fichaTecnica.produtos}  paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}>                
									{this.columnsProdutos}
								</TZMTable>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Atributos da Ficha Técnica</span>}>
								<TZMTable value={this.state.fichaTecnica.atributos}  paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}>                
									{this.columnsAtributos}
								</TZMTable>
							</TZMTabPanel>
							<TZMTabPanel header={<span><span className="ui-tab-number" children="3" />Imagem Ficha Técnica</span>}>  
								<ScrollPanel style={{width: '100%', height: '600px'}} className="custom">
									<LazyImage key={this.state.fichaTecnica.imagem}  src={`${basePath}/public/omega/imagens/${this.state.fichaTecnica.imagem}`} />                            
								</ScrollPanel>
							</TZMTabPanel>
						</TZMTabView>
					</TZMForm>
				</TZMPanel>
				<TZMPanelFooter>
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-close" onClick={this.onHide} />
				</TZMPanelFooter>
        	</TZMDialog>   
        );
    }
}