import { httpGet } from "../../../utilities/TZMRequest";

export class FichaTecnicaService {

	listar(params) {
		return httpGet("/vw-fichas-tecnicas/listar", params);
	}

	listarFichaTecnicaProdutos(params) {		
		return httpGet("/vw-fichas-tecnicas/listar-ficha-tecnica-produtos", params);
	}

	materiais(params) {
		return httpGet("/vw-fichas-tecnicas/materiais", params);
	}

	diametrosTubete(params) {
		return httpGet("/vw-fichas-tecnicas/diametros-tubete", params);
	}

	sentidosRebobinagem(params) {
		return httpGet("/vw-fichas-tecnicas/sentidos-rebobinagem", params);
	}

	completar(params) {
		return httpGet("/vw-fichas-tecnicas/completar", params);
	}

	completarFichaTecnicaProdutos(params) {
		return httpGet("/vw-fichas-tecnicas/completar-ficha-tecnica-produtos", params);
	}

	completarFichaTecnicaProdutoAtributo(params) {
		return httpGet("/vw-fichas-tecnicas/completar-ficha-tecnica-produto-atributo", params);
	}

	
}