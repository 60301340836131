import { saveAs } from 'file-saver';
import React from 'react';
import TZMPopup from '../../components/dialog/TZMPopup';
import TZMProgressbar from '../../components/menubar/TZMProgressbar';
import { ModalRealm } from '../../components/modal/ModalRealm';
import TZMUserData from '../../utilities/TZMUserData';
import { Util } from '../../utilities/Util';

const reportPath = process.env.REACT_APP_REPORT_URL;

export class RelatorioService {    

    postReport = async (path, nome, dummy, message) => {
        let header = window.localStorage.getItem("X-Authorization-Key");
        await fetch(`${reportPath}${path}`, { method: "get", headers: { "Authorization": header, "Content-Type": "application/json" }, body: JSON.stringify(this.state) })
        .then((response) => {
            switch (response.status) {
                case 200:
                    let extensao;
                    switch (response.headers.get("Content-Type")) {
                        case "application/pdf":
                            extensao = ".pdf";
                            break;
                        case "application/octet-stream":
                            extensao = ".zip";
                            break;
                        default:
                    }
                    response.blob().then((blob) => {
                        const file = new Blob([blob], {type: 'application/pdf', });
                        const data = window.URL.createObjectURL(file);
                        saveAs(data, nome + extensao);
                    });
                    return response;
                case 404:
                    ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={message} />);
                    return Promise.reject();
                default:
                    return Promise.reject();
            }
        }).catch((erro) => {
            console.log(erro);
        });
    }

    postReportNF = async (path, nome, dummy, message, loading = true) => {
        let header = window.localStorage.getItem("X-Authorization-Key");
        TZMProgressbar.setActive(loading);
        await fetch(`${reportPath}${path}`, { method: "get", headers: { "Authorization": header, "Content-Type": "application/json" }, body: JSON.stringify(this.state) })
        .then((response) => {
            switch (response.status) {
                case 200:
                    let extensao;
                    switch (response.headers.get("Content-Type")) {
                        case "application/pdf":
                            extensao = ".pdf";
                            break;
                        case "application/octet-stream":
                            extensao = ".zip";
                            break;
                        default:
                    }
                    response.blob().then((blob) => {
                        const file = new Blob([blob], {type: 'application/pdf', });
                        const data = window.URL.createObjectURL(file);
                        saveAs(data, nome + extensao);
                    });
                    TZMProgressbar.setActive(false);
                    return response;
                case 404:
                    ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={message} />);
                    return Promise.reject();
                default:
                    return Promise.reject();
            }
            
        }).catch((erro) => {
            TZMProgressbar.setActive(false);
            console.log(erro);
        });
    }
    

    espelhoPedido = async (pk, nome) => {        
        return await this.postReport(`/vwpedidos/espelhoPedido/${JSON.stringify(pk)}`, nome, '.pdf');
    }

    espelho = async (numero) => {
        return await this.postReport(`/vwpedidos/espelho-pedido/${numero}`, numero, '.pdf');
    }
    
	relatorioInadimplencia = async (params) => {
        let append = "";
        if (params != null) {
            let values = [];
            Object.keys(params).forEach((key) => {
                if (params[key] != null) {
                    if (Array.isArray(params[key])) {
                        let temp = params[key].filter(element => element);
                        if (temp.length > 0) {
                            values.push(`${key}=${encodeURIComponent(params[key].join(","))}`);
                        }
                    } else if (typeof params[key] === "object") {
                        values.push(`${key}=${encodeURIComponent(JSON.stringify(params[key]))}`);
                    } else {
                        if (params[key].length > 0) {
                            values.push(`${key}=${encodeURIComponent(Util.trim(params[key]))}`);
                        }
                    }
                }
            });
            if (values.length > 0) {
                append = "?" + values.join("&");
            }
        }

        return await this.postReport(`/inadimplencias/impressaoRRE`  + append, 'RelInadimplentes', '.pdf');
    }	    

    relatorioCSV = async (params) => {
        let append = "";
        if (params != null) {
            let values = [];
            Object.keys(params).forEach((key) => {
                if (params[key] != null) {
                    if (Array.isArray(params[key])) {
                        let temp = params[key].filter(element => element);
                        if (temp.length > 0) {
                            values.push(`${key}=${encodeURIComponent(params[key].join(","))}`);
                        }
                    } else if (typeof params[key] === "object") {
                        values.push(`${key}=${encodeURIComponent(JSON.stringify(params[key]))}`);
                    } else {
                        if (params[key].length > 0) {
                            values.push(`${key}=${encodeURIComponent(Util.trim(params[key]))}`);
                        }
                    }
                }
            });
            if (values.length > 0) {
                append = "?" + values.join("&");
            }
        }
        return await this.postReport(`/inadimplencias/csv`  + append, 'CSVInadimplentes', '.csv');
    }	 
    
    notaFiscal = async (notaFiscalId,pedidoId) => {
        return await this.postReportNF(`/vw-notas-fiscais/pdf?notaFiscalId=${notaFiscalId}&pedidoId=${pedidoId}` , `${notaFiscalId}`, null , [`Nenhum PDF encontrado para a nota fiscal ${notaFiscalId}.`]);
    }

    notasFiscais = async (pedidoId) => {
        return await this.postReportNF(`/vw-notas-fiscais/pdfs?pedidoId=${pedidoId}` , `NF-PEDIDO-${pedidoId}`, null , [`Nenhuma nota fiscal encontrada para o pedido ${pedidoId}.`]);
    }

	relatorioSimulacao = async (simulacao) => {
        return await this.postReportBody("/simuladores/relatorio",simulacao, 'Orçamento');
    }
    
    postReportBody = async (path, body,nome,message) => {
        console.log("body",body);
        let header = window.localStorage.getItem("X-Authorization-Key");
        await fetch(`${reportPath}${path}`, { method: "post", headers: { "Authorization": header, "Content-Type": "application/json" }, body: JSON.stringify(body) })
        .then((response) => {
            switch (response.status) {
                case 200:
                    let extensao;
                    switch (response.headers.get("Content-Type")) {
                        case "application/pdf":
                            extensao = ".pdf";
                            break;
                        case "application/octet-stream":
                            extensao = ".zip";
                            break;
                        default:
                    }
                    response.blob().then((blob) => {
                        const file = new Blob([blob], {type: 'application/pdf', });
                        const data = window.URL.createObjectURL(file);
                        saveAs(data, nome + extensao);
                    });
                    return response;
                case 404:
                    ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()} header="Advertência" messages={message} />);
                    return Promise.reject();
                default:
                    return Promise.reject();
            }
        }).catch((erro) => {
            console.log(erro);
        });
    }
    
}
