import * as React from "react";

const websocketPath = process.env.REACT_APP_WSCKT_URL;

export class Websocket extends React.Component {

	state = {
		connected: false
	};

	componentDidMount() {
		this.connect();
	}

	connect = () => {
		this.socket = new WebSocket(`${this.props.root || websocketPath}/websocket${this.props.path}/websocket${this.props.params ? "?" + Object.keys(this.props.params).map(k => `${k}=${this.props.params[k]}`).join("&") : ""}`);
		this.socket.onmessage = this.onMessageReceived;
		this.socket.onopen = () => {
			this.setState({connected: true});
			if (this.props.onLoad) {
				this.props.onLoad(this.socket);
			}
		}
		this.socket.onerror = () => this.socket.close();
		this.socket.onclose = () => {
			this.setState({connected: false});
			setTimeout(() => {
				this.connect();
			}, 1000);
		};
	}

	onMessageReceived = (message) => {
		this.props.onMessage(this.props.noParse ? message.data : JSON.parse(message.data));
	}

	componentWillUnmount() {
		this.socket.close();
	}

	render() {
		return this.state.connected ? null : <div className="websocket-failed"><span /></div>;
	}

}
