import { Button } from "primereact/components/button/Button";
import React from "react";
import TZMAutoComplete from "../../../components/common/TZMAutoComplete";
import TZMButton from "../../../components/common/TZMButton";
import TZMIcon from "../../../components/common/TZMIcon";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMTabPanel from "../../../components/panel/TZMTabPanel";
import TZMTabView from "../../../components/panel/TZMTabView";
import TZMTable from "../../../components/table/TZMTable";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { Validator } from "../../../utilities/TZMValidator";
import { ListaPrecoVersaoService } from "../../servicos/ListaPrecoVersaoService";
import { VwProdutoService } from "../../servicos/VwProdutoService";
import { ConsultarProduto } from "./ConsultarProduto";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { Column } from "primereact/components/column/Column";
import { AlterarPreco } from "./AlterarPreco";
import "./ListaPreco.css";
import { UsuarioService } from "../../servicos/UsuarioService";

export class EditarListaPreco extends React.Component {

	constructor(props) {
        super(props);        
		this.state = {
            listaPrecoVersao: this.completarNulos(this.props.listaPrecoVersao),
            params: {
                produto: { pk: {}, descricao: "" }
            },
            alterado: false,
			visible: true
		};
        this.listaPrecoVersaoService = new ListaPrecoVersaoService();
		this.vwProdutoService = new VwProdutoService();
		this.usuarioService = new UsuarioService();
    }

    completarNulos(listaPrecoVersao) {

		if (!listaPrecoVersao.itens) {
			listaPrecoVersao.itens = [];
		}
		if (!listaPrecoVersao.listaPreco) {
			listaPrecoVersao.listaPreco = {id: null, descricao: ""};
			listaPrecoVersao.versionamento = 1;
		} 

		if (!listaPrecoVersao.status) {
			listaPrecoVersao.status = 'PENDENTE';
		}
		return listaPrecoVersao;
	}

	onHide = () => this.setState({visible: false});
 
	salvar = () => {
		if (this.validar()) {
			let listaPrecoVersao = Object.assign({}, this.state.listaPrecoVersao);
			this.listaPrecoVersaoService.salvar(listaPrecoVersao).then((listaPrecoVersao) => {
				if (this.props.onModalClose) this.props.onModalClose(listaPrecoVersao);
				this.onHide();
			});
		}
	}

	publicar = async () => {
		if (this.validar()) {
			let listaPrecoVersao = Object.assign({}, this.state.listaPrecoVersao);
			listaPrecoVersao.status = 'ATIVO';
			listaPrecoVersao.publicar = true;
			listaPrecoVersao.encerradoPor = await this.usuarioService.completarAsync(TZMUserData.getUsuario());
			this.listaPrecoVersaoService.salvar(listaPrecoVersao).then((listaPrecoVersao) => {
				if (this.props.onModalClose) this.props.onModalClose(listaPrecoVersao);
				this.onHide();
			});
		}
	}

	validar = () => {
		let messages = [];
		let listaPrecoVersao = this.state.listaPrecoVersao;
		if (!listaPrecoVersao.listaPreco.descricao || !listaPrecoVersao.listaPreco.descricao.length) {
			messages.push("A descrição é obrigatória");
		}

		if (listaPrecoVersao.itens == null || listaPrecoVersao.itens.length === 0) {
			messages.push("Nenhum produto adicionado.");
		} else {
			listaPrecoVersao.itens.forEach((item) => {				
				if (!item.preco || item.preco === 0) {
					messages.push(`O preço do item ${item.produto.descricao} não pode ser vazio ou igual a 0(zero).`);
				}
			});
		}

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup key={TZMUserData.getIdGenerator()}  header="Advertência" messages={messages} />);
			return false;
		}

		return true;
	}
    
    handleUpdate = (key, event) => {
		let listaPrecoVersao = this.state.listaPrecoVersao;
		listaPrecoVersao[key] = event.target.value;
		this.setState({ listaPrecoVersao, alterado: true });
	}

	handleUpdateListaPreco = (event) => {
		let listaPrecoVersao = this.state.listaPrecoVersao;
		listaPrecoVersao.listaPreco[event.name] = event.value;
		this.setState({listaPrecoVersao, alterado: true});
	}

	produtoTemplate = (value) => {
		return (
			<div className="ui-tzm-suggestion-line"><div className="ui-tzm-suggestion-chip">{value.pk.produtoId}</div>{value.descricao}</div>
		);
    }
    
    blurOnEnter = (event) => {
		if (event.keyCode === 9 || event.keyCode === 13) document.activeElement.blur();
	}
    
    listarProdutos = (event) => {
		this.vwProdutoService.autoCompletar(event.query).then((produtos) => {
			this.setState({ produtos });
		});
	}
	
	consultarProduto = () => {
		ModalRealm.showDialog(<ConsultarProduto key={TZMUserData.getIdGenerator()} listaPrecoVersao={this.state.listaPrecoVersao} onSave={(produtos,params) => {
			let listaPrecoVersao = this.state.listaPrecoVersao;
			let precoPadro = !params.preco || params.preco === 0 ? 0 : params.preco;
			let fci = !params.preco ? 0 : params.fci;
			produtos.forEach(produto => {
				listaPrecoVersao.itens.unshift({produto, preco: precoPadro,fci: fci, produtoId: produto.pk.produtoId, produtoDescricao: produto.descricao });				
			});						
			this.setState({ listaPrecoVersao });
		}}  />);
	}

	alterarPreco = () => {
		ModalRealm.showDialog(<AlterarPreco key={TZMUserData.getIdGenerator()} listaPrecoVersao={this.state.listaPrecoVersao} onSave={(listaPrecoVersao) => {
			this.setState({ listaPrecoVersao });
		}}  />);
	}

    editorProduto = (rowData, props) => {
		return (
			<div className="tzm-ui-tablelike">
                <TZMAutoComplete inputClassName="editorProduto" field="descricao" itemTemplate={this.produtoTemplate} onKeyDown={this.blurOnEnter}
                    value={this.state.listaPrecoVersao.itens[props.rowIndex].produto} onChange={(event) => {
					let listaPrecoVersao = this.state.listaPrecoVersao;
					listaPrecoVersao.itens[props.rowIndex].produto = event.value;
					this.setState({ listaPrecoVersao });
				}} suggestions={this.state.produtos} completeMethod={this.listarProdutos} onSelect={(event) => {
					if (event.value && event.value.pk) {						
						this.handleUpdateItem("produto", props.rowIndex, event.value);
					}
				}} />
			</div>
		);
	}

	
	bodyProduto = (rowData) => {		
		return (rowData.produto ? <div className="tzm-ui-tablelike">{rowData.produto.descricao}</div> : null);
    } 

    handleUpdateItem = (key, index, value) => {
		let listaPrecoVersao = this.state.listaPrecoVersao;
		listaPrecoVersao.itens[index][key] = value;
		this.setState({ listaPrecoVersao, alterado: true });
    }
    
	editorPreco = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
				<TZMTextField  min={0} type="number" required value={this.state.listaPrecoVersao.itens[props.rowIndex].preco.toString()} 
					onChange={(event) => this.handleUpdateItem("preco", props.rowIndex, event.target.value)} />
			</div>
		);
	}    

	editorFci = (rowData, props) => {		
		return (			
			<div style={{ textAlign: "right"}}>
				<TZMTextField  min={0} type="number" required value={this.state.listaPrecoVersao.itens[props.rowIndex].fci.toString()} 
					onChange={(event) => this.handleUpdateItem("fci", props.rowIndex, event.target.value)} />
			</div>
		);
	}    

    paginacaoItens = (event) => {this.setState({ firstItem: event.first });}
    
    insertItem = () => {
		switch (this.state.selectedTab) {
			case 1:
				let listaPrecoVersao = this.state.listaPrecoVersao;
				if (!listaPrecoVersao.itens) {
					listaPrecoVersao.itens = [];
				}
				listaPrecoVersao.itens.push({ produto: { pk: {}, descricao: " ",  }, preco: 0 });
				let firstItem = listaPrecoVersao.itens.length % 10 === 1 ? listaPrecoVersao.itens.length - 1 : this.state.firstItem;
				this.setState({ listaPrecoVersao, alterado: true, firstItem });
				if (firstItem === listaPrecoVersao.itens.length - 1) {
					setTimeout(() => {
						document.getElementsByClassName("editorProduto")[0].focus();
					}, 200);
				}
				break;
			default:
		}
	}

	deleteItem = (event, rowData) => {
		event.preventDefault();
		event.stopPropagation();
		if (Validator.isEntidade(rowData.produto)) {
			ModalRealm.showDialog(<TZMConfirm key={TZMUserData.getIdGenerator()} onYes={() => {
				let listaPrecoVersao = this.state.listaPrecoVersao;
				listaPrecoVersao.itens.splice(listaPrecoVersao.itens.findIndex((item) => item === rowData), 1);
				let firstItem = listaPrecoVersao.itens.length % 10 === 0 ? listaPrecoVersao.itens.length >= 10 ? listaPrecoVersao.itens.length - 10 : 0 : this.state.firstItem;
				this.setState({ listaPrecoVersao, alterado: true, firstItem });
			}} question="Tem certeza de que deseja remover este item?" />);
		} else {
			let listaPrecoVersao = this.state.listaPrecoVersao;
			listaPrecoVersao.itens.splice(listaPrecoVersao.itens.findIndex((item) => item === rowData), 1);
			let firstItem = listaPrecoVersao.itens.length % 10 === 0 ? listaPrecoVersao.itens.length >= 10 ? listaPrecoVersao.itens.length - 10 : 0 : this.state.firstItem;
			this.setState({ listaPrecoVersao, alterado: true, firstItem });
		}
	}

	colorir = (rowData) => {		
		return {'inativo' :  Validator.isEntidade(rowData.produto) && rowData.produto.posicao  === 'INATIVO'};
	}
    
    render() {
		return (
			<TZMDialog style={{width: "1200px"}} visible={this.state.visible} modal header="Lista de Preço" onHide={this.onHide} {...this.props}>
				<TZMTabView onTabChange={(event) => this.setState({ selectedTab: event.index })} activeIndex={this.state.selectedTab}>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="1" />Dados Principais</span>}>
						<div className="ui-g">
							<div className="ui-g-4">
								<TZMTextField  name="descricao" value={this.state.listaPrecoVersao.listaPreco.descricao} label="Descrição" onChange={this.handleUpdateListaPreco}/>
							</div>							
							<div className="ui-g-2">
								<TZMTextField  disabled value={this.state.listaPrecoVersao.listaPreco.codigo} label="Código" />
							</div>
							<div className="ui-g-2">
								<TZMTextField  disabled value={this.state.listaPrecoVersao.versionamento} label="Versão" />
							</div>
							<div className="ui-g-2">
								<TZMTextField  disabled value={this.state.listaPrecoVersao.status} label="Status" />
							</div>
						</div>
					</TZMTabPanel>
					<TZMTabPanel header={<span><span className="ui-tab-number" children="2" />Produtos</span>}>
						<div className="ui-g">
							
							<div className="ui-g-12">
								<TZMTable selection={this.state.selectedItemPedido} onPage={this.paginacaoItens} first={this.state.firstItem} value={this.state.listaPrecoVersao.itens}
											rowClassName={this.colorir} 
											paginator rows={15} rowsPerPageOptions={[15, 30, 45]} pageLinkSize={15}
											paginatorRight={ 
												<div style={{ textAlign: "right" }}>
													{this.state.listaPrecoVersao.status !== 'ENCERRADO' ?													
													<Button icon="fas fa-dollar-sign" title="Alterar Preço em Lote" className="tzm-paginator-button" onClick={() =>{this.alterarPreco()}} />
													: null}
												</div>
										}  
										paginatorLeft={ 
										<div style={{ textAlign: "left" }}>
												{this.state.listaPrecoVersao.status !== 'ENCERRADO' ?													
													<Button icon="fas fa-plus" title="Adicionar Produtos" className="tzm-paginator-button" onClick={() =>{this.consultarProduto()}} />
													: null}
										</div>
					
								} emptyMessage="Nenhum item adicionado">
									<Column key="produtoId" field="produtoId"  style={{ textAlign: "center", width: "18em" }} header="SKU" />
									<Column key="produtoDescricao" field="produtoDescricao"  style={{ width: "*" }} header="Produto" />
									<Column key="codigo" style={{ textAlign: "center", width: "10em" }} header="Posição" body={(item) => Validator.isEntidade(item.produto) ? item.produto.posicao : ""} />									
									<Column key="fci"  style={{ center: "right", width: "15em" }}  header="% FCI" body={this.editorFci} />
									<Column key="preco" className={this.colorir} style={{ center: "right", width: "15em" }}  header="Preço" body={this.editorPreco} />									
									<Column style={{ width: "6em", textAlign: "center" }} key="acoes" header="Ações" body={(rowData) => {
										return (
											<div>
												{this.state.listaPrecoVersao.status !== 'ENCERRADO' ?
												<span className="fake-link" onClick={(event) => this.deleteItem(event, rowData)}>
													<TZMIcon title="Excluir Item" className="ui-action-icon" name="trash-alt" />
												</span> : null}  	
											</div>
										);
									}} />
								</TZMTable>
							</div>
						</div>                                
					</TZMTabPanel>    
				</TZMTabView>
				<TZMPanelFooter>
					{this.state.listaPrecoVersao.status !== 'ENCERRADO' ?  <TZMButton className="ui-button-success" label="Salvar" icon="fas fa-save" onClick={this.salvar}/> : null }
					<TZMButton className="ui-button-secondary" label="Fechar" icon="fas fa-times" onClick={this.onHide} />
					{this.state.listaPrecoVersao.status === 'PENDENTE' ? <TZMButton className="ui-button-primary" style={{ float: "left" }} label="Publicar" icon="fas fa-check" onClick={this.publicar} /> : null}					
				</TZMPanelFooter>
				<TZMShortcut active={this.state.visible} onCtrlS={this.salvar} onEscape={this.onHide}/>
			</TZMDialog>
		);
	}

}
